import { sumCondition } from './math'

export const calSoNamCaNhan = (datetime) => {
    const date = sumCondition(datetime.getDate(), (sum) => sum > 9)
    const month = sumCondition(datetime.getMonth() + 1, (sum) => sum > 9)
    const sothaido = sumCondition(+`${date}${month}`, (sum) => sum > 9)

    const sonamthegioi = sumCondition(
        new Date().getFullYear(),
        (sum) => sum > 9
    )

    const s = sothaido + sonamthegioi
    return sumCondition(s, (sum) => sum > 9)
}

export const calPyramid = (datetime) => {
    const date = datetime.getDate()
    const month = datetime.getMonth() + 1
    const year = datetime.getFullYear()
    const numDate = date === 11 ? date : sumCondition(date, (sum) => sum > 9)
    const numMonth =
        month === 11 ? month : sumCondition(month, (sum) => sum > 9)
    const numYear =
        year === datetime.get ? year : sumCondition(year, (sum) => sum > 9)

    const temp1 = numDate + numMonth
    const no1_Vertex1 =
        temp1 === 11 || temp1 === 22
            ? temp1
            : sumCondition(temp1, (sum) => sum > 9)

    const temp2 = numDate + numYear
    const no1_Vertex2 =
        temp2 === 11 || temp2 === 22
            ? temp2
            : sumCondition(temp2, (sum) => sum > 9)

    const temp3 = no1_Vertex1 + no1_Vertex2
    const no1_Vertex3 =
        temp3 === 11 || temp3 === 22
            ? temp3
            : sumCondition(no1_Vertex1 + no1_Vertex2, (sum) => sum > 9)

    const temp4 = no1_Vertex1 + no1_Vertex2
    const no1_Vertex4 =
        temp4 === 11 || temp4 === 22
            ? temp4
            : sumCondition(numMonth + numYear, (sum) => sum > 9)
    return [
        numMonth,
        numDate,
        numYear,
        no1_Vertex1,
        no1_Vertex2,
        no1_Vertex3,
        no1_Vertex4,
    ]
}
