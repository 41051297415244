import modalContants from '../../config/constant/modal.constant'

const modalActions = {
    openTabModal: () => ({
        type: modalContants.OPEN_TAB_MODAL,
    }),
    closeTabModal: () => ({
        type: modalContants.CLOSE_TAB_MODAL,
    }),
}

export default modalActions
