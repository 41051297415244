import {
    Box,
    Button,
    Chip,
    Divider,
    Grid,
    Stack,
    Typography,
} from '@mui/material'

import { PATH_PAGE } from '../../config/constant'
import React from 'react'
import alertActions from '../../redux/actions/alert.action'
import { formatterMoney } from '../../helpers/money'
import shoppingService from '../../services/shopping.service'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const StackChips = ({ index }) => {
    return (
        <Stack direction='row' spacing={1}>
            <Chip
                label='Cơ bản'
                {...(index === 0 ? { color: 'primary' } : {})}
            />
            <Chip
                label='Nâng cao'
                {...(index === 1 ? { color: 'primary' } : {})}
            />
            <Chip
                label='Cao cấp'
                {...(index === 2 ? { color: 'primary' } : {})}
            />
            <Chip
                label='Chuyên gia'
                {...(index === 3 ? { color: 'primary' } : {})}
            />
        </Stack>
    )
}

const Package = ({ index, idPackage, name, price, desc }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const onClickBuy = (e) => {
        e.preventDefault()
        onSubmit()
    }

    const onSubmit = () => {
        shoppingService
            .createOrder([{ id: idPackage }])
            .then((data) => {
                const { orderId } = data
                return navigate({
                    pathname: PATH_PAGE.payment_order,
                    search: '?orderId=' + orderId,
                })
            })
            .catch((error) => dispatch(alertActions.error(error.errorMessage)))
    }

    return (
        <Box sx={{ width: '100%', maxWidth: 420 }}>
            <Box sx={{ my: 3, mx: 2 }}>
                <Grid container alignItems='center'>
                    <Grid item xs>
                        <Typography
                            gutterBottom
                            variant='h5'
                            component='div'
                            color='primary'
                        >
                            {name}
                        </Typography>
                    </Grid>
                </Grid>
                <Typography gutterBottom variant='h5' component='div'>
                    {formatterMoney.format(price)}
                </Typography>
                <Typography
                    color='text.secondary'
                    variant='body2'
                    sx={{ my: 2 }}
                >
                    {desc}
                </Typography>
            </Box>
            <Divider variant='middle' />
            <Box sx={{ m: 2 }}>
                <Typography gutterBottom variant='body1'>
                    Gói
                </Typography>
                <StackChips index={index} />
            </Box>
            <Box sx={{ mt: 3, ml: 1, mb: 1 }}>
                <Button
                    type='button'
                    color='error'
                    variant='contained'
                    onClick={onClickBuy}
                >
                    Mua
                </Button>
            </Box>
        </Box>
    )
}

export default Package
