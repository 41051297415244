import { Box } from '@mui/material'
import React from 'react'
import BaseContent from './Base'
import luangiaiActions from '../../../redux/actions/luangiai.action'
import { useSelector } from 'react-redux'
import MdAttr from './MdAttr'

const SoChuDao = () => {
    const { lg_sochudao, query: state } = useSelector((state) => state.luangiai)
    return (
        <>
            <BaseContent
                title='Số chủ đạo'
                des='Con Số Chủ Đạo theo Thần Số Học (tên tiếng Anh là <strong>Your Ruling Number</strong>) là con số xâu chuỗi toàn bộ cuộc sống của bạn từ khi bạn sinh ra cho đến lúc bạn buông tay xa lìa cuộc đời này.'
                hashLink='so-chu-dao'
                id='so-chu-dao'
                helperText='Đăng nhập để xem đầy đủ số chủ đạo của bản thân'
                condition={lg_sochudao?.length > 0}
                actions={luangiaiActions.chitietSoChuDao}
                state={state}
            >
                <Box name='hinhanh'></Box>
                <Box name='luangiai' mt={4}>
                    <MdAttr luangiai={lg_sochudao} />
                </Box>
            </BaseContent>
        </>
    )
}

export default SoChuDao
