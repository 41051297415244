import { changePassword, getMyProfile, updateProfile } from '../api/user.api'

import storageConstant from '../config/constant/storage.constant'

const handleGetMyProfile = async () => {
    return getMyProfile()
        .then(({ data }) => {
            const user = data
            localStorage.setItem(storageConstant.USER, JSON.stringify(user))
            return user
        })
        .catch(() => {
            return Promise.reject()
        })
}

const handleChangePassword = async (
    currentPassword,
    newPassword,
    confirmPassword
) => {
    return changePassword(currentPassword, newPassword, confirmPassword)
}

const handleUpdateProfile = async ({
    email,
    avatarUrl,
    fullname,
    phoneCode,
    phoneNumber,
    birth,
}) => {
    return updateProfile({
        email,
        avatarUrl,
        fullname,
        phoneCode,
        phoneNumber,
        birth,
    }).then(({ data }) => {
        const user = data
        localStorage.setItem(storageConstant.USER, JSON.stringify(user))
        return user
    })
}

const userService = {
    handleGetMyProfile,
    handleChangePassword,
    handleUpdateProfile,
}
export default userService
