//react import
import { useEffect, useState } from 'react';
//mui material import
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';


//icons material import
import PhotoCamera from '@mui/icons-material/PhotoCamera';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
        xs: '90vw',
        sm: '80vw',
        md: '50vw',
        lg: '35vw'
    },
    bgcolor: 'background.paper',
    boxShadow: 24,
    padding: '3vh 1vw',
    minHeight: '65vh',
    maxHeight: '80vh',
    overflowY: 'auto'
};



export default function UserModal({ row }) {

    const [stateForm, setForm] = useState({})

    useEffect(() => {
        setForm(row)
    }, [])
    return (
        <Box sx={style} >
            <Grid container spacing={2}>
                <Grid item md={12} lg={12} sm={12} xs={12} align='center' mb={2}>
                    <h4>
                        {row.username}
                    </h4>
                </Grid>
                <Grid container md={12} lg={12} sm={12} xs={12} spacing={3} mb={2}>
                    <Grid item md={4} sm={4} xs={5} align='right' sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                    }}>
                        <h6 sx={{ margin: '0' }}>Tên đầy đủ</h6>
                    </Grid>
                    <Grid item md={8} sm={8} xs={7}>
                        <TextField
                            fullWidth
                            label=""
                            name="fullname"
                            // onChange={handleChange}
                            value={stateForm.fullname}
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
                <Grid container md={12} spacing={3} lg={12} sm={12} xs={12} mb={2} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Grid item md={4} sm={4} xs={5} align='right' sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end'
                    }}>
                        <h6 sx={{ margin: '0' }}>Ảnh đại diện</h6>
                    </Grid>
                    <Grid item md={2} sm={1.5} xs={2}>
                        <Avatar sx={{
                            width: '5vmax',
                            height: '5vmax',
                        }} />
                    </Grid>
                    <Grid item md={6} sm={6.5} xs={5}>
                        <label htmlFor="contained-button-file">
                            <Input accept="image/*" id="contained-button-file" multiple type="file" />
                            <Button variant="contained" component="span">
                                Tải ảnh đại diên
                            </Button>
                        </label>
                    </Grid>

                </Grid>
                <Grid container md={12} lg={12} sm={12} xs={12} spacing={3} mb={2}>
                    <Grid item md={4} sm={4} xs={5} align='right' sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                    }}>
                        <h6 sx={{ margin: '0' }}>Email</h6>
                    </Grid>
                    <Grid item md={8} sm={8} xs={7}>
                        <TextField
                            fullWidth
                            label=""
                            name="email"
                            // onChange={handleChange}
                            value={stateForm.email}
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
                <Grid container md={12} lg={12} sm={12} xs={12} spacing={3} mb={2}>
                    <Grid item md={4} sm={4} xs={5} align='right' sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                    }}>
                        <h6 sx={{ margin: '0' }}>Sđt</h6>
                    </Grid>
                    <Grid item md={8} sm={8} xs={7}>
                        <TextField
                            fullWidth
                            label=""
                            name="phoneNumber"
                            // onChange={handleChange}
                            value={stateForm.phoneNumber}
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
                <Grid container md={12} lg={12} sm={12} xs={12} spacing={3} mb={2}>
                    <Grid item md={4} sm={4} xs={5} align='right' sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                    }}>
                        <h6 sx={{ margin: '0' }}>Ngày sinh</h6>
                    </Grid>
                    <Grid item md={8} sm={8} xs={7}>
                        <TextField
                            fullWidth
                            label=""
                            name="birth"
                            // onChange={handleChange}
                            value={stateForm.birth}
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
                <Grid container md={12} lg={12} sm={12} xs={12} spacing={3} mb={2}>
                    <Grid item md={4} sm={4} xs={5} align='right' sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                    }}>
                        <h6 sx={{ margin: '0' }}>Xác thực</h6>
                    </Grid>
                    <Grid item md={8} sm={8} xs={7}>
                        <Button variant="contained" color="warning">
                            Xác thực người dùng
                        </Button>
                    </Grid>
                </Grid>
                <Grid container md={12} lg={12} sm={12} xs={12} spacing={3} mb={2}>
                    <Grid item md={4} sm={4} xs={5} align='right' sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                    }}>
                        <h6 sx={{ margin: '0' }}>Block</h6>
                    </Grid>
                    <Grid item md={8} sm={8} xs={7}>
                        <Button variant="contained" color="error">
                            Khóa người dùng
                        </Button>
                    </Grid>
                </Grid>
                <Grid container md={12} lg={12} sm={12} xs={12} spacing={3} mb={2}>
                    <Grid item md={4} sm={4} xs={5} align='right' sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                    }}>
                        <h6 sx={{ margin: '0' }}>Vjp</h6>
                    </Grid>
                    <Grid item md={8} sm={8} xs={7}>
                        <Button variant="contained" color="secondary">
                            Đăng kí
                        </Button>
                    </Grid>
                </Grid>
                <Grid item md={12} lg={12} sm={12} xs={12}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            p: 2
                        }}
                    >
                        <Button
                            variant="outlined"
                            sx={{
                                marginRight: 1
                            }}
                        >
                            Xem lịch sử tra cứu
                        </Button>
                        <Button
                            color="success"
                            variant="contained"
                        >
                            Lưu thay đổi
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Box >
    )
}

const Input = styled('input')({
    display: 'none',
});

