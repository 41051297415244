import { PATH_PAGE } from '../../config/constant'
import { lazy } from 'react'

const route = {
    key: 'manager',
    path: PATH_PAGE.manager,
    exact: true,
    component: lazy(() => import('.')),
    role: 'admin',
    isPrivate: true,
    otherRoutes: [],
}

export default route
