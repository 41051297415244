import {
    Alert,
    Box,
    Button,
    CssBaseline,
    Grid,
    Grow,
    IconButton,
    Paper,
    ThemeProvider,
    Tooltip,
    Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'

import Catalogues from './components/Catalogues'
import { DarkTheme } from '../../components/UI/Theme'
import FacebookIcon from '@mui/icons-material/Facebook'
import { FacebookShareButton } from 'react-share'
import HTMLParser from 'html-react-parser'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import LinkIcon from '@mui/icons-material/Link'
import { MainContainer } from '../../components/UI'
import NhanSoHocThanBi1 from '../../assets/images/nhan-so-hoc-hinh-nen-5.jpg'
import NoImage from '../../assets/images/no-image.png'
import { PATH_PAGE } from '../../config/constant'
import alertActions from '../../redux/actions/alert.action'
import { getCatalogues } from '../../api/catalogues'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'

const REACT_APP_BK_ENDPOINT = process.env.REACT_APP_BK_ENDPOINT
const DEFAULT_TITLE = 'Khám phá thần số học'
const DEFAULT_DES = `<strong>Thần số học (Numerology)</strong> là một chuyên ngành nghiên cứu về những con số và
các tác động sóng rung của những con số đối với đời
sống con người. Có thể xem Thần số học như
một dạng đọc vị về số, giải mã những tín hiệu mà
cuộc sống đã gửi đến kèm với từng cá thể con người
trong đời sống này, tương tự như Nhân tướng học, hay
Nhân trắc học…`
const DEFAULT_MG = NhanSoHocThanBi1

const TheoryHumanArithmetic = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { slug } = useParams()
    const [isLoading, setIsLoading] = useState(false)
    const [catalogues, setCatalogues] = useState([
        {
            title: '',
            url: '',
            img: '',
            description: '',
        },
    ])

    const [title, setTitle] = useState(DEFAULT_TITLE)
    const [description, setDescription] = useState(DEFAULT_DES)
    const [image, setImage] = useState(DEFAULT_MG)
    const [clickContent, setClickContent] = useState(false)

    const copy = () => {
        const el = document.createElement('input')
        el.value = window.location.href
        document.body.appendChild(el)
        el.select()
        document.execCommand('copy')
        document.body.removeChild(el)
        setClickContent('Sao chép đường dẫn thành công')
    }
    useEffect(() => {
        if (slug) {
            document.title = title
        } else {
            document.title = DEFAULT_TITLE
            setTitle(DEFAULT_TITLE)
            setDescription(DEFAULT_DES)
            setImage(DEFAULT_MG)
        }
    }, [slug])

    useEffect(() => {
        setIsLoading(true)
        getCatalogues({ withSub: 1, fatherSlug: slug })
            .then(({ data }) => {
                const catalogueData = data.data
                if (catalogueData.length > 0 && slug) {
                    const firstItem = catalogueData[0]
                    setTitle(firstItem.title)
                    setDescription(firstItem.description)
                    setImage(REACT_APP_BK_ENDPOINT + firstItem.img)
                }
                setCatalogues(catalogueData)
            })
            .catch((error) => dispatch(alertActions.error(error.message)))
            .finally(() => setIsLoading(false))
    }, [slug])

    const errorImage = (target) => {
        target.onError = null
        target.src = NoImage
    }

    return (
        <>
            <ThemeProvider theme={DarkTheme}>
                <CssBaseline />
                <MainContainer
                    className='post-content'
                    maxWidth={false}
                    // sx={{
                    //     maxWidth: '1400px',
                    //     paddingBottom: 5,
                    // }}
                >
                    {!!clickContent && (
                        <Alert
                            severity='success'
                            color='success'
                            onClose={() => setClickContent(false)}
                        >
                            {clickContent}
                        </Alert>
                    )}
                    <Box id='base-main'>
                        <Grow in>
                            <Grid container spacing={3}>
                                <Grid
                                    item
                                    md={5}
                                    sx={{
                                        alignSelf: 'center',
                                    }}
                                >
                                    <LazyLoadImage
                                        alt={`anh-${title}`}
                                        src={image}
                                        width={'100%'}
                                        effect='blur'
                                        onError={({ target }) =>
                                            errorImage(target)
                                        }
                                    />
                                </Grid>
                                <Grid item md={7}>
                                    <Paper
                                        elevation={0}
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            backgroundColor: 'transparent',
                                            backgroundImage: 'none',
                                            padding: 3,
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <Typography
                                                variant='h2'
                                                gutterBottom
                                            >
                                                {title}
                                            </Typography>

                                            <Box display='inline-flex'>
                                                <FacebookShareButton
                                                    url={window.location.href}
                                                >
                                                    <Tooltip
                                                        title='Chia sẻ lên Facebook'
                                                        sx={{ mr: '4px' }}
                                                    >
                                                        <IconButton component='span'>
                                                            <FacebookIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </FacebookShareButton>
                                                <Tooltip
                                                    title='Sao chép đường dẫn'
                                                    sx={{ mr: '18px' }}
                                                >
                                                    <div onClick={copy}>
                                                        <IconButton component='span'>
                                                            <LinkIcon />
                                                        </IconButton>
                                                    </div>
                                                </Tooltip>
                                            </Box>
                                        </div>
                                        <Typography
                                            paragraph
                                            variant='body1'
                                            sx={{ lineHeight: '1.7' }}
                                        >
                                            {HTMLParser(description)}
                                        </Typography>
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                alignContent: 'center',
                                                columnGap: '10px',
                                            }}
                                        >
                                            <Typography
                                                // paragraph
                                                variant='inherit'
                                                sx={{ color: 'gray' }}
                                            >
                                                {
                                                    'Sử dụng công cụ tra cứu để khám phá các chỉ số của riêng bản thân bạn'
                                                }
                                            </Typography>
                                            <Button
                                                variant='contained'
                                                color='success'
                                                size='small'
                                                onClick={() =>
                                                    navigate(
                                                        PATH_PAGE.human_arithmetic
                                                    )
                                                }
                                            >
                                                Tra Cứu Miễn Phí
                                            </Button>
                                        </div>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grow>
                    </Box>
                    <Box name='list-group' mt={5}>
                        {catalogues.map((catalogue) => (
                            <Catalogues isLoading={isLoading} {...catalogue} />
                        ))}
                    </Box>
                </MainContainer>
            </ThemeProvider>
        </>
    )
}

export default TheoryHumanArithmetic
