import { ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { useMatch, useNavigate, useResolvedPath } from 'react-router-dom'

import React from 'react'

const ListItemLink = ({ href, primary, icon, closeDrawer }) => {
    const resolved = useResolvedPath(href)
    const navigate = useNavigate()
    const match = useMatch({ path: resolved.pathname, end: true })

    const onClickListItem = (e) => {
        e.preventDefault()
        closeDrawer()
        if(href.includes('http') || href.includes('https')){
            window.location.href = href
        } else {
            navigate(href)
        }
    }

    return (
        <ListItem
            button
            onClick={onClickListItem}
            component='a'
            selected={match && true}
        >
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={primary} />
        </ListItem>
    )
}

export default ListItemLink
