import { PATH_PAGE } from '../../config/constant'
import { lazy } from 'react'

const route = {
    key: 'payment_order',
    path: PATH_PAGE.payment_order,
    exact: true,
    private: true,
    component: lazy(() => import('.')),
    otherRoutes: [],
}

export default route
