import { PATH_PAGE } from '../../config/constant'
import { lazy } from 'react'

const route = {
    key: 'account_profile',
    path: PATH_PAGE.account_profile,
    exact: true,
    component: lazy(() => import('.')),
    otherRoutes: [],
    isPrivate: true,
}

export default route
