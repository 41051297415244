import { PATH_PAGE } from '../../config/constant'
import { lazy } from 'react'

const route = {
    key: 'human-arithmetic-theory',
    path: PATH_PAGE.human_arithmetic_theory,
    exact: true,
    component: lazy(() => import('.')),
    otherRoutes: [
        {
            key: 'human-arithmetic-theory-group',
            path: ':slug',
            exact: true,
            component: lazy(() => import('.')),
            otherRoutes: [],
        },
    ],
}

export default route
