import { Grid, Paper, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { MatranMuiTenTenRieng } from '../../../helpers/muiten'
import luangiaiActions from '../../../redux/actions/luangiai.action'
import BaseContent from './Base'
import MdAttr from './MdAttr'
import TableArrowComponent from './TableArrowComponent'

const BieuDoTenRieng = () => {
    const { lg_tenrieng, query: state } = useSelector((s) => s.luangiai)
    const finalname = state.shortname || state.fullname.split(' ').pop()
    const [matranTen, setMatranTen] = useState(undefined)

    useEffect(() => {
        const matrixArrowFullname = new MatranMuiTenTenRieng(state.fullname)
        setMatranTen(matrixArrowFullname.matran)
    }, [state])

    return (
        <BaseContent
            title={'Biểu đồ tên'}
            des='Phân tích biểu đồ tên trong thần số học cho chúng ta thông tin chính về nền tảng tính cách của một người. Khi phân tích bảng tên trong thần số học, chúng ta xét từng hàng ngang và chỉ lựa chọn những hàng mà cả 3 ô vuông có chứa các con số. Nếu hàng ngang nào khuyết 1 ô, 2 ô hay khuyết cả 3 ô thì bỏ qua, không xét đến.'
            hashLink='bieu-do-ten'
            id='bieu-do-ten'
            helperText='Đăng nhập tài khoản để biết bí mật về tên của bản thân'
            condition={lg_tenrieng?.length > 0}
            actions={luangiaiActions.chitietBDTenRieng}
            state={state}
        >
            <Grid container spacing={2} mt={2}>
                <Grid item sm={12} md={4} xs={12}>
                    <TableArrowComponent
                        matrixArrow={matranTen}
                        caption={'Biểu đồ tên ' + finalname}
                    />
                </Grid>
                <Grid item sm={12} md={8} xs={12}>
                    <Paper
                        className='primary-paper'
                        sx={{ px: 3, py: 1, height: '100%' }}
                    >
                        <table className='collapse' style={{ margin: 0 }}>
                            <tbody>
                                <tr>
                                    <td
                                        className='bd-none'
                                        style={{ textAlign: 'left' }}
                                    >
                                        <Typography
                                            color='primary'
                                            sx={{
                                                fontSize: '16px',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Trục 3-6-9&nbsp;&nbsp;
                                            <Typography
                                                variant='body1'
                                                component='span'
                                                color='#fff'
                                            >
                                                Trục trí não - trí nhớ và thông
                                                minh
                                            </Typography>
                                        </Typography>
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        className='bd-none'
                                        style={{ textAlign: 'left' }}
                                    >
                                        <Typography
                                            color='primary'
                                            sx={{
                                                fontSize: '16px',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Trục 2-5-8&nbsp;&nbsp;
                                            <Typography
                                                variant='body1'
                                                component='span'
                                                color='#fff'
                                            >
                                                Trục tinh thần - vui buồn, giận
                                                hờn...
                                            </Typography>
                                        </Typography>
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        className='bd-none'
                                        style={{ textAlign: 'left' }}
                                    >
                                        <Typography
                                            color='primary'
                                            sx={{
                                                fontSize: '16px',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Trục 1-4-7&nbsp;&nbsp;
                                            <Typography
                                                variant='body1'
                                                component='span'
                                                color='#fff'
                                            >
                                                Trục thể chất - sức khỏe, hoạt
                                                động
                                            </Typography>
                                        </Typography>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Paper>
                </Grid>
            </Grid>
            <Box name='luangiai' mt={4}>
                <MdAttr luangiai={lg_tenrieng} />
            </Box>
        </BaseContent>
    )
}

export default BieuDoTenRieng
