import 'react-medium-image-zoom/dist/styles.css'

import { Box } from '@mui/system'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Link } from 'react-router-dom'
import MaterialLink from '@mui/material/Link'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Typography } from '@mui/material'
import Zoom from 'react-medium-image-zoom'
import remarkEmoji from 'remark-emoji'
import remarkGfm from 'remark-gfm'
import remarkRehype from 'remark-rehype'

const optionsMarkdown = {
    h1: ({ node, ...props }) => (
        <Typography gutterBottom variant='h4' component='h1' {...props} />
    ),
    h2: ({ node, ...props }) => (
        <Typography gutterBottom variant='h4' component='h2' {...props} />
    ),
    h3: ({ node, ...props }) => (
        <Typography gutterBottom variant='h5' component='h3' {...props} />
    ),
    h4: ({ node, ...props }) => (
        <Typography
            gutterBottom
            variant='h6'
            paragraph
            component='h4'
            {...props}
        />
    ),
    p: ({ node, ...props }) => (
        <Typography paragraph variant='body1' component='p' {...props} />
    ),
    a: ({ node, ...props }) => (
        <MaterialLink component={Link} to={props.href} {...props} />
    ),
    ul: ({ node, ...props }) => <ul dense={false} {...props} />,
    li: ({ node, ...props }) => <li {...props} />,
    img: ({ node, ...props }) => (
        <Box name='box' display='flex' justifyContent='center'>
            <Zoom>
                <LazyLoadImage
                    alt='md-text'
                    loading='lazy'
                    {...props}
                    width='100%'
                />
            </Zoom>
        </Box>
    ),
    th: ({ node, ...props }) => (
        <th className='markdown-td' isHeader {...props} />
    ),
    td: ({ node, ...props }) => (
        <td className='markdown-td' isHeader={false} {...props} />
    ),
}
function flatten(text, child) {
    return typeof child === 'string'
        ? text + child
        : React.Children.toArray(child.props.children).reduce(flatten, text)
}
function HeadingRenderer(props) {
    var children = React.Children.toArray(props.children)
    var text = children.reduce(flatten, '')
    var slug = text.toLowerCase().replace(/\W/g, '-')
    return React.createElement('h' + props.level, { id: slug }, props.children)
}
const MarkDown = (props) => {
    return (
        <ReactMarkdown
            className='markdown-body reading-time'
            rehypePlugins={[remarkGfm, remarkRehype, remarkEmoji]}
            components={optionsMarkdown}
            {...props}
            renderers={{ Heading: HeadingRenderer }}
        />
    )
}

export default MarkDown
