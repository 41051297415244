import { Divider, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    MatranMuiTenNgaySinh,
    MatranMuiTenTenRieng,
    MatranMuiTenTongHop,
} from '../../../helpers/muiten'
import alertActions from '../../../redux/actions/alert.action'
import luangiaiActions from '../../../redux/actions/luangiai.action'
import BaseContent from './Base'
import MdAttr from './MdAttr'
import MatrixArrowTableComponent from './TableArrowComponent'

const BieuDoTongHop = () => {
    const dispatch = useDispatch()
    const [matranTongHop, setMatranTongHop] = useState(undefined)
    const [matranNgaySinh, setMatranNgaySinh] = useState(undefined)
    const [matranTenRieng, setMatranTenRieng] = useState(undefined)
    const {
        lg_tonghop,
        lg_cacsonbdns,
        query: state,
    } = useSelector((s) => s.luangiai)

    useEffect(() => {
        // const date = new Date(state.dob.replace(/-/g, '/'))
        try {
            if (state.dob) {
                const date = new Date(state.dob)
                const mtNgaySinh = new MatranMuiTenNgaySinh(date)
                const mtTenRieng = new MatranMuiTenTenRieng(state.fullname)
                const mtTongHop = new MatranMuiTenTongHop(date, state.fullname)
                setMatranNgaySinh(mtNgaySinh.matran)
                setMatranTenRieng(mtTenRieng.matran)
                setMatranTongHop(mtTongHop.matran)
            }
        } catch (error) {
            dispatch(alertActions.error())
        }
    }, [state, dispatch])

    return (
        <BaseContent
            title='Biểu đồ tổng hợp'
            des='Biểu đồ tổng hợp là sự kết hợp của 2 biểu đồ bên trên <strong>Biểu đồ Sức Mạnh</strong> và <strong>Biểu đồ Tên Riêng</strong>, tại đây bạn sẽ được xem cụ thể về sức mạnh, tính cách và định hướng của bản thân.'
            hashLink='bieu-do-tong-hop'
            id='bieu-do-tong-hop'
            helperText='Đăng nhập tài khoản để biết thêm về bí mật của bản thân'
            condition={lg_tonghop?.length > 0}
            actions={luangiaiActions.chitietBDTongHop}
            state={state}
        >
            <Box
                name='hinhve'
                display='flex'
                flexWrap='wrap'
                rowGrap={2}
                columnGrap={2}
                justifyContent='space-around'
                mt={2}
            >
                <MatrixArrowTableComponent
                    matrixArrow={matranNgaySinh}
                    caption='Biểu đồ sức mạnh'
                />
                <Typography variant='h4' sx={{ margin: 'auto', flex: 0 }}>
                    +
                </Typography>
                <MatrixArrowTableComponent
                    matrixArrow={matranTenRieng}
                    caption='Biểu đồ tên riêng'
                />
                <Typography variant='h4' sx={{ margin: 'auto', flex: 0 }}>
                    =
                </Typography>
                <MatrixArrowTableComponent
                    matrixArrow={matranTongHop}
                    caption='Biểu đồ tổng hợp'
                />
            </Box>
            <Box name='luangiai' mt={4}>
                <Divider sx={{ marginTop: 4 }}>
                    Các số xuất hiện trong biểu đồ
                </Divider>
                <MdAttr luangiai={lg_cacsonbdns} />
                <Divider sx={{ marginTop: 4 }}>Các mũi tên bạn sở hữu</Divider>
                <MdAttr luangiai={lg_tonghop} />
            </Box>
        </BaseContent>
    )
}

export default BieuDoTongHop
