import React from 'react'
import Malada from '../assets/images/nhan-so-hoc-hoa-van.svg'

const styles = {
    position: 'absolute',
    textAlign: 'center',
    top: '50%',
    left: '50%',
    marginTop: '-160px',
    marginLeft: '-145px',
}

const LoadingComponent = ({ isShow }) => {
    return (
        <>
            {isShow && (
                <div style={styles}>
                    <img id='loading' src={Malada} alt='malada-loading'></img>
                </div>
            )}
        </>
    )
}

export default LoadingComponent
