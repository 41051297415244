import { Typography } from '@mui/material'
import React from 'react'
import { useRef } from 'react'
import { HashLink } from 'react-router-hash-link'

const HashLinkCustom = ({ id, to, title, ...otherProps }) => {
    const headingRef = useRef(null)
    return (
        <HashLink
            smooth
            to={to}
            style={{
                textDecoration: 'none',
                width: '100%',
            }}
        >
            <Typography
                id={id}
                {...otherProps}
                paddingTop='60px'
                ref={headingRef}
            >
                {title}
            </Typography>
        </HashLink>
    )
}

export default HashLinkCustom
