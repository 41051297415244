import './style.css'
import React, { useContext, useState, useEffect } from 'react'
import { MainContainer } from '../../components/UI'
import { PATH_PAGE } from '../../config/constant'
import {
    Box,
    Button,
    Card,
    CardContent,
    CardMedia,
    CssBaseline,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    TextField,
    ThemeProvider,
    Typography,
} from '@mui/material'
import NumerlogyBG from '../../assets/images/nhan-so-hoc-anh-bia.png'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import Validator from '../../utils/Validator'
import { isInt } from '../../helpers/validateInput'
import { DarkTheme } from '../../components/UI/Theme'
import { useNavigate, createSearchParams } from 'react-router-dom'
import { isEnglish, isVietnamese } from '../../helpers/characterTransform'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import FormHelperText from '@mui/material/FormHelperText'
import AppContext from '../../context/AppContext'
import { getCodeSearchHistory } from '../../api/search_history'
import alertActions from '../../redux/actions/alert.action'
import { useDispatch } from 'react-redux'
import moment from 'moment'

const makeNArray = (n) => Array.from({ length: n }, (_, i) => i + 1)
const makeNYearArrayFromNow = () => {
    const currentYear = new Date().getFullYear()
    const lastestYear = 1900
    const n = currentYear - lastestYear + 1
    return Array.from({ length: n }, (_, i) => currentYear - i)
}

const validator = new Validator([
    {
        field: 'fullname',
        method: (value, field, state) => {
            return (
                value.match(/[%<>\\$'"]/) || value.trim().split(' ').length < 2
            )
        },
        message: 'Vui lòng nhập họ và tên đầy đủ',
        validWhen: false,
        args: [],
    },
    {
        field: 'fullname',
        method: (value, field, state) => {
            return isVietnamese(value) || isEnglish(value)
        },
        message:
            'Hệ thống chỉ hỗ trợ tên Tiếng Việt và Tiếng Anh (The system only supports Vietnamese and English name)',
        validWhen: true,
        args: [],
    },
    {
        field: 'shortname',
        method: (value, field, state) => {
            return isVietnamese(value) || isEnglish(value)
        },
        message:
            'Hệ thống chỉ hỗ trợ tên Tiếng Việt và Tiếng Anh (The system only supports Vietnamese and English name)',
        validWhen: true,
        args: [],
    },
    {
        field: 'gender',
        method: (value, field, state) => {
            return (
                parseInt(value) === 1 ||
                parseInt(value) === 2 ||
                parseInt(value) === 3
            )
        },
        message: 'Giới tính không hợp lệ',
        validWhen: true,
        args: [],
    },
    {
        field: 'date',
        method: (value, field, state) => {
            return isInt(value) && value <= 31 && value > 0
        },
        message: 'Ngày sinh không hợp lệ',
        validWhen: true,
        args: [],
    },
    {
        field: 'month',
        method: (value, field, state) => {
            return isInt(value) && value <= 12 && value > 0
        },
        message: 'Tháng sinh không hợp lệ',
        validWhen: true,
        args: [],
    },
    {
        field: 'year',
        method: (value, field, state) => {
            return isInt(value) && value >= 1000
        },
        message: 'Năm sinh không hợp lệ',
        validWhen: true,
        args: [],
    },
])

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

/**
 * @description Trang Nhân số học
 * @returns
 */
const HumanArithmeticPage = () => {
    const [isValid, setValidForm] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { footer } = useContext(AppContext)
    const [, setShowFooter] = footer
    useEffect(() => setShowFooter(false), [])

    useEffect(() => {
        document.title = 'Tra Cứu | Nhân Số Đại Việt'
        let description = document.querySelector('meta[name="description"]')
        if (description) {
            description.setAttribute(
                'content',
                'Công cụ được phát triển bởi hệ thống dữ liệu phong phú về Nhân số học hàng đầu tại Việt Nam, kèm với đó là đội ngũ kỹ sư giàu kinh nghiệm. Hệ thống có thể xử lý hàng triệu kết quả chỉ trong vài giây với tỷ lệ chính xác lên tới 80%.'
            )
        }
    }, [])

    const [stateForm, setForm] = useState({
        fullname: '',
        shortname: '',
        gender: 1,
        date: '',
        month: '',
        year: '',
    })

    const [errors, setErrors] = useState({})
    const handleInput = (e) => {
        setForm({ ...stateForm, [e.target.name]: e.target.value })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setErrors(validator.validate(stateForm))
        setValidForm(validator.isValid)
    }
    if (isValid) {
        const { year, month, date } = stateForm
        // console.log(`${year}-${("0" + month).slice(-2)}-${("0" + date).slice(-2)}`)
        const dob = moment(`${year}-${("0" + month).slice(-2)}-${("0" + date).slice(-2)}`, 'YYYY-MM-DD')
        if (!dob.isValid()) {
            return dispatch(alertActions.error('Ngày sinh không hợp lệ.'))
        }
        const dataPost = { ...stateForm, dob: dob.toISOString() }
        delete dataPost.year
        delete dataPost.month
        delete dataPost.date

        getCodeSearchHistory(dataPost)
        .then(({ data: code }) => {
            if (code) {
                return navigate(
                    PATH_PAGE.human_arithmetic_result +
                        '?' +
                        createSearchParams({ code })
                )
            }
            dispatch(alertActions.error())
        })
        .catch((error) => dispatch(alertActions.error()))

        // const path =
        //     PATH_PAGE.human_arithmetic_result +
        //     '?' +
        //     createSearchParams({
        //         fullname: stateForm.fullname,
        //         shortname: stateForm.shortname,
        //         gender: stateForm.gender,
        //         dob: `${stateForm.year}-${stateForm.month}-${stateForm.date}`,
        //     })
        // return navigate(path)
    }
    return (
        <>
            <MainContainer maxWidth='md'>
                <ThemeProvider theme={DarkTheme}>
                    <CssBaseline />
                    <Card
                        variant='outlined'
                        sx={{ backgroundColor: 'rgba(0,0,0,60%)' }}
                    >
                        <CardMedia
                            component='img'
                            height='180'
                            image={NumerlogyBG}
                            alt='NumerlogyBG'
                        />
                        <CardContent>
                            <Typography
                                variant='h5'
                                sx={{ textTransform: 'uppercase' }}
                            >
                                GIẢI MÃ NHÂN SỐ HỌC
                            </Typography>
                            <Box
                                component='form'
                                method='post'
                                onSubmit={handleSubmit}
                                sx={{
                                    mt: 4,
                                    flexWrap: 'wrap',
                                    display: 'flex',
                                    '& .form-group': {
                                        marginBottom: 3,
                                        width: '100%',
                                    },
                                }}
                            >
                                <FormControl className='form-group'>
                                    <TextField
                                        required
                                        error={!!errors.fullname}
                                        helperText={errors?.fullname}
                                        value={stateForm.fullname}
                                        onInput={handleInput}
                                        name='fullname'
                                        id='input-fullname'
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        type='text'
                                        label='Họ và tên đầy đủ'
                                        placeholder='VD: Lê Hoàng Long'
                                        fullWidth
                                    />
                                </FormControl>
                                <FormControl className='form-group'>
                                    <TextField
                                        error={!!errors.shortname}
                                        helperText={errors?.shortname}
                                        value={stateForm.shortname}
                                        onInput={handleInput}
                                        name='shortname'
                                        id='input-shortname'
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        type='text'
                                        label='Tên thường gọi'
                                        placeholder='VD: Long Lê'
                                        fullWidth
                                    />
                                </FormControl>
                                {/* <FormControl
                                    className='form-group'
                                    style={{ marginLeft: '2px' }}
                                >
                                    <RadioGroup
                                        row
                                        aria-labelledby='gender'
                                        name='gender'
                                        onChange={handleInput}
                                        helperText={errors?.gender}
                                        defaultValue={stateForm.gender}
                                    >
                                        <FormControlLabel
                                            value={1}
                                            control={<Radio />}
                                            label='Nam'
                                        />
                                        <FormControlLabel
                                            value={2}
                                            control={<Radio />}
                                            label='Nữ'
                                        />
                                        <FormControlLabel
                                            value={3}
                                            control={<Radio />}
                                            label='Khác'
                                        />
                                    </RadioGroup>
                                </FormControl> */}
                                <div className='form-group flex-input-inline'>
                                    <FormControl
                                        sx={{ flexGrow: 1 }}
                                        error={!!errors.date}
                                    >
                                        <InputLabel id='lb-input-date'>
                                            Ngày sinh
                                        </InputLabel>
                                        <Select
                                            required
                                            id='input-date'
                                            name='date'
                                            labelId='lb-input-date'
                                            error={!!errors.date}
                                            onChange={handleInput}
                                            input={
                                                <OutlinedInput label='Ngày sinh' />
                                            }
                                            value={stateForm.date}
                                            MenuProps={MenuProps}
                                        >
                                            <MenuItem value=''>
                                                <em>Ngày</em>
                                            </MenuItem>
                                            {makeNArray(31).map((date) => (
                                                <MenuItem value={date}>
                                                    {date}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>
                                            {errors?.date}
                                        </FormHelperText>
                                    </FormControl>
                                    <FormControl
                                        sx={{ flexGrow: 1 }}
                                        error={!!errors.month}
                                    >
                                        <InputLabel id='lb-input-month'>
                                            Tháng sinh
                                        </InputLabel>
                                        <Select
                                            required
                                            id='input-month'
                                            labelId='lb-input-month'
                                            name='month'
                                            error={!!errors.month}
                                            onChange={handleInput}
                                            input={
                                                <OutlinedInput label='Tháng sinh' />
                                            }
                                            value={stateForm.month}
                                            MenuProps={MenuProps}
                                        >
                                            <MenuItem value=''>
                                                <em>Tháng</em>
                                            </MenuItem>
                                            {makeNArray(12).map((month) => (
                                                <MenuItem value={month}>
                                                    {month}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>
                                            {errors?.month}
                                        </FormHelperText>
                                    </FormControl>
                                    <FormControl
                                        sx={{ flexGrow: 1 }}
                                        error={!!errors.year}
                                    >
                                        <InputLabel id='lb-input-year'>
                                            Năm sinh
                                        </InputLabel>
                                        <Select
                                            required
                                            id='input-year'
                                            labelId='lb-input-year'
                                            error={!!errors.year}
                                            name='year'
                                            onChange={handleInput}
                                            label='Năm sinh'
                                            input={
                                                <OutlinedInput label='Năm sinh' />
                                            }
                                            value={stateForm.year}
                                            MenuProps={MenuProps}
                                        >
                                            <MenuItem value=''>
                                                <em>Năm</em>
                                            </MenuItem>
                                            {makeNYearArrayFromNow().map(
                                                (year) => (
                                                    <MenuItem value={year}>
                                                        {year}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                        <FormHelperText>
                                            {errors?.year}
                                        </FormHelperText>
                                    </FormControl>
                                </div>
                                <div className='form-group'>
                                    <Typography
                                        sx={{ pl: 1 }}
                                        variant='caption'
                                        color='text.secondary'
                                        display='block'
                                        gutterBottom
                                    >
                                        Nhập ngày tháng năm sinh dương lịch
                                    </Typography>
                                </div>
                                <Button
                                    type='submit'
                                    variant='contained'
                                    startIcon={<SearchOutlinedIcon />}
                                    sx={{ mt: 2 }}
                                >
                                    Tra cứu
                                </Button>
                            </Box>
                        </CardContent>
                    </Card>
                </ThemeProvider>
            </MainContainer>
        </>
    )
}

export default HumanArithmeticPage
