import axios from 'axios'
import tokenService from './services/token.service'

const instance = axios.create({
    timeout: 10000,
    timeoutErrorMessage: 'Xin lỗi, máy chủ đang gián đoạn',
    baseURL: process.env.REACT_APP_API_URL,
    header: {
        Accept: 'text/json',
        'Content-Type': 'application/json;multipart/form-data',
    },
    withCredentials: true,
})

const createError = (
    httpStatusCode,
    statusCode,
    errorMessage,
    problems,
    errorCode = 0
) => {
    const error = new Error()
    error.httpStatusCode = httpStatusCode
    error.httpStatusCode = httpStatusCode
    error.statusCode = statusCode
    error.errorMessage = errorMessage
    error.problems = problems
    error.errorCode = errorCode + ''
    return error
}

export const isSuccessStatusCode = (s) => {
    const statusType = typeof s
    return (
        (statusType === 'number' && s === 0) ||
        (statusType === 'string' && s.toUpperCase() === 'OK')
    )
}

instance.interceptors.request.use(
    async (config) => {
        return config
    },
    (error) => {
        Promise.reject(error)
    }
)

instance.interceptors.response.use(
    (response) => {
        // Thrown error for request with OK status code
        const { data } = response
        const { isSuccess } = data
        if (isSuccess) {
            return data
        }
        if (!data.hasOwnProperty('errors')) {
            return data
        }
        const originalRequest = response.config
        const { errors } = data
        const { status, isAuto } = errors
        switch (status) {
            case 401:
                if (isAuto) {
                    originalRequest._retry = true
                    return instance
                        .post(`/auth/token`)
                        .then(() => instance(originalRequest))
                    // .catch((err) => {
                    //     if (err.statusCode === 403) {
                    //         localStorage.removeItem('user')
                    //         tokenService.clearToken()
                    //         window.location.reload()
                    //         // window.location.replace('/')
                    //         // alert(
                    //         //     'Phiên đã hết hạn vui lòng đăng nhập lại!'
                    //         // )
                    //     }
                    // })
                }
                break
            default:
                break
        }
    },
    async (error) => {
        const { response } = error
        if (response == null) {
            return Promise.reject(error)
        }
        const { data } = response
        if (data.hasOwnProperty('errors') && !data.isSuccess) {
            return Promise.reject(
                createError(
                    response.status,
                    response.status,
                    data.message,
                    data.errors.stacks,
                    data.errors.code
                )
            )
        }
        return Promise.reject(createError(response.status))
    }
)

export default instance
