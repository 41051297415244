import '../style.css'

import {
    Alert,
    Box,
    Button,
    IconButton,
    Skeleton,
    Tooltip,
    Typography,
} from '@mui/material'
import React, { useState } from 'react'

import FacebookIcon from '@mui/icons-material/Facebook'
import { FacebookShareButton } from 'react-share'
import HTMLParser from 'html-react-parser'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import LinkIcon from '@mui/icons-material/Link'
import MarkDown from '../../../components/MarkDown'
import { PATH_PAGE } from '../../../config/constant'
import { useNavigate } from 'react-router-dom'

const ContentPost = ({ post, contentPost }) => {
    const navigate = useNavigate()
    const [clickContent, setClickContent] = useState(false)
    if (!post || !contentPost) {
        return <>Loading...</>
    }
    const date = new Date(post.updatedAt)
    const shortDate = `Tháng ${date.getMonth()} năm ${date.getFullYear()}`
    const avgWordsPerMin = 250
    const time = Math.ceil(contentPost.match(/\w+/g).length / avgWordsPerMin)

    const copy = () => {
        const el = document.createElement('input')
        el.value = window.location.href
        document.body.appendChild(el)
        el.select()
        document.execCommand('copy')
        document.body.removeChild(el)
        setClickContent('Sao chép đường dẫn thành công')
    }
    const bookmark = () => {
        const items = localStorage.getItem('bookmarks')
        if (!items) {
            localStorage.setItem('bookmarks', JSON.stringify([post]))
        } else {
            localStorage.setItem(
                'bookmarks',
                JSON.stringify(JSON.parse(items).push(post))
            )
        }
        setClickContent('Đã lưu vào danh sách đọc sau')
    }

    return (
        <>
            {!!clickContent && (
                <Alert
                    severity='success'
                    color='success'
                    onClose={() => setClickContent(false)}
                >
                    {clickContent}
                </Alert>
            )}

            <Box className='post-content' p={2}>
                <Box
                    display='flex'
                    flexDirection='row'
                    justifyContent='space-between'
                    mb={4}
                >
                    <div>
                        <Typography variant='body1' color='textSecondary'>
                            {shortDate}&nbsp;&nbsp;&nbsp; {time} phút đọc
                        </Typography>
                    </div>
                    <div>
                        <Box display='inline-flex'>
                            <FacebookShareButton url={window.location.href}>
                                <Tooltip
                                    title='Chia sẻ lên Facebook'
                                    sx={{ mr: '4px' }}
                                >
                                    <IconButton component='span'>
                                        <FacebookIcon />
                                    </IconButton>
                                </Tooltip>
                            </FacebookShareButton>

                            {/* <Tooltip
                                    title='Chia sẻ lên Twitter'
                                    sx={{ mr: '4px' }}
                                >
                                    <IconButton
                                        aria-label='upload picture'
                                        component='span'
                                    >
                                        <TwitterIcon />
                                    </IconButton>
                                </Tooltip> */}
                            <Tooltip
                                title='Sao chép đường dẫn'
                                sx={{ mr: '18px' }}
                            >
                                <div onClick={copy}>
                                    <IconButton component='span'>
                                        <LinkIcon />
                                    </IconButton>
                                </div>
                            </Tooltip>
                            {/* <Tooltip title='Đánh dấu đọc sau'>
                                    <div onClick={bookmark}>
                                        <IconButton component='span'>
                                            <BookmarkBorderIcon />
                                        </IconButton>
                                    </div>
                                </Tooltip> */}
                        </Box>
                    </div>
                </Box>

                <Typography
                    gutterBottom
                    variant='h3'
                    component='h1'
                    color='inherit'
                    fontWeight='bold'
                >
                    {post.title}
                </Typography>
                <LazyLoadImage
                    src={process.env.REACT_APP_BK_ENDPOINT + post.img}
                    alt={`hinh-anh-${post?.slug}`}
                    width='100%'
                />
                <Box
                    display='flex'
                    alignItems='center'
                    alignContent='center'
                    columnGap={10}
                    mt={4}
                >
                    <Typography variant='inherit' sx={{ color: 'gray' }}>
                        Sử dụng công cụ tra cứu để khám phá các chỉ số của riêng
                        bản thân bạn
                    </Typography>
                    <Button
                        variant='contained'
                        color='success'
                        size='small'
                        onClick={() => navigate(PATH_PAGE.human_arithmetic)}
                    >
                        Tra Cứu Miễn Phí
                    </Button>
                </Box>

                <Typography paragraph variant='subtitle1' component='p' mt={4}>
                    {post.description
                        ? HTMLParser(post.description)
                        : new Array(3)
                              .fill(1)
                              .map(() => (
                                  <Skeleton
                                      animation='wave'
                                      variant='text'
                                      height={30}
                                      width='100%'
                                  />
                              ))}
                </Typography>
                <MarkDown
                    key={contentPost.substring(0, 40)}
                    children={contentPost}
                />
            </Box>
        </>
    )
}

export default ContentPost
