import { isEnglish, isVietnamese } from '../../helpers/characterTransform'
import { isInt, isValidDate } from '../../helpers/validateInput'
import Validator from '../../utils/Validator'

const validator = new Validator([
    {
        field: 'fullname',
        method: (value, field, state) => {
            return (
                value.match(/[%<>\\$'"]/) || value.trim().split(' ').length < 2
            )
        },
        message: 'Vui lòng nhập đầy đủ họ và tên',
        validWhen: false,
        args: [],
    },
    {
        field: 'fullname',
        method: (value, field, state) => {
            return isVietnamese(value) || isEnglish(value)
        },
        message:
            'Hệ thống chỉ hỗ trợ tên Tiếng Việt và Tiếng Anh (The system only supports Vietnamese and English name)',
        validWhen: true,
        args: [],
    },
    {
        field: 'shortname',
        method: (value, field, state) => {
            return isVietnamese(value) || isEnglish(value)
        },
        message:
            'Hệ thống chỉ hỗ trợ tên Tiếng Việt và Tiếng Anh (The system only supports Vietnamese and English name)',
        validWhen: true,
        args: [],
    },
    {
        field: 'gender',
        method: (value, field, state) => {
            return (
                parseInt(value) === 1 ||
                parseInt(value) === 2 ||
                parseInt(value) === 3
            )
        },
        message: 'Giới tính không hợp lệ',
        validWhen: true,
        args: [],
    },
    {
        field: 'date',
        method: (value, field, state) => {
            return isInt(value) && value <= 31 && value > 0
        },
        message: 'Ngày sinh không hợp lệ',
        validWhen: true,
        args: [],
    },
    {
        field: 'month',
        method: (value, field, state) => {
            return isInt(value) && value <= 12 && value > 0
        },
        message: 'Tháng sinh không hợp lệ',
        validWhen: true,
        args: [],
    },
    {
        field: 'year',
        method: (value, field, state) => {
            return isInt(value) && value >= 1000
        },
        message: 'Năm sinh không hợp lệ',
        validWhen: true,
        args: [],
    },
])

export default validator
