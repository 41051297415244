import modalConstants from '../../config/constant/modal.constant'

const init = {
    tabModal: false,
}

function modalReducer(state = init, action) {
    switch (action.type) {
        case modalConstants.OPEN_TAB_MODAL:
            return {
                ...state,
                tabModal: true,
            }
        case modalConstants.CLOSE_TAB_MODAL:
            return {
                ...state,
                tabModal: false,
            }
        default:
            return state
    }
}

export default modalReducer
