import CalculateFullname from './calculateFullname'

class MaTranMuiTen {
    arrNum = []
    matran = []

    bindDatetimeNumber(datetime) {
        const date = datetime.getDate()
        const month = datetime.getMonth() + 1
        const year = datetime.getFullYear()
        return `${date}${month}${year}`
            .split('')
            .map((i) => parseInt(i))
            .filter((i) => i !== 0)
    }

    bindStringNumber(str) {
        const nameThanSo = new CalculateFullname(str)
        return [...nameThanSo.arrNumberVowel, ...nameThanSo.arrNumberConsonant]
    }

    _initMatran() {
        this.matran = [
            [
                { value: '', display: 0, sign: [], special: [], empty: 0 }, // sign in range [1, 8]
                { value: '', display: 0, sign: [], special: [], empty: 0 }, // sign in range [1, 8]
                { value: '', display: 0, sign: [], special: [], empty: 0 }, // sign in range [1, 8]
            ],
            [
                { value: '', display: 0, sign: [], special: [], empty: 0 }, // sign in range [1, 8]
                { value: '', display: 0, sign: [], special: [], empty: 0 }, // sign in range [1, 8]
                { value: '', display: 0, sign: [], special: [], empty: 0 }, // sign in range [1, 8]
            ],
            [
                { value: '', display: 0, sign: [], special: [], empty: 0 }, // sign in range [1, 8]
                { value: '', display: 0, sign: [], special: [], empty: 0 }, // sign in range [1, 8]
                { value: '', display: 0, sign: [], special: [], empty: 0 }, // sign in range [1, 8]
            ],
        ]
    }

    findEmptyNumber() {
        for (let i = 0; i < this.matran.length; i++) {
            for (let j = 0; j < this.matran[0].length; j++) {
                if (this.matran[i][j].value !== '') {
                    if (!this._checkIsEmpty(this.matran, i, j)) {
                        this.matran[i][j].empty = 1
                    }
                }
            }
        }
    }

    _checkIsEmpty(matran, i, j) {
        let f = ''
        if (i + 1 < 3) {
            f += matran[i + 1][j].value
        }
        if (i - 1 >= 0) {
            f += matran[i - 1][j].value
        }
        if (j + 1 < 3) {
            f += matran[i][j + 1].value
        }
        if (j - 1 >= 0) {
            f += matran[i][j - 1].value
        }
        if (i - 1 >= 0 && j - 1 >= 0) {
            f += matran[i - 1][j - 1].value
        }
        if (i - 1 >= 0 && j + 1 < 3) {
            f += matran[i - 1][j + 1].value
        }
        if (i + 1 < 3 && j + 1 < 3) {
            f += matran[i + 1][j + 1].value
        }
        if (i + 1 < 3 && j - 1 >= 0) {
            f += matran[i + 1][j - 1].value
        }
        return f
    }

    bindMatran(value) {
        if (typeof value === 'string') {
            this.arrNum = this.bindStringNumber(value)
        } else {
            this.arrNum = this.bindDatetimeNumber(value)
        }
        this._initMatran()
        this.arrNum.forEach((number) => {
            const modulo = number % 3
            const col = Math.ceil(number / 3) - 1
            const row = modulo === 0 ? modulo : modulo === 1 ? 2 : 1
            this.matran[row][col].value += number
        })
        return this.matran
    }

    bindMatranMix(datetime, name) {
        this.arrNum = [
            ...this.bindDatetimeNumber(datetime),
            ...this.bindStringNumber(name),
        ]
        this._initMatran()
        this.arrNum.forEach((number) => {
            const modulo = number % 3
            const col = Math.ceil(number / 3) - 1
            const row = modulo === 0 ? modulo : modulo === 1 ? 2 : 1
            this.matran[row][col].value += number
        })
        return this.matran
    }

    getListNumber() {
        const listNumber = []
        this.matran.forEach((rows, i) => {
            rows.forEach((column, j) => {
                const value = column.value
                if (value) {
                    listNumber.push({
                        num: parseInt(value),
                        repeat: value.length,
                    })
                } else {
                    listNumber.push({ num: 3 - i + 3 * j, repeat: 0 })
                }
            })
        })
        return listNumber
    }

    bindArrow() {
        throw new Error('overide')
    }

    getListArrow() {
        throw new Error('overide')
    }
}

export class MatranMuiTenNgaySinh extends MaTranMuiTen {
    constructor(datetime) {
        super()
        this.bindMatran(datetime)
        this.findEmptyNumber()
        this.bindArrow()
    }

    bindArrow() {
        if (this.hasDoubleArrow159()) {
            this.matran[2][0].special.push(-1)
            this.matran[1][1].special.push(-1)
            this.matran[0][2].special.push(-1)

            this.matran[2][0].special.push(-3)
            this.matran[1][1].special.push(-3)
            this.matran[0][2].special.push(-3)

            this.matran[1][0].special.push(-2)
            this.matran[0][1].special.push(-2)
            this.matran[2][1].special.push(-4)
            this.matran[1][2].special.push(-4)
        } else if (this.hasArrow159() || this.hasEmptyArrow159()) {
            this.matran[2][0].sign.push(3)
            this.matran[1][1].sign.push(3)
            this.matran[0][2].sign.push(3)
        }
        if (this.hasArrow357() || this.hasEmptyArrow357()) {
            this.matran[0][0].sign.push(5)
            this.matran[1][1].sign.push(5)
            this.matran[2][2].sign.push(5)
        }
        if (this.hasArrow369() || this.hasEmptyArrow369()) {
            this.matran[0][0].sign.push(4)
            this.matran[0][1].sign.push(4)
            this.matran[0][2].sign.push(4)
        }
        if (this.hasArrow258() || this.hasEmptyArrow258()) {
            this.matran[1][0].sign.push(4)
            this.matran[1][1].sign.push(4)
            this.matran[1][2].sign.push(4)
        }
        if (this.hasArrow147() || this.hasEmptyArrow147()) {
            this.matran[2][0].sign.push(4)
            this.matran[2][1].sign.push(4)
            this.matran[2][2].sign.push(4)
        }
        if (this.hasArrow123()) {
            this.matran[2][0].sign.push(2)
            this.matran[1][0].sign.push(2)
            this.matran[0][0].sign.push(2)
        }
        if (this.hasArrow456() || this.hasEmptyArrow456()) {
            this.matran[2][1].sign.push(2)
            this.matran[1][1].sign.push(2)
            this.matran[0][1].sign.push(2)
        }
        if (this.hasArrow789() || this.hasEmptyArrow789()) {
            this.matran[2][2].sign.push(2)
            this.matran[1][2].sign.push(2)
            this.matran[0][2].sign.push(2)
        }
    }

    // mui ten 1-5-9
    hasArrow159() {
        return (
            this.matran[2][0].value !== '' &&
            this.matran[1][1].value !== '' &&
            this.matran[0][2].value !== ''
        )
    }

    // mui ten trong 1-5-9
    hasEmptyArrow159() {
        return (
            this.matran[2][0].value === '' &&
            this.matran[1][1].value === '' &&
            this.matran[0][2].value === ''
        )
    }

    hasDoubleArrow159() {
        return (
            (this.matran[2][0].value === '11' &&
                this.matran[1][1].value === '55' &&
                this.matran[0][2].value === '99') ||
            (this.matran[2][0].value === '111' &&
                this.matran[1][1].value === '555' &&
                this.matran[0][2].value === '999')
        )
    }
    // mui ten 3-5-7
    hasArrow357() {
        return (
            this.matran[0][0].value !== '' &&
            this.matran[1][1].value !== '' &&
            this.matran[2][2].value !== ''
        )
    }

    // mui ten trong 3-5-7
    hasEmptyArrow357() {
        return (
            this.matran[0][0].value === '' &&
            this.matran[1][1].value === '' &&
            this.matran[2][2].value === ''
        )
    }

    // mui ten 3-6-9
    hasArrow369() {
        return (
            this.matran[0][0].value !== '' &&
            this.matran[0][1].value !== '' &&
            this.matran[0][2].value !== ''
        )
    }

    // mui ten trong 3-6-9
    hasEmptyArrow369() {
        return (
            this.matran[0][0].value === '' &&
            this.matran[0][1].value === '' &&
            this.matran[0][2].value === ''
        )
    }

    // mui ten 2-5-8
    hasArrow258() {
        return (
            this.matran[1][0].value !== '' &&
            this.matran[1][1].value !== '' &&
            this.matran[1][2].value !== ''
        )
    }

    // mui ten trong 2-5-8
    hasEmptyArrow258() {
        return (
            this.matran[1][0].value === '' &&
            this.matran[1][1].value === '' &&
            this.matran[1][2].value === ''
        )
    }

    // mui ten 1-4-7
    hasArrow147() {
        return (
            this.matran[2][0].value !== '' &&
            this.matran[2][1].value !== '' &&
            this.matran[2][2].value !== ''
        )
    }

    // mui ten trong 1-4-7
    hasEmptyArrow147() {
        return (
            this.matran[2][0].value === '' &&
            this.matran[2][1].value === '' &&
            this.matran[2][2].value === ''
        )
    }

    // mui ten 1-2-3
    hasArrow123() {
        return (
            this.matran[2][0].value !== '' &&
            this.matran[1][0].value !== '' &&
            this.matran[0][0].value !== ''
        )
    }

    // mui ten 4-5-6
    hasArrow456() {
        return (
            this.matran[2][1].value !== '' &&
            this.matran[1][1].value !== '' &&
            this.matran[0][1].value !== ''
        )
    }

    // mui ten trong 4-5-6
    hasEmptyArrow456() {
        return (
            this.matran[2][1].value === '' &&
            this.matran[1][1].value === '' &&
            this.matran[0][1].value === ''
        )
    }

    // mui ten 7-8-9
    hasArrow789() {
        return (
            this.matran[2][2].value !== '' &&
            this.matran[1][2].value !== '' &&
            this.matran[0][2].value !== ''
        )
    }

    // mui ten trong 7-8-9
    hasEmptyArrow789() {
        return (
            this.matran[2][2].value === '' &&
            this.matran[1][2].value === '' &&
            this.matran[0][2].value === ''
        )
    }

    getListArrow() {
        const listArrow = []
        if (this.hasDoubleArrow159()) {
            // listArrow.push('Mũi tên 1-5-9 (Mũi tên Quyết tâm kép)');
            listArrow.push(1592)
        } else if (this.hasArrow159()) {
            listArrow.push(159)
        }
        if (this.hasEmptyArrow159()) {
            // listArrow.push('Mũi tên trống 1-5-9 (Mũi tên Trì hoãn)');
            listArrow.push(1590)
        }
        if (this.hasArrow357()) {
            // listArrow.push('Mũi tên 3-5-7 (Mũi tên Tâm linh)');
            listArrow.push(357)
        }
        if (this.hasEmptyArrow357()) {
            // listArrow.push('Mũi tên trống 3-5-7 (Mũi tên Hoài nghi)');
            listArrow.push(3570)
        }
        if (this.hasArrow369()) {
            // listArrow.push('Mũi tên 3-6-9 (Mũi tên Trí tuệ)');
            listArrow.push(369)
        }
        if (this.hasEmptyArrow369()) {
            // listArrow.push('Mũi tên trống 3-6-9 (Mũi tên Trí nhớ ngắn hạn)');
            listArrow.push(3690)
        }
        if (this.hasArrow258()) {
            // listArrow.push('Mũi tên 2-5-8 (Mũi tên Cân bằng cảm xúc)');
            listArrow.push(258)
        }
        if (this.hasEmptyArrow258()) {
            // listArrow.push('Mũi tên trống 2-5-8 (Mũi tên Nhạy cảm)');
            listArrow.push(2580)
        }
        if (this.hasArrow147()) {
            // listArrow.push('Mũi tên 1-4-7 (Mũi tên Thực tế)');
            listArrow.push(147)
        }
        if (this.hasEmptyArrow147()) {
            // listArrow.push('Mũi tên trống 1-4-7 (Mũi tên Thiếu trật tự)');
            listArrow.push(1470)
        }
        if (this.hasArrow123()) {
            // listArrow.push('Mũi tên 1-2-3 (Mũi tên Kế hoạch)');
            listArrow.push(123)
        }
        if (this.hasArrow456()) {
            // listArrow.push('Mũi tên 4-5-6 (Mũi tên Ý chí)');
            listArrow.push(456)
        }
        if (this.hasEmptyArrow456()) {
            // listArrow.push('Mũi tên trống 4-5-6 (Mũi tên Uất giận)');
            listArrow.push(4560)
        }
        if (this.hasArrow789()) {
            // listArrow.push('Mũi tên 7-8-9 (Mũi tên Hoạt động)');
            listArrow.push(789)
        }
        if (this.hasEmptyArrow789()) {
            // listArrow.push('Mũi tên trống 7-8-9 (Mũi tên Thụ động)');
            listArrow.push(7890)
        }
        console.log('listArrow:::', listArrow)
        return listArrow
    }
}

export class MatranMuiTenTenRieng extends MaTranMuiTen {
    constructor(name) {
        super()
        this.bindMatran(name)
        this.findEmptyNumber()
        this.bindArrow()
    }
    bindArrow() {
        if (this.hasArrow147()) {
            this.matran[2][0].sign.push(4)
            this.matran[2][1].sign.push(4)
            this.matran[2][2].sign.push(4)
        }
        if (this.hasArrow258()) {
            this.matran[1][0].sign.push(4)
            this.matran[1][1].sign.push(4)
            this.matran[1][2].sign.push(4)
        }
        if (this.hasArrow369()) {
            this.matran[0][0].sign.push(4)
            this.matran[0][1].sign.push(4)
            this.matran[0][2].sign.push(4)
        }
    }

    getListArrow() {
        const listArrow = []
        if (this.hasArrow147()) {
            listArrow.push(147)
        }
        if (this.hasArrow258()) {
            listArrow.push(258)
        }
        if (this.hasArrow369()) {
            listArrow.push(369)
        }
        return listArrow
    }

    hasArrow147() {
        return (
            this.matran[2][0].value !== '' &&
            this.matran[2][1].value !== '' &&
            this.matran[2][2].value !== ''
        )
    }

    hasArrow258() {
        return (
            this.matran[1][0].value !== '' &&
            this.matran[1][1].value !== '' &&
            this.matran[1][2].value !== ''
        )
    }

    hasArrow369() {
        return (
            this.matran[0][0].value !== '' &&
            this.matran[0][1].value !== '' &&
            this.matran[0][2].value !== ''
        )
    }
}

export class MatranMuiTenTongHop extends MaTranMuiTen {
    constructor(datetime, name) {
        super()
        this.bindMatranMix(datetime, name)
        this.findEmptyNumber()
        this.bindArrow()
    }
    bindArrow() {
        if (this.hasDoubleArrow159()) {
            this.matran[2][0].special.push(-1)
            this.matran[1][1].special.push(-1)
            this.matran[0][2].special.push(-1)

            this.matran[2][0].special.push(-3)
            this.matran[1][1].special.push(-3)
            this.matran[0][2].special.push(-3)

            this.matran[1][0].special.push(-2)
            this.matran[0][1].special.push(-2)
            this.matran[2][1].special.push(-4)
            this.matran[1][2].special.push(-4)
        } else if (this.hasArrow159() || this.hasEmptyArrow159()) {
            this.matran[2][0].sign.push(3)
            this.matran[1][1].sign.push(3)
            this.matran[0][2].sign.push(3)
        }
        if (this.hasArrow357() || this.hasEmptyArrow357()) {
            this.matran[0][0].sign.push(5)
            this.matran[1][1].sign.push(5)
            this.matran[2][2].sign.push(5)
        }
        if (this.hasArrow369() || this.hasEmptyArrow369()) {
            this.matran[0][0].sign.push(4)
            this.matran[0][1].sign.push(4)
            this.matran[0][2].sign.push(4)
        }
        if (this.hasArrow258() || this.hasEmptyArrow258()) {
            this.matran[1][0].sign.push(4)
            this.matran[1][1].sign.push(4)
            this.matran[1][2].sign.push(4)
        }
        if (this.hasArrow147() || this.hasEmptyArrow147()) {
            this.matran[2][0].sign.push(4)
            this.matran[2][1].sign.push(4)
            this.matran[2][2].sign.push(4)
        }
        if (this.hasArrow123()) {
            this.matran[2][0].sign.push(2)
            this.matran[1][0].sign.push(2)
            this.matran[0][0].sign.push(2)
        }
        if (this.hasArrow456() || this.hasEmptyArrow456()) {
            this.matran[2][1].sign.push(2)
            this.matran[1][1].sign.push(2)
            this.matran[0][1].sign.push(2)
        }
        if (this.hasArrow789() || this.hasEmptyArrow789()) {
            this.matran[2][2].sign.push(2)
            this.matran[1][2].sign.push(2)
            this.matran[0][2].sign.push(2)
        }
    }

    // mui ten 1-5-9
    hasArrow159() {
        return (
            this.matran[2][0].value !== '' &&
            this.matran[1][1].value !== '' &&
            this.matran[0][2].value !== ''
        )
    }

    // mui ten trong 1-5-9
    hasEmptyArrow159() {
        return (
            this.matran[2][0].value === '' &&
            this.matran[1][1].value === '' &&
            this.matran[0][2].value === ''
        )
    }

    hasDoubleArrow159() {
        return (
            (this.matran[2][0].value === '11' &&
                this.matran[1][1].value === '55' &&
                this.matran[0][2].value === '99') ||
            (this.matran[2][0].value === '111' &&
                this.matran[1][1].value === '555' &&
                this.matran[0][2].value === '999')
        )
    }
    // mui ten 3-5-7
    hasArrow357() {
        return (
            this.matran[0][0].value !== '' &&
            this.matran[1][1].value !== '' &&
            this.matran[2][2].value !== ''
        )
    }

    // mui ten trong 3-5-7
    hasEmptyArrow357() {
        return (
            this.matran[0][0].value === '' &&
            this.matran[1][1].value === '' &&
            this.matran[2][2].value === ''
        )
    }

    // mui ten 3-6-9
    hasArrow369() {
        return (
            this.matran[0][0].value !== '' &&
            this.matran[0][1].value !== '' &&
            this.matran[0][2].value !== ''
        )
    }

    // mui ten trong 3-6-9
    hasEmptyArrow369() {
        return (
            this.matran[0][0].value === '' &&
            this.matran[0][1].value === '' &&
            this.matran[0][2].value === ''
        )
    }

    // mui ten 2-5-8
    hasArrow258() {
        return (
            this.matran[1][0].value !== '' &&
            this.matran[1][1].value !== '' &&
            this.matran[1][2].value !== ''
        )
    }

    // mui ten trong 2-5-8
    hasEmptyArrow258() {
        return (
            this.matran[1][0].value === '' &&
            this.matran[1][1].value === '' &&
            this.matran[1][2].value === ''
        )
    }

    // mui ten 1-4-7
    hasArrow147() {
        return (
            this.matran[2][0].value !== '' &&
            this.matran[2][1].value !== '' &&
            this.matran[2][2].value !== ''
        )
    }

    // mui ten trong 1-4-7
    hasEmptyArrow147() {
        return (
            this.matran[2][0].value === '' &&
            this.matran[2][1].value === '' &&
            this.matran[2][2].value === ''
        )
    }

    // mui ten 1-2-3
    hasArrow123() {
        return (
            this.matran[2][0].value !== '' &&
            this.matran[1][0].value !== '' &&
            this.matran[0][0].value !== ''
        )
    }

    // mui ten 4-5-6
    hasArrow456() {
        return (
            this.matran[2][1].value !== '' &&
            this.matran[1][1].value !== '' &&
            this.matran[0][1].value !== ''
        )
    }

    // mui ten trong 4-5-6
    hasEmptyArrow456() {
        return (
            this.matran[2][1].value === '' &&
            this.matran[1][1].value === '' &&
            this.matran[0][1].value === ''
        )
    }

    // mui ten 7-8-9
    hasArrow789() {
        return (
            this.matran[2][2].value !== '' &&
            this.matran[1][2].value !== '' &&
            this.matran[0][2].value !== ''
        )
    }

    // mui ten trong 7-8-9
    hasEmptyArrow789() {
        return (
            this.matran[2][2].value === '' &&
            this.matran[1][2].value === '' &&
            this.matran[0][2].value === ''
        )
    }

    getListArrow() {
        const listArrow = []
        if (this.hasDoubleArrow159()) {
            // listArrow.push('Mũi tên 1-5-9 (Mũi tên Quyết tâm kép)');
            listArrow.push(1592)
        } else if (this.hasArrow159()) {
            listArrow.push(159)
        }
        if (this.hasEmptyArrow159()) {
            // listArrow.push('Mũi tên trống 1-5-9 (Mũi tên Trì hoãn)');
            listArrow.push(1590)
        }
        if (this.hasArrow357()) {
            // listArrow.push('Mũi tên 3-5-7 (Mũi tên Tâm linh)');
            listArrow.push(357)
        }
        if (this.hasEmptyArrow357()) {
            // listArrow.push('Mũi tên trống 3-5-7 (Mũi tên Hoài nghi)');
            listArrow.push(3570)
        }
        if (this.hasArrow369()) {
            // listArrow.push('Mũi tên 3-6-9 (Mũi tên Trí tuệ)');
            listArrow.push(369)
        }
        if (this.hasEmptyArrow369()) {
            // listArrow.push('Mũi tên trống 3-6-9 (Mũi tên Trí nhớ ngắn hạn)');
            listArrow.push(3690)
        }
        if (this.hasArrow258()) {
            // listArrow.push('Mũi tên 2-5-8 (Mũi tên Cân bằng cảm xúc)');
            listArrow.push(258)
        }
        if (this.hasEmptyArrow258()) {
            // listArrow.push('Mũi tên trống 2-5-8 (Mũi tên Nhạy cảm)');
            listArrow.push(2580)
        }
        if (this.hasArrow147()) {
            // listArrow.push('Mũi tên 1-4-7 (Mũi tên Thực tế)');
            listArrow.push(147)
        }
        if (this.hasEmptyArrow147()) {
            // listArrow.push('Mũi tên trống 1-4-7 (Mũi tên Thiếu trật tự)');
            listArrow.push(1470)
        }
        if (this.hasArrow123()) {
            // listArrow.push('Mũi tên 1-2-3 (Mũi tên Kế hoạch)');
            listArrow.push(123)
        }
        if (this.hasArrow456()) {
            // listArrow.push('Mũi tên 4-5-6 (Mũi tên Ý chí)');
            listArrow.push(456)
        }
        if (this.hasEmptyArrow456()) {
            // listArrow.push('Mũi tên trống 4-5-6 (Mũi tên Uất giận)');
            listArrow.push(4560)
        }
        if (this.hasArrow789()) {
            // listArrow.push('Mũi tên 7-8-9 (Mũi tên Hoạt động)');
            listArrow.push(789)
        }
        if (this.hasEmptyArrow789()) {
            // listArrow.push('Mũi tên trống 7-8-9 (Mũi tên Thụ động)');
            listArrow.push(7890)
        }
        return listArrow
    }
}
