import userConstants from '../../config/constant/user.constant'

const user = JSON.parse(localStorage.getItem('user'))
const initialState = user ? { loggedIn: true, user } : {}

function authReducer(state = initialState, action) {
    switch (action.type) {
        case userConstants.WHO_AM_I:
            return {
                loggedIn: true,
                user: action.payload,
            }
        case userConstants.LOGIN_REQUEST:
            return {
                loggedIn: false,
                user: {},
            }
        case userConstants.LOGIN_SUCCESS:
            return {
                loggedIn: true,
                user: action.payload,
            }
        case userConstants.LOGIN_FAILURE:
            return {}
        case userConstants.REGISTER_REQUEST:
            return {
                loggedIn: false,
                user: {},
            }
        case userConstants.REGISTER_SUCCESS:
            return {
                loggedIn: false,
                user: {},
            }
        case userConstants.REGISTER_FAILURE:
            return {}
        case userConstants.LOGOUT:
            return {
                loggedIn: false,
                user: {},
            }
        case userConstants.GET_USER_PROFILE:
            return {
                loggedIn: true,
                user: action.payload,
            }
        case userConstants.CHANGE_PASSWORD_SUCCESS:
            return {
                loggedIn: false,
                user: {},
            }
        case userConstants.UPDATE_PROFILE_SUCCESS:
            return {
                loggedIn: true,
                user: action.payload,
            }
        default:
            return state
    }
}

export default authReducer
