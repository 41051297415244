import './style.css'

import {
    AppBar,
    Button,
    IconButton,
    List,
    Toolbar,
    useTheme,
} from '@mui/material'
import { DrawerCustome, DrawerHeader } from '../UI'
import { Link, useNavigate } from 'react-router-dom'
import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Avatar from '@mui/material/Avatar'
import { Box } from '@mui/system'
import CalculateIcon from '@mui/icons-material/Calculate'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'
import HideOnScroll from '../HideOnScroll'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemLink from '../ListItemLink'
import ListItemText from '@mui/material/ListItemText'
import LoginIcon from '@mui/icons-material/Login'
import LogoutIcon from '@mui/icons-material/Logout'
import MenuIcon from '@mui/icons-material/Menu'
import MuiLink from '@mui/material/Link'
import { PATH_PAGE } from '../../config/constant'
import PolicyOutlined from '@mui/icons-material/PolicyOutlined'
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined'
import Slide from '@mui/material/Slide'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'
import authActions from '../../../src/redux/actions/auth.action'
import { authSelector } from '../../redux/selector/auth.selector'
import modalActions from '../../redux/actions/modal.action'

const MainItems = [
    {
        display: true,
        key: 'home',
        href: PATH_PAGE.home,
        primary: 'Trang chủ',
        icon: <HomeOutlinedIcon />,
    },
    {
        display: false,
        key: 'calendar',
        href: PATH_PAGE.calendar,
        primary: 'Lịch vạn niên',
        icon: <CalendarMonthOutlinedIcon />,
    },
    {
        display: true,
        key: 'human-arithmetic',
        href: PATH_PAGE.human_arithmetic,
        primary: 'Nhân số học',
        icon: <CalculateIcon />,
    },
    {
        display: true,
        key: 'human-arithmetic-theory',
        href: PATH_PAGE.human_arithmetic_theory,
        primary: 'Khám phá',
        icon: <LibraryBooksOutlinedIcon />,
    },
    /* {
        display: true,
        key: 'human-arithmetic-blog',
        href: "https://blog.nhansodaiviet.com/blog",
        primary: 'Bài Viết',
        icon: <BookOutlinedIcon />,
    } */
    // {
    //     display: true,
    //     key: 'shopping',
    //     href: PATH_PAGE.shopping,
    //     primary: 'Mua sắm',
    //     icon: <ShoppingBagOutlinedIcon />,
    // },
]

const SupportItems = [
    {
        display: true,
        key: 'about',
        href: PATH_PAGE.about,
        primary: 'Về chúng tôi',
        icon: <InfoOutlinedIcon />,
    },
    {
        display: true,
        key: 'policy',
        href: PATH_PAGE.policy,
        primary: 'Chính sách',
        icon: <PolicyOutlined />,
    },
]

const AdminItems = [
    {
        display: true,
        key: 'manager',
        href: PATH_PAGE.manager,
        primary: 'Quản lí người dùng',
        icon: <SupervisorAccountIcon />,
    },
]

const Navbar = (props) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { loggedIn, user } = useSelector(authSelector)
    const theme = useTheme()

    const [openDrawer, setOpenDrawer] = useState(false)
    const handleDrawerOpen = () => setOpenDrawer(true)
    const handleDrawerClose = () => setOpenDrawer(false)
    const [openAlert, setOpenAlert] = useState(false)

    const handleOpenAlert = useCallback(() => setOpenAlert(true), [])
    const handleCloseAlert = useCallback(() => setOpenAlert(false), [])
    const handleSignOut = () => {
        dispatch(authActions.logout()).then(() => navigate('/'))
    }
    return (
        <>
            <header>
                <Box display='flex' className='navbar-area'>
                    <HideOnScroll {...props}>
                        <AppBar
                            position='fixed'
                            open={openDrawer}
                            sx={{
                                backgroundColor: 'var(--black-400)',
                                backgroundImage: 'none',
                                boxShadow: 'none',
                                padding: '0 !important',
                            }}
                        >
                            <Toolbar>
                                <MuiLink
                                    component={Link}
                                    className='navbar-brand'
                                    to='/'
                                    sx={{
                                        textDecoration: 'none',
                                    }}
                                >
                                    <h3 className='title'>Nhân Số Đại Việt</h3>
                                </MuiLink>
                                {loggedIn ? (
                                    <Avatar
                                        src={user.avatarUrl || ''}
                                        sx={{
                                            marginRight: '2vw',
                                            cursor: 'pointer',
                                        }}
                                        onClick={(e) => {
                                            navigate(PATH_PAGE.account_profile)
                                        }}
                                    />
                                ) : (
                                    <Button
                                        sx={{
                                            mr: 5,
                                        }}
                                        type='button'
                                        color='error'
                                        variant='contained'
                                        onClick={() =>
                                            dispatch(
                                                modalActions.openTabModal()
                                            )
                                        }
                                    >
                                        Đăng nhập
                                    </Button>
                                )}
                                <IconButton
                                    aria-label='open drawer'
                                    onClick={handleDrawerOpen}
                                    edge='start'
                                    sx={{
                                        fontSize: '20px',
                                        ...(openDrawer && { display: 'none' }),
                                    }}
                                >
                                    <MenuIcon />
                                </IconButton>
                            </Toolbar>
                        </AppBar>
                    </HideOnScroll>
                    <DrawerCustome
                        variant='persistent'
                        anchor='right'
                        open={openDrawer}
                        onClose={() => setOpenDrawer(false)}
                        onOpen={() => setOpenDrawer(true)}
                    >
                        <DrawerHeader>
                            <IconButton onClick={handleDrawerClose}>
                                {theme.direction === 'ltr' ? (
                                    <ChevronLeftIcon />
                                ) : (
                                    <ChevronRightIcon />
                                )}
                            </IconButton>
                        </DrawerHeader>
                        {loggedIn ? (
                            <>
                                <ListItem disablePadding>
                                    <ListItemButton
                                        onClick={() => {
                                            handleDrawerClose()
                                            navigate(PATH_PAGE.account_profile)
                                        }}
                                        sx={{
                                            marginRight: '1vw',
                                            display: {
                                                md: 'none',
                                                sm: 'none',
                                                xs: 'flex',
                                            },
                                        }}
                                    >
                                        <ListItemIcon>
                                            <Avatar
                                                src={user.avatarUrl || ''}
                                            />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                user.fullname
                                                    ? user.fullname
                                                    : user.username
                                            }
                                        />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemButton
                                        onClick={() => {
                                            handleDrawerClose()
                                            handleOpenAlert()
                                        }}
                                        sx={{
                                            marginRight: '1vw',
                                            display: {
                                                md: 'none',
                                                sm: 'none',
                                                xs: 'flex',
                                            },
                                        }}
                                    >
                                        <ListItemIcon>
                                            <LogoutIcon />
                                        </ListItemIcon>
                                        <ListItemText primary='Đăng xuất' />
                                    </ListItemButton>
                                </ListItem>
                            </>
                        ) : (
                            <ListItem disablePadding>
                                <ListItemButton
                                    onClick={() => {
                                        handleDrawerClose()
                                        dispatch(modalActions.openTabModal())
                                    }}
                                    sx={{
                                        marginRight: '1vw',
                                        display: {
                                            md: 'none',
                                            sm: 'none',
                                            xs: 'flex',
                                        },
                                    }}
                                >
                                    <ListItemIcon>
                                        <LoginIcon />
                                    </ListItemIcon>
                                    <ListItemText primary='Đăng nhập' />
                                </ListItemButton>
                            </ListItem>
                        )}
                        <Divider />
                        <List>
                            {MainItems.filter((e) => e.display).map((item) => (
                                <ListItemLink
                                    key={item.key}
                                    href={item.href}
                                    primary={item.primary}
                                    icon={item.icon}
                                    closeDrawer={handleDrawerClose}
                                />
                            ))}
                        </List>
                        {loggedIn && user.isAdmin && (
                            <>
                                <Divider />
                                <List>
                                    {AdminItems.filter((e) => e.display).map(
                                        (item) => (
                                            <ListItemLink
                                                key={item.key}
                                                href={item.href}
                                                primary={item.primary}
                                                icon={item.icon}
                                                closeDrawer={handleDrawerClose}
                                            />
                                        )
                                    )}
                                </List>
                            </>
                        )}
                        {/* <Divider /> */}
                        <List sx={{ marginTop: 'auto' }}>
                            <List>
                                {SupportItems.filter((e) => e.display).map(
                                    (item) => (
                                        <ListItemLink
                                            key={item.key}
                                            href={item.href}
                                            primary={item.primary}
                                            icon={item.icon}
                                            closeDrawer={handleDrawerClose}
                                        />
                                    )
                                )}
                            </List>
                        </List>
                        <Divider />
                        {loggedIn && (
                            <ListItem disablePadding>
                                <ListItemButton
                                    onClick={() => {
                                        handleDrawerClose()
                                        handleOpenAlert()
                                    }}
                                    sx={{
                                        marginRight: '1vw',
                                        display: {
                                            md: 'flex',
                                            sm: 'flex',
                                            xs: 'none',
                                        },
                                    }}
                                >
                                    <ListItemIcon>
                                        <LogoutIcon />
                                    </ListItemIcon>
                                    <ListItemText primary='Đăng xuất' />
                                </ListItemButton>
                            </ListItem>
                        )}
                    </DrawerCustome>
                    <Dialog
                        open={openAlert}
                        TransitionComponent={Transition}
                        keepMounted
                        aria-describedby='alert-dialog-slide-description'
                    >
                        <DialogTitle>Bạn có chắc muốn đăng xuất ?</DialogTitle>
                        <DialogActions>
                            <Button onClick={handleCloseAlert}>Hủy</Button>
                            <Button
                                onClick={() => {
                                    handleCloseAlert()
                                    handleSignOut()
                                }}
                            >
                                Chắc chắn
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Box>
            </header>
        </>
    )
}
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />
})
export default Navbar
