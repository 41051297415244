import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    Stack,
    TextField,
    Typography,
    Link,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import React, { useState } from 'react'
import Validator from '../../../utils/Validator'
import Google18px from '../../../assets/images/google-18px.svg'
import Facebook18px from '../../../assets/images/facebook-18px.svg'

import authActions from '../../../redux/actions/auth.action'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { useFormik } from 'formik'
import * as Yup from 'yup'

const SignUp = ({ handleChange, onClose }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false)

    const handleSignUp = async (username, password) => {
        setLoading(true)
        await dispatch(authActions.register(username, password))
            .then(() => {
                setLoading(false)
                onClose()
            })
            .catch(() => {
                setLoading(false)
            })
    }

    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
            re_password: '',
        },
        validationSchema: Yup.object({
            username: Yup.string()
                .required('Tên đăng nhập không được để trống!')
                .matches(
                    /^[a-zA-Z0-9]{6,20}$/,
                    'Có 6 - 20 kí tự, có số, chữ cái và không có ký tự đặc biệt'
                ),
            password: Yup.string()
                .required('Mật khẩu không được để trống!')
                .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,12}$/,
                    'Có 8 - 12 kí tự có số, chữ cái in hoa, chữ cái in thường và không có ký tự đặc biệt'
                ),
            re_password: Yup.string()
                .required('Mật khẩu xác nhận không được để trống!')
                .oneOf(
                    [Yup.ref('password'), null],
                    'Mật khẩu xác nhận phải giống mật khẩu!'
                ),
        }),
        onSubmit: ({ username, password }) => {
            handleSignUp(username, password)
        },
    })
    return (
        <>
            <Box
                component='form'
                onSubmit={formik.handleSubmit}
                noValidate
                sx={{ mt: 1 }}
            >
                <TextField
                    margin='normal'
                    required
                    fullWidth
                    size='small'
                    id='username'
                    label='Tên đăng nhập'
                    name='username'
                    error={formik.errors.username}
                    helperText='Có 6 - 20 kí tự, có số, chữ cái và không có ký tự đặc biệt'
                    value={formik.values.username}
                    onChange={formik.handleChange}
                />
                <TextField
                    margin='normal'
                    required
                    fullWidth
                    size='small'
                    name='password'
                    label='Mật khẩu'
                    type={showPassword ? 'text' : 'password'}
                    value={formik.values.password}
                    id='password'
                    error={formik.errors.password}
                    helperText='Có 8 - 12 kí tự có số, chữ cái in hoa, chữ cái in thường và không có ký tự đặc biệt'
                    onChange={formik.handleChange}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position='end'>
                                <IconButton
                                    aria-label='toggle password visibility'
                                    onClick={() =>
                                        setShowPassword(!showPassword)
                                    }
                                >
                                    {showPassword ? (
                                        <VisibilityOff />
                                    ) : (
                                        <Visibility />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <TextField
                    margin='normal'
                    required
                    fullWidth
                    size='small'
                    name='re_password'
                    label='Nhập lại Mật khẩu'
                    type={showPassword ? 'text' : 'password'}
                    value={formik.values.re_password}
                    error={formik.errors.re_password}
                    id='re_password'
                    helperText='Mật khẩu xác nhận phải giống mật khẩu'
                    onChange={formik.handleChange}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position='end'>
                                <IconButton
                                    aria-label='toggle password visibility'
                                    onClick={() =>
                                        setShowPassword(!showPassword)
                                    }
                                >
                                    {showPassword ? (
                                        <VisibilityOff />
                                    ) : (
                                        <Visibility />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                {JSON.stringify(formik.errors) === '{}' ? (
                    <LoadingButton
                        type='submit'
                        fullWidth
                        loading={loading}
                        loadingPosition='start'
                        variant='contained'
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Đăng ký
                    </LoadingButton>
                ) : (
                    <LoadingButton
                        type='submit'
                        fullWidth
                        loadingPosition='start'
                        variant='contained'
                        sx={{ mt: 3, mb: 2 }}
                        disabled
                    >
                        Đăng ký
                    </LoadingButton>
                )}
                <Grid container>
                    <Grid item xs></Grid>
                    <Grid item>
                        <Link
                            href='#'
                            variant='body2'
                            onClick={() => handleChange(0)}
                        >
                            Đăng nhập
                        </Link>
                    </Grid>
                </Grid>
                <Divider
                    sx={{
                        marginY: 2,
                    }}
                >
                    <Typography>hoặc</Typography>
                </Divider>
            </Box>
        </>
    )
}

export default SignUp
