import { createOrder, findOrderPaying } from '../api/shopping.api'

const shoppingService = {
    createOrder: async (payload) => {
        try {
            const response = await createOrder(payload)
            return response.data
        } catch (error) {
            return Promise.reject(error)
        }
    },

    findOrderPaying: async (orderId) => {
        try {
            const response = await findOrderPaying(orderId)
            const data = response.data
            localStorage.setItem('order', JSON.stringify(data))
            return data
        } catch (error) {
            return Promise.reject(error)
        }
    },
}

export default shoppingService
