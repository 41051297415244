//mui import
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';


//mui icon import
import DownloadIcon from '@mui/icons-material/Download';
import FileUploadIcon from '@mui/icons-material/FileUpload';


export default function HeaderUserList() {
    return (
        <Grid container pl={5} pr={5} pt={5} spacing={3}>
            <Grid item md={6} sm={6} xs={12} lg={9}>
                <Typography variant="h4" gutterBottom component="div">
                    Danh sách người dùng
                </Typography>
            </Grid>
            <Grid item md={3} sm={3} xs={6} lg={1.5} flex justifyContent='center' >
                <Button variant="outlined" startIcon={<FileUploadIcon />}>
                    Nhập File
                </Button>
            </Grid>
            <Grid item md={3} sm={3} xs={6} lg={1.5} flex justifyContent='center'>
                <Button variant="outlined" startIcon={<DownloadIcon />}>
                    Xuất File
                </Button>
            </Grid>
        </Grid>
    )
}
