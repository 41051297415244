//react import

import { Box, TextField } from '@mui/material'
import { useEffect, useState } from 'react'

import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import Button from '@mui/material/Button'
import FastForwardIcon from '@mui/icons-material/FastForward'
import FastRewindIcon from '@mui/icons-material/FastRewind'
import Grid from '@mui/material/Grid'
import InputAdornment from '@mui/material/InputAdornment'
import Link from '@mui/material/Link'
import Paper from '@mui/material/Paper'
import SearchIcon from '@mui/icons-material/Search'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import { disable } from 'workbox-navigation-preload'
import { getHistorySearch } from '../../../api/user.api'
import moment from 'moment'

const COLUMNS_TABLE = [
    { key: '1', value: 'Họ tên', alias: 'fullname' },
    { key: '2', value: 'Tên thường gọi', alias: 'finallname' },
    { key: '3', value: 'Giới tính', alias: 'gender' },
]

export default function SearchTable() {
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState('')
    const [page, setPage] = useState(1)
    const [totalPages, setTotalPages] = useState(0)
    const [rows, setRows] = useState([])
    const [dob, setDob] = useState([])

    // code xem lai lich su tra cuu
    const [searchCodes, setSearchCodes] = useState([])

    const limit = 10

    const handleGetHistorySearch = () => {
        const searchList = []
        const searchCodes = []
        const dob = []

        setLoading(true)

        getHistorySearch(search, page, limit).then((response) => {
            const data = response.data
            data.docs.forEach((item) => {
                searchList.push(JSON.parse(item.query))
            })
            data.docs.forEach((item) => {
                searchCodes.push(item.code)
            })
            setSearchCodes(searchCodes)
            setRows(searchList)
            searchList.forEach((item) => {
                dob.push(moment(item.dob).format('MM/DD/YYYY'))
            })
            setDob(dob)
            setTotalPages(data.totalPages)
            setLoading(false)
        })
    }

    const onKeyDownSearch = (e) => {
        if (e.keyCode === 13) {
            handleGetHistorySearch()
        }
    }

    // useEffect(()=>{
    //     handleGetHistorySearch()
    // },[])

    useEffect(() => {
        handleGetHistorySearch()
    }, [page])

    return (
        <>
            {/* <Grid
                container
                mb={5}
                spacing={2}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    paddingLeft: 1,
                    paddingRight: 1,
                }}
            > */}
            <div>
                {/* <Grid item lg={4} md={4} sm={7} xs={9}> */}
                <TextField
                    variant='standard'
                    label='Tìm kiếm'
                    id='outlined-start-adornment'
                    sx={{ width: '100%', maxWidth: 420 }}
                    type='search'
                    placeholder='Tìm kiếm theo Họ và tên'
                    // InputProps={{
                    //     startAdornment: (
                    //         <InputAdornment position='start'>
                    //             <SearchIcon />
                    //         </InputAdornment>
                    //     ),
                    // }}
                    onChange={(e) => setSearch(e.target.value)}
                    onKeyDown={onKeyDownSearch}
                />
                {/* </Grid> */}
                {/* <Grid lg={1} md={1} sm={3} xs={3} item>
                    <Button
                        variant='contained'
                        sx={{ width: '100%', height: '100%' }}
                        onClick={handleGetHistorySearch}
                    >
                        Tìm kiếm
                    </Button>
                </Grid> */}
                {/* </Grid> */}
            </div>
            {loading ? (
                <></>
            ) : (
                <div style={{ margin: '12px 0' }}>
                    <Box
                        sx={{
                            // width: '100vw',
                            // display: 'flex',
                            // flexDirection: 'column',
                            // alignItems: 'center',
                            // paddingBottom: '10vh',
                            marginTop: '15px',
                        }}
                    >
                        {rows && rows.length !== 0 ? (
                            <>
                                <TableContainer
                                    component={Paper}
                                    sx={{
                                        minHeight: '10vh',
                                        // width: {
                                        //     lg: '60vw',
                                        //     md: '95vw',
                                        //     sm: '95vw',
                                        //     xs: '100vw',
                                        // },
                                        // background: 'rgba(0,0,0,0.5)',
                                        // opacity: '100%',
                                    }}
                                >
                                    <Table
                                        sx={{
                                            minWidth: {
                                                lg: '50vw',
                                                md: '50vw',
                                                sm: '120vw',
                                                xs: '200vw',
                                            },
                                        }}
                                        aria-label='simple table'
                                    >
                                        <TableHead>
                                            <TableCell align='center'>
                                                STT
                                            </TableCell>
                                            {COLUMNS_TABLE.map((row) => (
                                                <TableCell
                                                    align='center'
                                                    key={row.key}
                                                >
                                                    {row.value}
                                                </TableCell>
                                            ))}
                                            <TableCell align='center'>
                                                Ngày sinh
                                            </TableCell>
                                            <TableCell align='center'>
                                                Kết quả
                                            </TableCell>
                                        </TableHead>
                                        <TableBody>
                                            {rows.map((row, index) => (
                                                <TableRow
                                                    key={row.id}
                                                    sx={{
                                                        '&:last-child td, &:last-child th':
                                                            { border: 0 },
                                                    }}
                                                >
                                                    <TableCell align='center'>
                                                        {index + 1}
                                                    </TableCell>
                                                    {COLUMNS_TABLE.map(
                                                        (col) => (
                                                            <TableCell
                                                                align='center'
                                                                key={col.key}
                                                            >
                                                                {row[col.alias]}
                                                            </TableCell>
                                                        )
                                                    )}
                                                    <TableCell align='center'>
                                                        {dob[index]}
                                                    </TableCell>
                                                    <TableCell align='center'>
                                                        <Link
                                                            href={`/ket-qua?code=${searchCodes[index]}`}
                                                        >
                                                            Xem chi tiết
                                                        </Link>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                {totalPages !== 1 ? (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: {
                                                lg: 'flex-end',
                                                md: 'flex-end',
                                                sm: 'center',
                                                xs: 'center',
                                            },
                                            alignItems: 'center',
                                            background: 'rgba(0,0,0,0.5)',
                                            width: {
                                                lg: '60vw',
                                                md: '95vw',
                                                sm: '95vw',
                                                xs: '100vw',
                                            },
                                            paddingTop: '2vh',
                                            paddingBottom: '2vh',
                                            borderTop: '1px solid white',
                                            marginBottom: '2vh',
                                            paddingRight: {
                                                xs: '0',
                                                sm: '5',
                                                md: '5',
                                                lg: '5',
                                            },
                                        }}
                                    >
                                        <Button
                                            onClick={() => {
                                                setPage(1)
                                            }}
                                            disabled={page === 1 ? true : false}
                                        >
                                            <FastRewindIcon
                                                sx={{ fontSize: '20px' }}
                                            />
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                setPage(page - 1)
                                            }}
                                            disabled={page === 1 ? true : false}
                                        >
                                            <ArrowLeftIcon
                                                sx={{ fontSize: '25px' }}
                                            />
                                        </Button>
                                        <p style={{ margin: '0 0.5vw' }}>
                                            {page}/{totalPages}
                                        </p>
                                        <Button
                                            disabled={
                                                page === totalPages
                                                    ? true
                                                    : false
                                            }
                                            onClick={() => {
                                                setPage(page + 1)
                                            }}
                                        >
                                            <ArrowRightIcon
                                                sx={{ fontSize: '25px' }}
                                            />
                                        </Button>
                                        <Button
                                            disabled={
                                                page === totalPages
                                                    ? true
                                                    : false
                                            }
                                            onClick={() => {
                                                setPage(totalPages)
                                            }}
                                        >
                                            <FastForwardIcon
                                                sx={{ fontSize: '20px' }}
                                            />
                                        </Button>
                                    </Box>
                                ) : (
                                    <></>
                                )}
                            </>
                        ) : (
                            <Typography
                                variant='h3'
                                component='h3'
                                align='center'
                            >
                                Chưa có lịch sử tra cứu
                            </Typography>
                        )}
                    </Box>
                </div>
            )}
        </>
    )
}
