import { Box, Button, Typography } from '@mui/material'

import { PATH_PAGE } from '../../config/constant'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import Svg404 from '../../assets/images/illustration_404.svg'
import { styled } from '@mui/material/styles'

const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}))

const Error404 = ({ to = PATH_PAGE.home, titleText, desText, btnText }) => {
    return (
        <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
            <Typography variant='h3' component='div'>
                {titleText || 'Trang web không tồn tại!'}
            </Typography>
            <Typography sx={{ color: 'text.secondary', mt: 2 }} paragraph>
                {desText ||
                    'Rất tiếc, Chúng tôi không tìm thấy trang web mà bạn muốn truy cập. Hãy kiểm tra lại đường dẫn của bạn.'}
            </Typography>
            <Box
                component='img'
                src={Svg404}
                sx={{ height: 260, mx: 'auto', my: { xs: 5, sm: 10 } }}
            />
            <Button
                to={to}
                size='large'
                color='error'
                variant='contained'
                component={RouterLink}
            >
                {btnText || 'Quay lại trang chủ'}
            </Button>
        </ContentStyle>
    )
}

export default Error404
