import './style.css'

import React, { useEffect, useRef } from 'react'

import About from './components/About'
import Form from './components/Form'
import Header from '../../components/Header'
import HowItWork from './components/HowItWork'
import Why from './components/Why'

const Home = () => {
    useEffect(() => {
        document.title = 'Nhân Số Đại Việt'
        let description = document.querySelector('meta[name="description"]')
        if (description) {
            description.setAttribute(
                'content',
                'Nhân Số Học (Numerology) là một môn khoa học nghiên cứu về bản thân con người thông qua Họ Tên Và Ngày Sinh của bạn, Nhân Số Đại Việt là website tra cứu thông tin Nhân số Học online chính xác, nhanh chóng. Thông qua Nhân Số Đại Việt bạn có thể hiểu sâu về con người bên trong mình.'
            )
        }
    }, [])

    const formRef = useRef()
    const scrollToForm = () => {
        formRef.current.scrollIntoView({ behavior: 'smooth' })
    }
    return (
        <>
            <Header scrollFormAction={scrollToForm} />
            <Why />
            <Form formRef={formRef} />
            <About />
            <HowItWork scrollFormAction={scrollToForm} />
        </>
    )
}

export default Home
