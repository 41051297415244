import './style.css'
import { List, ListItemButton, ListItemText } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'

const getNestedHeadings = (headingElements) => {
    const nestedHeadings = []
    headingElements.forEach((heading, index) => {
        const { innerText: title, id } = heading
        if (title) {
            if (heading.nodeName === 'H2') {
                nestedHeadings.push({ id, title, items: [] })
            } else if (heading.nodeName === 'H3' && nestedHeadings.length > 0) {
                nestedHeadings[nestedHeadings.length - 1].items.push({
                    id,
                    title,
                })
            }
        }
    })
    return nestedHeadings
}

const useHeadingsData = () => {
    const [nestedHeadings, setNestedHeadings] = useState([])
    useEffect(() => {
        const headingElements = Array.from(document.querySelectorAll('h2'))
        const newNestedHeadings = getNestedHeadings(headingElements)
        setNestedHeadings(newNestedHeadings)
    }, [])
    return { nestedHeadings }
}

const useIntersectionObserver = (setActiveId) => {
    const headingElementsRef = useRef({})
    useEffect(() => {
        const callback = (headings) => {
            headingElementsRef.current = headings.reduce(
                (map, headingElement) => {
                    map[headingElement.target.id] = headingElement
                    return map
                },
                headingElementsRef.current
            )

            const visibleHeadings = []
            Object.keys(headingElementsRef.current).forEach((key) => {
                const headingElement = headingElementsRef.current[key]
                if (headingElement.isIntersecting)
                    visibleHeadings.push(headingElement)
            })
            const getIndexFromId = (id) =>
                headingElements.findIndex((heading) => heading.id === id)

            if (visibleHeadings.length === 1) {
                setActiveId(visibleHeadings[0].target.id)
            } else if (visibleHeadings.length > 1) {
                const sortedVisibleHeadings = visibleHeadings.sort(
                    (a, b) =>
                        getIndexFromId(a.target.id) >
                        getIndexFromId(b.target.id)
                )
                setActiveId(sortedVisibleHeadings[0].target.id)
            }
        }

        const observer = new IntersectionObserver(callback, {
            rootMargin: '-40px 0px -40% 0px',
            threshold: 0.5,
        })

        const headingElements = Array.from(document.querySelectorAll('h2'))

        headingElements.forEach((element) => observer.observe(element))

        return () => observer.disconnect()
    }, [setActiveId])
}

const Headings = ({ headings, activeId }) => {
    const scrollIntoView = (e, id) => {
        e.preventDefault()
        document.getElementById(id).scrollIntoView({ behavior: 'smooth' })
    }
    return (
        <List
            component='nav'
            aria-labelledby='nested-list-subheader'
            sx={{
                width: '100%',
                maxWidth: 360,
            }}
        >
            <ListItemText
                primary='Nội dung bài viết'
                primaryTypographyProps={{
                    fontSize: 15,
                    fontWeight: 'medium',
                    lineHeight: '20px',
                    mb: '15px',
                }}
            />
            {headings.map((heading, index) => (
                <ListItemButton
                    key={heading.id}
                    onClick={(e) => scrollIntoView(e, heading.id)}
                    selected={heading.id === activeId}
                    className={
                        heading.id === activeId ? 'list-item-selected' : ''
                    }
                >
                    <ListItemText primary={`${index + 1}. ${heading.title}`} />
                </ListItemButton>
            ))}
        </List>
    )
}

const TableContents = () => {
    const [activeId, setActiveId] = useState()
    const { nestedHeadings } = useHeadingsData()

    useIntersectionObserver(setActiveId)

    return (
        <nav aria-label='Table of contents'>
            <Headings headings={nestedHeadings} activeId={activeId} />
        </nav>
    )
}

export default TableContents
