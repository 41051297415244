import { Button, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { useLocation } from 'react-router-dom'
import HashLinkCustom from '../../../components/HashLink'
import HtmlParser from 'html-react-parser'
import { useDispatch } from 'react-redux'
import { useState } from 'react'

const BaseCard = ({
    id,
    title,
    des,
    helperText,
    hashLink,
    actions,
    children,
    condition = false,
    state,
}) => {
    const [isClick, setIsClick] = useState(false)
    const dispatch = useDispatch()
    const location = useLocation()
    const { pathname, search } = location
    const handleClick = () => {
        dispatch(actions(state)).then(() => setIsClick(true))
    }
    return (
        <Box
            mt={2}
            px={1}
            display='flex'
            rowGap={1}
            flexDirection='column'
            name='nox'
        >
            <Box>
                <HashLinkCustom
                    id={id}
                    to={`${pathname}${search}#${hashLink}`}
                    title={title}
                    gutterBottom
                    variant='h3'
                    color='primary'
                    component='h2'
                    className='title-divider'
                    sx={{ fontSize: '36px' }}
                />
                <Box mt={2}>
                    <Typography variant='body1' sx={{ fontStyle: 'italic' }}>
                        {HtmlParser(des)}
                    </Typography>
                </Box>
                {condition ? (
                    <Box mt={2}>{children}</Box>
                ) : (
                    <>
                        <Box mt={2}>
                            {children.filter(
                                (child) => child.props.name !== 'luangiai'
                            )}
                        </Box>
                        <Box mt={2}>
                            <Typography variant='p'>
                                Dữ liệu đang được cập nhật...
                            </Typography>
                        </Box>
                    </>
                )}
                {/* {condition && (
                    <Box>
                        <small style={{ marginRight: '8px' }}>
                            {helperText}
                        </small>
                        <Button
                            size='small'
                            color='error'
                            variant='contained'
                            sx={{
                                borderRadius: 0,
                                boxShadow:
                                    '0px 10px 20px 0px rgb(255 84 84 / 24%)',
                            }}
                        >
                            Đăng nhập
                        </Button>
                    </Box>
                )} */}
            </Box>
            {condition && !isClick ? (
                <Box display='flex' className='btn-group' columnGap='5px'>
                    <Button
                        variant='contained'
                        size='medium'
                        onClick={handleClick}
                        sx={{ borderRadius: '0' }}
                    >
                        Đọc thêm
                    </Button>
                </Box>
            ) : (
                <></>
            )}
        </Box>
    )
}

export default BaseCard
