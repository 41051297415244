import {
    convertVnToEN,
    isEnglish,
    isVietnamese,
    isVowelInEN,
    isVowelInVN,
} from './characterTransform'
import { sumAllDigit } from './math'
const arrRegex = [
    /a|j|s/gi, // Nhom 1
    /b|k|t/gi, // Nhom 2
    /c|l|u/gi, // Nhom 3
    /d|m|v/gi, // Nhom 4
    /e|n|w/gi, // Nhom 5
    /f|o|x/gi, // Nhom 6
    /g|p|y/gi, // Nhom 7
    /h|q|z/gi, // Nhom 8
    /i|r/gi, // Nhom 9
]

export default class CalculateFullname {
    constructor(name) {
        this.name = name
        this.arrNumberVowel = []
        this.arrNumberConsonant = []
        this.dataSetFullname = this._cal()
    }

    _cal() {
        const arrStr = []
        const characters = this.name.split('')
        let fnCheckVowel = undefined
        if (isVietnamese(this.name)) {
            fnCheckVowel = isVowelInVN
        } else if (isEnglish(this.name)) {
            fnCheckVowel = isVowelInEN
        }
        if (!fnCheckVowel) {
            throw new Error('Not support this language.')
        }
        characters.forEach((character) => {
            const obj = { character, isUp: false, number: '' }
            if (character !== '') {
                arrRegex.forEach((regex, index) => {
                    const reg = new RegExp(regex)
                    const priCharacter = convertVnToEN(character)
                    // Kiem tra match voi nhom chu cai than so hoc
                    if (reg.test(priCharacter)) {
                        obj.number = index + 1
                        if (fnCheckVowel(character)) {
                            obj.isUp = true
                            this.arrNumberVowel.push(obj.number)
                        } else {
                            obj.isUp = false
                            this.arrNumberConsonant.push(obj.number)
                        }
                        return
                    }
                })
            }
            arrStr.push(obj)
        })
        return arrStr // if length of arrStr is 0 means Fullname is not VN and English
    }

    calfullnameNumber() {
        if (this.arrNumberVowel.length * this.arrNumberVowel.length === 0) {
            this._cal()
        }
        const numberVowel = +this.arrNumberVowel.join('')
        const numberConsonant = +this.arrNumberConsonant.join('')
        let soLinhHon = sumAllDigit(numberVowel) // so Linh Hon = tong so nguyen am
        let soBieuDat = sumAllDigit(numberConsonant) // so Cam Nhan  = tong so phu am
        let _soBieuDat = ''

        while (soLinhHon > 11) {
            soLinhHon = sumAllDigit(soLinhHon)
        }

        if (soBieuDat === 22) {
            soBieuDat = 4
            _soBieuDat = '22/4'
        } else {
            while (soBieuDat > 11) {
                soBieuDat = sumAllDigit(soBieuDat)
            }
            _soBieuDat = soBieuDat
        }

        let soTenRieng = sumAllDigit(soLinhHon + soBieuDat)
        while (soTenRieng > 11) {
            soTenRieng = sumAllDigit(soTenRieng)
        }
        return { soLinhHon, soBieuDat: _soBieuDat, soTenRieng }
    }
}
