import {
    Card,
    CardActionArea,
    CardContent,
    Grid,
    Skeleton,
    Typography,
} from '@mui/material'
import React from 'react'
import HTMLParser from 'html-react-parser'
import { Link } from 'react-router-dom'
import MaterialLink from '@mui/material/Link'
import { PATH_PAGE } from '../../../config/constant'

const FeaturedPost = ({ post }) => {
    const url = post?.slug
        ? `${PATH_PAGE.post.replace(':slug', post.slug)}`
        : '#'
    return (
        <>
            <Grid item xs={12} md={4}>
                <CardActionArea
                    component='a'
                    href={url}
                    sx={{ height: '100%' }}
                >
                    <Card display='flex' className='primary-card'>
                        <CardContent sx={{ flex: 1 }}>
                            <Typography
                                component='h2'
                                variant='h5'
                                gutterBottom
                            >
                                {post?.title ? (
                                    post.title
                                ) : (
                                    <Skeleton
                                        animation='wave'
                                        variant='text'
                                        height={50}
                                        width='40%'
                                    />
                                )}
                            </Typography>
                            {/* <Typography
                                variant='subtitle1'
                                color='text.secondary'
                                gutterBottom
                            >
                                {post?.updatedAt ? (
                                    moment(post.updatedAt).format('DD/MM/YYYY')
                                ) : (
                                    <Skeleton
                                        animation='wave'
                                        variant='text'
                                        height={30}
                                        width='20%'
                                    />
                                )}
                            </Typography> */}
                            <Typography
                                variant='subtitle1'
                                color='textSecondary'
                                paragraph
                                className='limit-text'
                            >
                                {post?.shortDescription
                                    ? HTMLParser(post.shortDescription)
                                    : new Array(2)
                                          .fill(1)
                                          .map(() => (
                                              <Skeleton
                                                  animation='wave'
                                                  variant='text'
                                                  height={30}
                                                  width='100%'
                                              />
                                          ))}
                            </Typography>
                            <Typography>
                                <MaterialLink
                                    component={Link}
                                    sx={{ fontSize: '14px' }}
                                    to={url}
                                >
                                    Tiếp tục đọc
                                </MaterialLink>
                            </Typography>
                        </CardContent>
                        {/* {post?.img ? (
                            <CardMedia
                                component='img'
                                sx={{
                                    width: 100,
                                    display: { xs: 'none', sm: 'block' },
                                }}
                                image={post.img}
                                alt=''
                            />
                        ) : (
                            <Skeleton
                                animation='wave'
                                variant='rectangular'
                                height='100%'
                                width={100}
                            />
                        )} */}
                    </Card>
                </CardActionArea>
            </Grid>
        </>
    )
}

export default FeaturedPost
