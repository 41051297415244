import './style.css'

import { Box, Button, Grid, Modal, Stack } from '@mui/material'
import React, { useState } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import Facebook18px from '../../assets/images/facebook-18px.svg'
import Google18px from '../../assets/images/google-18px.svg'
import SignIn from './components/SignIn'
import SignUp from './components/SignUp'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: {
        lg: '25vw',
        md: '35vw',
        sm: '70vw',
        xs: '90vw',
    },
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 3,
    px: 4,
    pb: 4,
}

const TabModalComponent = ({ open, handleClose }) => {
    const [showValue, setShowValue] = useState(0)

    const closeModal = () => {
        setShowValue(0)
        handleClose()
    }

    const handleGoogleLogin = () => {
        window.location.replace(process.env.REACT_APP_API_URL + '/auth/google')
    }

    const handleFacebookLogin = () => {
        window.location.replace(
            process.env.REACT_APP_API_URL + '/auth/facebook'
        )
    }

    return (
        <Modal
            open={open}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
        >
            <Box sx={style}>
                <Grid container>
                    <Grid item lg={6} md={6} sm={9} xs={10}>
                        <h3 id='parent-modal-title'>
                            {!showValue ? 'Đăng nhập' : 'Đăng ký'}
                        </h3>
                    </Grid>
                    <Grid
                        item
                        lg={6}
                        md={6}
                        sm={3}
                        xs={2}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <CloseIcon
                            sx={{ color: 'white', cursor: 'pointer' }}
                            onClick={() => {
                                closeModal()
                            }}
                        ></CloseIcon>
                    </Grid>
                </Grid>
                <Box sx={{ mt: 1, borderColor: 'divider' }}>
                    {!showValue ? (
                        <SignIn
                            handleChange={setShowValue}
                            onClose={closeModal}
                        />
                    ) : (
                        <SignUp
                            handleChange={setShowValue}
                            onClose={closeModal}
                        />
                    )}
                </Box>
                <Stack
                    direction='row'
                    display='flex'
                    justifyContent='space-around'
                    columnGap='10px'
                >
                    <Button
                        variant='contained'
                        fullWidth
                        onClick={handleGoogleLogin}
                        color='inherit'
                        sx={{ color: 'var(--black-base)' }}
                    >
                        <img
                            alt='google-icon'
                            src={Google18px}
                            style={{ left: '16px', position: 'absolute' }}
                        />
                        Google
                    </Button>
                    <Button
                        variant='contained'
                        fullWidth
                        onClick={handleFacebookLogin}
                        color='inherit'
                        sx={{ color: 'var(--black-base)' }}
                    >
                        <img
                            alt='facebook-icon'
                            src={Facebook18px}
                            style={{ left: '16px', position: 'absolute' }}
                        />
                        Facebook
                    </Button>
                </Stack>
            </Box>
        </Modal>
    )
}

export default TabModalComponent
