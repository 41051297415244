import {
    Button,
    Card,
    CardContent,
    Grid,
    Paper,
    Skeleton,
    Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import LinkIcon from '@mui/icons-material/Link'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import moment from 'moment'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import HTMLParser from 'html-react-parser'
import Zoom from 'react-medium-image-zoom'

const MainFeaturedPost = ({ post }) => {
    const [copied, setCopied] = useState(false)

    const copy = () => {
        const el = document.createElement('input')
        el.value = window.location.href
        document.body.appendChild(el)
        el.select()
        document.execCommand('copy')
        document.body.removeChild(el)
        setCopied(true)
    }

    return (
        <Card sx={{ backgroundColor: 'rgba(0,0,0,60%)' }}>
            <CardContent>
                <Typography
                    gutterBottom
                    variant='h3'
                    component='div'
                    color='inherit'
                    sx={{ fontWeight: 'bold' }}
                >
                    {post.title}
                </Typography>
                <Zoom>
                    <LazyLoadImage
                        src={post.img}
                        alt={`hinh-anh-${post.slug}`}
                        width='100%'
                    />
                </Zoom>
            </CardContent>
        </Card>
        // <Grid container px={2} spacing={4}>
        //     <Grid item xs={12} md={12}>
        //         <div
        //             id='banner'
        //             style={{
        //                 maxHeight: '350px',
        //                 overflow: 'hidden',
        //                 height: '100%',
        //             }}
        //         >
        //             {post ? (
        //                 <Zoom>
        //                     <LazyLoadImage
        //                         src={post.img}
        //                         alt={`hinh-anh-${post.slug}`}
        //                         width='100%'
        //                     />
        //                 </Zoom>
        //             ) : (
        //                 <Skeleton
        //                     animation='wave'
        //                     variant='rectangular'
        //                     width='100%'
        //                     height={350}
        //                 />
        //             )}
        //         </div>
        //     </Grid>
        //     <Grid item xs={12} md={12}>
        //         <Paper
        //             elevation={4}
        //             sx={{
        //                 display: 'flex',
        //                 flexDirection: 'column',
        //                 height: '100%',
        //                 padding: 3,
        //                 justifyContent: 'center',
        //             }}
        //         >
        //             <Typography
        //                 gutterBottom
        //                 variant='h4'
        //                 component='div'
        //                 color='inherit'
        //                 sx={{ fontWeight: 'bold' }}
        //             >
        //                 {post?.title ? (
        //                     post.title
        //                 ) : (
        //                     <Skeleton
        //                         animation='wave'
        //                         variant='text'
        //                         height={50}
        //                         width='40%'
        //                     />
        //                 )}
        //             </Typography>
        //             <Typography component='div'>
        //                 {post?.updatedAt ? (
        //                     <Button
        //                         variant='text'
        //                         size='large'
        //                         startIcon={<AccessTimeIcon />}
        //                     >
        //                         {moment(post.updatedAt).format(
        //                             'HH:mm, DD/MM/YYYY'
        //                         )}
        //                     </Button>
        //                 ) : (
        //                     <Skeleton
        //                         animation='wave'
        //                         variant='text'
        //                         height={30}
        //                         width='50%'
        //                     />
        //                 )}
        //             </Typography>
        //             <Typography paragraph variant='subtitle1' mt={4}>
        //                 {post?.description
        //                     ? HTMLParser(post.description)
        //                     : new Array(3)
        //                           .fill(1)
        //                           .map(() => (
        //                               <Skeleton
        //                                   animation='wave'
        //                                   variant='text'
        //                                   height={30}
        //                                   width='100%'
        //                               />
        //                           ))}
        //             </Typography>
        //             <Box mt={2}>
        //                 <Button
        //                     variant='outlined'
        //                     size='large'
        //                     startIcon={<LinkIcon />}
        //                     onClick={copy}
        //                 >
        //                     {copied ? 'Đã sao chép' : 'Sao chép'}
        //                 </Button>
        //             </Box>
        //         </Paper>
        //     </Grid>
        // </Grid>
    )
}

export default MainFeaturedPost
