import { useState, useLayoutEffect } from 'react'

function useResize() {
    const [size, setSize] = useState([window.innerWidth, window.innerHeight])
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight])
        }
        window.addEventListener('resize', updateSize)
        return () => window.removeEventListener('resize', updateSize)
    })
    return size
}

export default useResize
