//react import
import React from 'react'
import { useState, useEffect } from 'react'
import {  QueryClient, QueryClientProvider,  } from 'react-query';

//components import
import HeaderUserList from './components/HeaderUserList'
import Search from './components/Search'
import UserList from './components/UserList'
import Provider from './store/Provider'

import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
const Manager = () => {
    let navigate = useNavigate()


    let data = useSelector((state) => state.auth)
    useEffect(() => {
        if (!data) {
            navigate('/')
        }
    }, [])
    const [loggedIn, setLoggedIn] = useState(false)
    const [user, setUser] = useState({})
    useEffect(() => {
        setLoggedIn(data.loggedIn)
        setUser(data.user)
    }, [])

    return (
        <Provider>
            <HeaderUserList />
            {/* <Search /> */}
            <UserList />
        </Provider>

    )
}

export default Manager


