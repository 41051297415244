import { PATH_PAGE } from '../../config/constant'
import { lazy } from 'react'

const route = {
    key: 'category',
    path: PATH_PAGE.human_arithmetic_category,
    exact: true,
    component: lazy(() => import('./')),
    otherRoutes: [],
}

export default route
