import {useEffect} from 'react'
import './style.css'

export default function GoogleAds({slot}){
  useEffect(()=>{
    const pushAd = () => {
      try{
        const adsbygoogle = window.adsbygoogle
        console.log({adsbygoogle})
        adsbygoogle.push({})
      }catch(e){
        console.error(e)
      }
    }

    let interval = setInterval(()=>{
      if(window.adsbygoogle){
        pushAd()
        clearInterval(interval)
      }
    },300)
    return ()=>{
      clearInterval(interval)
    }
  },[])

  return (
      <ins className='adsbygoogle'
        style={{display:"block"}}
        data-ad-client= 'ca-pub-6569805311944030'
        data-ad-slot={slot}
        data-ad-format="auto"
        data-full-width-responsive="true"
        >
      </ins>
  )
}