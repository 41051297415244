import './style.css'

import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Grid,
    Stack,
    Typography,
} from '@mui/material'
import { Link, useLocation, useSearchParams } from 'react-router-dom'
import { MainAdsContainer, MainContainer } from '../../components/UI'
import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import BackgroundCould from '../../components/BackgroundCould'
import { Error404 } from '../../components/Error'
import LoadingButton from '@mui/lab/LoadingButton'
import MuiLink from '@mui/material/Link'
import { PATH_PAGE } from '../../config/constant'
import alertActions from '../../redux/actions/alert.action'
import { authSelector } from '../../redux/selector/auth.selector'
import { formatterMoney } from '../../helpers/money'
import modalActions from '../../redux/actions/modal.action'
import paymentService from '../../services/payment.service'
import shoppingService from '../../services/shopping.service'
import { useEffect } from 'react'

const alertErrorPayAgain = (message) => (
    <Alert severity='error'>
        {message}
        &nbsp;
        <Typography display='inline' color=''>
            <MuiLink to={PATH_PAGE.shopping} component={Link}>
                Mua lại
            </MuiLink>
        </Typography>
    </Alert>
)
const alertSuccess = (message) => <Alert severity='success'>{message}</Alert>
const alertError = (message) => <Alert severity='error'>{message}</Alert>

const Error404Order = ({ orderId }) => (
    <MainContainer>
        <Error404
            to={PATH_PAGE.shopping}
            titleText='Không tìm thấy đơn hàng'
            desText={
                <Typography
                    type='secondary'
                    paragraph
                    variant='p'
                    component='div'
                >
                    Rất tiếc, chúng tôi không tìm thấy đơn hàng{' '}
                    <strong>{orderId}</strong>
                </Typography>
            }
            btnText='Tới trang mua sắm'
        />
    </MainContainer>
)

const Payment = () => {
    const { loggedIn } = useSelector(authSelector)
    const dispatch = useDispatch()
    const [searchParams] = useSearchParams()
    const orderId = searchParams.get('orderId')

    const [order, setOrder] = useState(null)
    const [wrongOrder, setWrongOrder] = useState(false)
    const [trigger, setTrigger] = useState(false)

    const [disableBtnMomo, setDisableBtnMomo] = useState(false)
    const [loadingBtnMomo, setLoadingBtnMomo] = useState(false)
    const [textBtnMomo, setTextBtnMomo] = useState('MoMo')

    const [alert, setAlert] = useState(null)

    const requestPaymentMomo = useCallback(async () => {
        try {
            setLoadingBtnMomo(true)
            const data = await paymentService.payMomo(
                orderId,
                window.location.origin + window.location.pathname
            )
            const payUrl = data.payUrl
            if (payUrl) {
                setTextBtnMomo('Chờ thanh toán')
                setDisableBtnMomo(true)
                window.location.replace(payUrl)
            } else {
                dispatch(
                    alertActions.error(
                        'Đã xảy ra lỗi ngoài ý muốn. Vui lòng thử lại sau.'
                    )
                )
            }
        } catch (error) {
            dispatch(alertActions.error(error.errorMessage))
        } finally {
            setLoadingBtnMomo(false)
        }
    }, [dispatch, orderId])

    useEffect(() => {
        document.title = 'Thanh toán' + (order ? ' | ' + order.orderInfo : '')
    }, [order])

    useEffect(() => {
        const requestFindOrder = async () => {
            try {
                const order = await shoppingService.findOrderPaying(orderId)
                setOrder(order)
            } catch (error) {
                if (error.httpStatusCode === 404) {
                    setWrongOrder(true)
                    return
                }
                dispatch(alertActions.error(error.errorMessage))
            }
        }
        requestFindOrder()
    }, [orderId, dispatch])

    useEffect(() => {
        let price = searchParams.get('price')
        let statusCode = searchParams.get('statusCode')
        let signature1st = searchParams.get('signature1st')
        let signature2nd = searchParams.get('signature2nd')
        let message =
            searchParams.get('message') ||
            'Đã xảy ra lỗi trong quá trình thanh toán'
        let timeResponse = searchParams.get('timeResponse')
        let typePaid = searchParams.get('typePaid')
        let saleId = searchParams.get('saleId')

        if (statusCode && timeResponse && saleId && price) {
            // if statusCode is not equal 1000 means something was wrong from URL or from PaymentService
            // Display error or success alert and not request to server
            if (statusCode !== '1000') {
                // If statusCode is 999 means this Order was solved SUCCESS then display Success alert
                if (statusCode === '999') {
                    setAlert(alertSuccess(message))
                } else {
                    setAlert(alertErrorPayAgain(message))
                }
                return
            }

            // action from Momo
            if (statusCode === '1000' && signature1st && signature2nd) {
                const requestOrderSolving = async (
                    price,
                    statusCode,
                    signature1st,
                    signature2nd,
                    timeResponse,
                    typePaid,
                    saleId
                ) => {
                    try {
                        setLoadingBtnMomo(true)
                        setTextBtnMomo('Đang xác nhận')
                        const { message } =
                            await paymentService.solvingOrderMomo({
                                price: Number(price),
                                statusCode: Number(statusCode),
                                signature1st,
                                signature2nd,
                                timeResponse,
                                typePaid,
                                saleId,
                                orderId,
                            })
                        setAlert(<Alert severity='success'>{message}</Alert>)
                    } catch (error) {
                        if (error.httpStatusCode === 400) {
                            setAlert(alertSuccess(error.errorMessage))
                        } else {
                            setAlert(alertError(error.errorMessage))
                        }
                    } finally {
                        setTextBtnMomo('MoMo')
                        setLoadingBtnMomo(false)
                    }
                }

                requestOrderSolving(
                    price,
                    statusCode,
                    signature1st,
                    signature2nd,
                    timeResponse,
                    typePaid,
                    saleId
                )
            }
        }
    }, [orderId, searchParams])

    useEffect(() => {
        if (loggedIn && trigger) {
            requestPaymentMomo()
        }
    }, [loggedIn, trigger, requestPaymentMomo])

    const onClickPaymentByMomo = async (e) => {
        e.preventDefault()
        if (!loggedIn) {
            dispatch(modalActions.openTabModal())
            setTrigger(true)
        } else {
            requestPaymentMomo()
        }
    }
    if (wrongOrder) {
        return <Error404Order orderId={orderId} />
    }
    return (
        <BackgroundCould>
            <MainAdsContainer>
                {order ? (
                    <Box
                        sx={{ width: '100%', bgColor: 'background.paper' }}
                        className='package-info'
                    >
                        <Grid container>
                            <Grid
                                item
                                className='package-info__left'
                                alignItems='center'
                            ></Grid>
                            <Grid
                                item
                                className='package-info__right'
                                width='100%'
                            >
                                <Typography>
                                    Thanh toán đơn hàng{' '}
                                    <Typography
                                        fontWeight='bold'
                                        color='primary'
                                        variant='h5'
                                        display='inline'
                                        component='div'
                                    >
                                        {order.orderId}
                                    </Typography>
                                </Typography>
                                <Typography
                                    mt={2}
                                    color='inherit'
                                    variant='h5'
                                    component='div'
                                >
                                    Tổng tiền:{' '}
                                    {formatterMoney.format(order.totalPrice)}
                                </Typography>
                                <Typography mt={2} paragraph>
                                    Nội dung: {order.orderInfo}
                                </Typography>
                                <Stack>{alert && alert}</Stack>
                                {!order.isPaid && (
                                    <Stack direction='row' spacing={2} mt={3}>
                                        <LoadingButton
                                            variant='contained'
                                            onClick={onClickPaymentByMomo}
                                            disabled={!!disableBtnMomo}
                                            loading={loadingBtnMomo}
                                            size='small'
                                            loadingIndicator='Loading…'
                                            sx={{
                                                color: '#fff',
                                                backgroundColor: '#D82D8B',
                                                '&:hover': {
                                                    backgroundColor: '#F759AB',
                                                },
                                            }}
                                        >
                                            {textBtnMomo}
                                        </LoadingButton>

                                        {/* ZALO */}
                                        <Button
                                            variant='contained'
                                            disabled
                                            size='small'
                                            sx={{
                                                color: '#fff',
                                                backgroundColor: '#0068ff',
                                                '&:hover': {
                                                    backgroundColor: '#0068ff',
                                                },
                                            }}
                                        >
                                            ZaloPay
                                        </Button>
                                    </Stack>
                                )}
                            </Grid>
                        </Grid>
                    </Box>
                ) : (
                    <CircularProgress />
                )}
            </MainAdsContainer>
        </BackgroundCould>
    )
    // if (!order) {
    //     return <MainAdsContainer>Đang tải...</MainAdsContainer>
    // }
    // return (
    //     <div className='bg-cloud'>
    //         <MainAdsContainer>
    //             <div>
    //                 {order.packages.map((_packge) => (
    //                     <>
    //                         <Box className='package-card'>
    //                             <Grid container spacing={8}>
    //                                 <Grid
    //                                     item
    //                                     className='package-card__left'
    //                                     xs={12}
    //                                     sm={12}
    //                                     md={4}
    //                                 >
    //                                     <div className='package-card__left__image'>
    //                                         <LazyLoadImage
    //                                             alt='package-preview'
    //                                             loading='lazy'
    //                                             decoding='async'
    //                                             src='https://nhanso.akb.vn/public/images/so-chu-dao.png'
    //                                         />
    //                                     </div>
    //                                     <div classname='package-card__left__shares'></div>
    //                                 </Grid>
    //                                 <Grid
    //                                     item
    //                                     classname='package-card__right'
    //                                     xs={12}
    //                                     sm={12}
    //                                     md={8}
    //                                 >
    //                                     <div className='package-card__right__content'>
    //                                         <Typography
    //                                             component='div'
    //                                             variant='h4'
    //                                             mb='1.2em'
    //                                             color='primary'
    //                                         >
    //                                             {order?.orderInfo}
    //                                         </Typography>
    //                                         <div>
    //                                             <Typography
    //                                                 component='h4'
    //                                                 variant='h4'
    //                                                 gutterBottom
    //                                             >
    //                                                 {formatterMoney.format(
    //                                                     _packge?.totalPrice || 0
    //                                                 )}
    //                                             </Typography>
    //                                             <Typography
    //                                                 paragraph
    //                                                 color='text.secondary'
    //                                                 component='p'
    //                                             >
    //                                                 Gain insight into your
    //                                                 child’s personality and
    //                                                 support their development!
    //                                                 This report was created for
    //                                                 parents who want to better
    //                                                 understand their child’s
    //                                                 innate traits, starting from
    //                                                 day one. Support your
    //                                                 child’s growth process and
    //                                                 help them find their unique
    //                                                 individuality by knowing
    //                                                 their talents and the
    //                                                 obstacles they may
    //                                                 encounter.
    //                                             </Typography>
    //                                             <Typography
    //                                                 paragraph
    //                                                 color='text.secondary'
    //                                                 component='p'
    //                                             >
    //                                                 Gain insight into your
    //                                                 child’s personality and
    //                                                 support their development!
    //                                                 This report was created for
    //                                                 parents who want to better
    //                                                 understand their child’s
    //                                                 innate traits, starting from
    //                                                 day one.
    //                                             </Typography>
    //                                         </div>
    //                                     </div>
    //                                     <div className='package-card__right__attr'>
    //                                         <Typography></Typography>
    //                                     </div>
    //                                     <div className='package-card__right__payment'></div>
    //                                 </Grid>
    //                             </Grid>
    //                         </Box>
    //                     </>
    //                 ))}
    //             </div>
    //         </MainAdsContainer>
    //     </div>
    // )
}
export default Payment
