import { facebookSignIn, signIn, signOut, signUp } from '../api/auth.api'

import Cookies from 'js-cookie'
import storageConstant from '../config/constant/storage.constant'
import tokenService from './token.service'

const authService = {
    handleSignIn: async (username, password) => {
        username = username.trim()
        password = password.trim()
        return signIn(username, password).then(({ data }) => {
            const { user, tokens } = data
            localStorage.setItem(storageConstant.USER, JSON.stringify(user))
            // tokenService.setAccessToken(tokens.accessToken)
            // tokenService.setRefreshToken(tokens.refreshToken)
            return user
        })
    },
    handleSignUp: async (username, password) => {
        username = username.trim()
        password = password.trim()
        return signUp(username, password)
    },

    handleSignOut: async () => {
        return signOut()
            .then(() => {
                localStorage.removeItem(storageConstant.USER)
                // tokenService.clearToken()
            })
            .catch(() => {
                localStorage.clear()
                // tokenService.clearToken()
            })
    },

    handleFacebookSignIn: async () => {
        return facebookSignIn()
    },
}
export default authService
