import { PATH_PAGE } from '../../config/constant'
import { lazy } from 'react'

const route = {
    key: 'calendar',
    path: PATH_PAGE.calendar,
    exact: true,
    component: lazy(() => import('./')),
    otherRoutes: [],
}

export default route
