import { Button, Grid, Paper, Stack, Typography } from '@mui/material'
import React from 'react'
import MaterialLink from '@mui/material/Link'
import { Link } from 'react-router-dom'

const Sidebar = ({ archives, description, social, title }) => {
    return (
        <Grid item xs={12} md={3}>
            <Paper elevation={0} sx={{ p: 2, bgcolor: 'grey.200' }}>
                <Typography variant='h6' gutterBottom>
                    {title}
                </Typography>
                <Typography>{description}</Typography>
            </Paper>
            <Typography variant='h6' gutterBottom sx={{ mt: 3 }}>
                Archives
            </Typography>
            {archives.map((archive) => (
                <MaterialLink
                    component={Link}
                    display='block'
                    variant='body1'
                    to='/'
                    key={archive.title}
                    sx={{
                        mb: 0.5,
                    }}
                >
                    {archive.title}
                </MaterialLink>
            ))}
            <Typography variant='h6' gutterBottom sx={{ mt: 3 }}>
                Social
            </Typography>
            {social.map((network) => (
                <Button
                    component={Link}
                    to='/'
                    variant='body1'
                    key={network.name}
                    sx={{ mb: 0.5, display: 'block' }}
                >
                    <Stack direction='row' spacing={1} alignItems='center'>
                        <network.icon />
                        <span>{network.name}</span>
                    </Stack>
                </Button>
            ))}
        </Grid>
    )
}

export default Sidebar
