import './style.css'

import {
    Alert,
    Box,
    Fab,
    Grid,
    Paper,
    Zoom,
    useScrollTrigger,
} from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'

import Action from '../Action'
import BieuDoNgaySinh from '../BieuDoNgaySinh'
import BieuDoTenRieng from '../BieuDoTenRieng'
import BieuDoTongHop from '../BieuDoTongHop'
import ChuKyVanNam from '../ChuKyVanNam'
import DinhCaoDoiNguoi from '../DinhCaoDoiNguoi'
import ListIcon from '@mui/icons-material/List'
import PdfPreview from '../PdfPreview'
import SoChuDao from '../SoChuDao'
import SucManhTenRieng from '../SucManhTenRieng'
import TableContents from '../TableContents'
import useResize from '../../../../hooks/useResize'

const Content = () => {
    const [clickContent, setClickContent] = useState(false)
    const [showToC, setShowToC] = useState(false)
    const tocRef = useRef(null)
    const btnRef = useRef(null)
    const trigger = useScrollTrigger()
    const [width] = useResize()
    useEffect(() => {
        function clickOutSideEvent(e) {
            if (
                tocRef.current &&
                !tocRef.current.contains(e.target) &&
                !btnRef.current.contains(e.target)
            ) {
                setShowToC(false)
            }
        }
        document.addEventListener('mousedown', clickOutSideEvent)
        return () =>
            document.removeEventListener('mousedown', clickOutSideEvent)
    }, [tocRef])

    const copy = () => {
        const el = document.createElement('input')
        el.value = window.location.href
        document.body.appendChild(el)
        el.select()
        document.execCommand('copy')
        document.body.removeChild(el)
        setClickContent('Sao chép đường dẫn thành công')
    }

    return (
        <>
            <div
                className='screen-default'
                elevation={0}
                style={{
                    backgroundColor: 'transparent',
                    backgroundImage: 'none',
                }}
            >
                {!!clickContent && (
                    <Alert
                        severity='success'
                        color='success'
                        onClose={() => setClickContent(false)}
                    >
                        {clickContent}
                    </Alert>
                )}
                <Grid container spacing={1} pt={3}>
                    <Grid
                        item
                        lg={1}
                        md={1}
                        sm={0}
                        xs={0}
                        className='post-actions hidden-md-down'
                        display='flex'
                        alignItems='center'
                        flexDirection='column'
                    >
                        <Action copy={() => copy()} />
                    </Grid>
                    <Grid
                        items
                        className='post-content'
                        lg={8}
                        md={8}
                        sm={12}
                        xs={12}
                        px={2}
                        sx={{ textAlign: 'justify' }}
                    >
                        {/* <Stack spacing={3}> */}
                        <SoChuDao />
                        <ChuKyVanNam />
                        <BieuDoNgaySinh />
                        <BieuDoTenRieng />
                        <BieuDoTongHop />
                        <SucManhTenRieng />
                        <DinhCaoDoiNguoi />
                        <PdfPreview />
                        {/* </Stack> */}
                    </Grid>
                    <Grid
                        items
                        className='post-table-contents'
                        lg={3}
                        md={3}
                        px={2}
                        sx={{
                            display: { xs: 'none', lg: 'block', md: 'block' },
                        }}
                    >
                        <TableContents />
                    </Grid>
                </Grid>

                <Zoom in={!trigger}>
                    <Box
                        className='post-bottom-bar hidden-lg-up'
                        sx={{
                            position: 'fixed',
                            right: 0,
                            left: 0,
                            zIndex: 20,
                            bottom: 0,
                        }}
                    >
                        <Box
                            display='flex'
                            alignItems='center'
                            flexDirection='row'
                        >
                            <Action copy={() => copy()} />
                        </Box>
                    </Box>
                </Zoom>
            </div>
            <Box
                ref={btnRef}
                onClick={(e) => {
                    e.preventDefault()
                    setShowToC(!showToC)
                }}
                metaData={width}
                role='presentation'
                sx={{
                    position: `fixed`,
                    bottom: `15px`,
                    right: `1rem`,
                    zIndex: 100,
                    ...(width > 899
                        ? { display: 'none' }
                        : { display: 'block' }),
                }}
            >
                <Fab variant='extended' size='small'>
                    <ListIcon sx={{ mr: 1 }} />
                    Mục lục
                </Fab>
            </Box>
            <div
                ref={tocRef}
                style={{
                    border: '1px solid',
                    position: 'fixed',
                    bottom: '4.1rem',
                    width: 'auto',
                    background: '#121212',
                    padding: '5px 10px',
                    right: '5px',
                    zIndex: 99,
                    boxShadow: '0px 0px 10px 2px #90caf9',
                    ...(showToC ? { display: 'block' } : { display: 'none' }),
                }}
            >
                <TableContents />
            </div>
        </>
    )
}

export default Content
