export const transposeMatrix = (a) => {
    const w = a.length || 0
    const h = a[0] instanceof Array ? a[0].length : 0
    if (h === 0 || w === 0) {
        return []
    }
    let i,
        j,
        t = []
    for (i = 0; i < h; i++) {
        t[i] = []
        for (j = 0; j < w; j++) {
            t[i][j] = a[j][i]
        }
    }
    return t
}
