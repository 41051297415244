import React from 'react'
import Container from '@mui/material/Container'
import { Box, Grid, Typography, Button } from '@mui/material'
import { Link } from 'react-router-dom'
import { PATH_PAGE } from '../../../config/constant'
import { useNavigate } from 'react-router-dom'

const item = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    px: 5,
}

const number = {
    fontSize: 24,
    fontFamily: 'default',
    color: 'primary.main',
    fontWeight: 'medium',
    mb: 2,
}

const image = {
    height: 55,
    my: 4,
}

const HowItWork = ({ scrollFormAction }) => {
    const navigate = useNavigate()
    return (
        <section
            id='howitwork'
            className='services-area'
            style={{ marginTop: '3rem' }}
        >
            <Container>
                <Box
                    component='section'
                    sx={{
                        display: 'flex',
                        overflow: 'hidden',
                    }}
                    className='section-title'
                >
                    <Container
                        sx={{
                            mt: 5,
                            mb: 10,
                            position: 'relative',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Typography className='title' sx={{ mb: 4 }}>
                            Đến với chúng tôi bạn có thể
                        </Typography>
                        <div>
                            <Grid container spacing={5}>
                                <Grid item xs={12} md={4}>
                                    <Box sx={item}>
                                        <Box sx={number}>1.</Box>
                                        {/* <Box
                                            component='img'
                                            src='/static/themes/onepirate/productHowItWorks1.svg'
                                            alt='suitcase'
                                            sx={image}
                                        /> */}
                                        <Typography variant='h6' align='center'>
                                            Đọc và nghiên cứu lý thuyết thần số
                                            học, hiểu rõ hơn về nguồn gốc, cơ sở
                                            khoa học, phương pháp tính toán của
                                            thần số học
                                        </Typography>
                                        <Button
                                            color='primary'
                                            size='large'
                                            variant='contained'
                                            sx={{ mt: 8 }}
                                            onClick={() =>
                                                navigate(
                                                    PATH_PAGE.human_arithmetic_theory
                                                )
                                            }
                                        >
                                            Đọc Thêm
                                        </Button>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Box sx={item}>
                                        <Box sx={number}>2.</Box>
                                        {/* <Box
                                            component='img'
                                            src='/static/themes/onepirate/productHowItWorks2.svg'
                                            alt='graph'
                                            sx={image}
                                        /> */}
                                        <Typography variant='h6' align='center'>
                                            Sử dụng công cụ tra cứu miễn phí,
                                            chính xác để tìm thấy các chỉ số,
                                            biểu đồ bí mật, trải nghiệm khám phá
                                            của riêng bản thân bạn
                                        </Typography>
                                        <Button
                                            color='primary'
                                            size='large'
                                            variant='contained'
                                            sx={{ mt: 8 }}
                                            onClick={scrollFormAction}
                                        >
                                            Tra Cứu Ngay
                                        </Button>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Box sx={item}>
                                        <Box sx={number}>3.</Box>
                                        {/* <Box
                                            component='img'
                                            src='/static/themes/onepirate/productHowItWorks3.svg'
                                            alt='clock'
                                            sx={image}
                                        /> */}
                                        <Typography variant='h6' align='center'>
                                            Quản lý thông tin lịch sử tra cứu,
                                            xuất bản tài liệu thần số của chính
                                            bản thân bạn và người thân dễ dàng
                                            với tài khoản đăng nhập
                                        </Typography>
                                        <Button
                                            color='primary'
                                            size='large'
                                            variant='contained'
                                            sx={{ mt: 8 }}
                                            onClick={scrollFormAction}
                                            disabled
                                        >
                                            Đăng nhập
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </div>
                    </Container>
                </Box>
            </Container>
        </section>
    )
}

export default HowItWork
