import React from 'react'
import { Alert, AlertTitle, Snackbar } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import alertActions from '../../redux/actions/alert.action'

const AlertCustom = () => {
    const dispatch = useDispatch()
    const alert = useSelector((state) => state.alert)

    const closeModal = () => {
        dispatch(alertActions.clear())
    }

    return (
        Object.keys(alert).length !== 0 && (
            <Snackbar
                open={true}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                autoHideDuration={6000}
                onClose={closeModal}
            >
                <Alert
                    onClose={closeModal}
                    severity={alert.severity}
                    sx={{ width: '100%' }}
                >
                    <AlertTitle>{alert.title}</AlertTitle>
                    {alert.message}
                </Alert>
            </Snackbar>
        )
    )
}

export default AlertCustom
