import './style.css'

import { Box, Button, Card, CardContent, Typography } from '@mui/material'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'

import AppContext from '../../context/AppContext'
import BackgroundCould from '../../components/BackgroundCould'
import Content from './components/Content'
import DatasetDescription from '../../config/data/maintNumber'
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown'
import { MainContainer } from '../../components/UI'
import Malada from '../../assets/images/nhan-so-hoc-hoa-van.svg'
import { PATH_PAGE } from '../../config/constant'
import VideoGalaxy from '../../assets/mp3/nhan-so-hoc-video-tra-cuu.mp4'
import luangiaiActions from '../../redux/actions/luangiai.action'
import moment from 'moment'

const ResultHumanArithmetic = () => {
    const dispatch = useDispatch()
    const [searchParams] = useSearchParams()
    const { num_sochudao, query: queryInfo } = useSelector((s) => s.luangiai)
    const { footer } = useContext(AppContext)
    const [, setShowFooter] = footer

    const [isHiddenVideo, setHiddenVideo] = useState(true)
    const [isDeleteVideo, setIsDeleteVideo] = useState(true)

    const videoRef = useRef()
    const categoryRef = useRef()
    const navigate = useNavigate()
    const [dataDescription, setDataDescription] = useState()

    useEffect(() => {
        setShowFooter(false)
        const keyInLocalStorage = Object.keys(localStorage).filter(
            (k) => k !== 'user'
        )
        let i = keyInLocalStorage.length

        // just store limit 5 records
        while (i > 5) {
            localStorage.removeItem(keyInLocalStorage[i])
            i--
        }
    }, [setShowFooter])

    useEffect(() => {
        const code = searchParams.get('code')
        if (!code) {
            return navigate(PATH_PAGE.human_arithmetic)
        }
        ;(function () {
            if (!JSON.parse(localStorage.getItem(window.location.href))) {
                setHiddenVideo(false)
                setIsDeleteVideo(false)
            } else {
                setShowFooter(true)
            }
        })()
        dispatch(luangiaiActions.tongquat(code)).catch(({ response }) =>
            navigate(PATH_PAGE.human_arithmetic)
        )
    }, [searchParams, navigate, dispatch, setShowFooter])

    useEffect(() => {
        setDataDescription(DatasetDescription[num_sochudao] ?? '')
    }, [num_sochudao])

    const setPlayBack = () => (videoRef.current.playbackRate = 1.25) //speed video 1.25x

    const hiddenVideo = () => {
        setHiddenVideo(true)
        setShowFooter(true)
        setTimeout(() => {
            setIsDeleteVideo(true)
        }, 2000) // remove video out of DOM afters
    }

    const scrollToCategory = () => {
        categoryRef.current.scrollIntoView({ behavior: 'smooth' })
    }

    return (
        <>
            {!isDeleteVideo && (
                <video
                    playsInline
                    id='video-galaxy'
                    className={isHiddenVideo ? 'hidden-video' : 'visible-video'}
                    autoPlay
                    muted
                    ref={videoRef}
                    onCanPlay={() => setPlayBack()}
                    onEnded={() => hiddenVideo()}
                    data-reactid='.0.1.0.0'
                >
                    <source src={VideoGalaxy} type='video/mp4' />
                </video>
            )}
            <MainContainer>
                <Box
                    id='basic-des'
                    className={
                        isHiddenVideo ? 'visible-content' : 'hidden-content'
                    }
                >
                    <Box textAlign='center'>
                        <Typography
                            className='text-shadow-highlight'
                            variant='h3'
                            sx={{ textTransform: 'uppercase' }}
                        >
                            {queryInfo?.fullname}
                        </Typography>
                        <Typography
                            className='text-shadow-highlight'
                            variant='h5'
                            sx={{ mt: 2 }}
                        >
                            {/* {moment(queryInfo?.dob.replace(/-/g, '/')).format(
                                'DD/MM/YYYY'
                            )} */}
                            {moment(queryInfo?.dob).format('DD/MM/YYYY')}
                        </Typography>
                        <Typography variant='h5' sx={{ mt: 2 }}>
                            Số chủ đạo
                        </Typography>
                    </Box>

                    <Box mt={5} textAlign='center' position='relative'>
                        <img id='loading' src={Malada} alt='malada-loading' />
                        <Typography
                            id='puse-number'
                            variant='h1'
                            className='text-shadow-highlight2'
                        >
                            {num_sochudao}
                        </Typography>
                    </Box>

                    {/* Ly giai so chu dao */}
                    <Box sx={{ marginTop: '10vh' }}>
                        <Card
                            variant='outlined'
                            sx={{ backgroundColor: 'rgb(0,0,0,50%)' }}
                        >
                            <CardContent>
                                <Typography
                                    paragraph
                                    variant='body1'
                                    component='div'
                                >
                                    {dataDescription}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Box>

                    {/* Button read more */}
                    <Box m={5} sx={{ textAlign: 'center' }}>
                        <Button size='large' onClick={scrollToCategory}>
                            <KeyboardDoubleArrowDownIcon
                                fontSize='large'
                                className='text-shadow-highlight blink'
                            />
                        </Button>
                    </Box>
                </Box>

                {isHiddenVideo && (
                    <Box
                        id='category'
                        name='collaps'
                        ref={categoryRef}
                        sx={{
                            marginTop: '10vh',
                        }}
                    >
                        <Content />
                    </Box>
                )}
            </MainContainer>
        </>
    )
}

export default ResultHumanArithmetic
