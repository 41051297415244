import './style.css'

import { Divider, Grid, Typography } from '@mui/material'
import { useEffect, useState } from 'react'

import { APP_NAME } from '../../config/key'
import AccountOrder from './components/AccountOrder'
import { MainContainer } from '../../components/UI'
import Profile from './components/Profile'
import ProfileDetails from './components/AccountProfileDetail'
import SearchTable from './components/SearchHistory'
import { authSelector } from '../../redux/selector/auth.selector'
import paymentService from '../../services/payment.service'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import userActions from '../../redux/actions/user.action'

export default function AccountProfile() {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const { user } = useSelector(authSelector)

    const [listOrders, setListOrders] = useState([])

    useEffect(() => {
        document.title = 'Tài khoản của tôi | ' + APP_NAME
    }, [])

    useEffect(() => {
        const getMyProfile = async () => {
            setLoading(true)
            dispatch(userActions.getMyProfile()).finally(() =>
                setLoading(false)
            )
        }
        getMyProfile()
    }, [dispatch])

    useEffect(() => {
        paymentService
            .getMyOrders()
            .then((orders) => {
                setListOrders(orders)
            })
            .catch()
            .finally(() => {})
    }, [])

    if (loading) {
        return <></>
    }

    return (
        //    <>
        //    <Box
        //         component='main'
        //         sx={{
        //             flexGrow: 1,
        //             py: 8,
        //         }}
        //     >
        //         <Container
        //         sx={{
        //             width: {
        //                 lg: '60vw',
        //                 md: '95vw',
        //                 sm: '95vw',
        //                 xs: '95vw',
        //             },
        //         }}
        //         >
        //             <Typography mb={3} align='center' variant='h3'>
        //                 Tài khoản của tôi
        //             </Typography>
        //             <Grid container spacing={3}>
        //                 <Grid item lg={4} md={4} xs={12}>
        //                     <Profile user={user} />
        //                 </Grid>
        //                 <Grid item lg={8} md={8} xs={12}>
        //                     <ProfileDetails user={user} />
        //                 </Grid>
        //             </Grid>
        //             <Typography mt={7} align='center' variant='h3'>
        //                 Lịch sử tra cứu
        //             </Typography>
        //         </Container>
        //     </Box>
        //     <SearchTable />
        //     </>

        <MainContainer>
            <div className='category'>
                <div className='category__item'>
                    <Typography
                        variant='h4'
                        component='h1'
                        color='primary'
                        gutterBottom
                        align='left'
                    >
                        Tài khoản
                    </Typography>
                    <Divider
                        variant='fullWidth'
                        sx={{
                            marginBottom: '1.67rem',
                        }}
                    />
                    <Grid container spacing={3}>
                        <Grid item lg={3} md={4} xs={12}>
                            <Profile user={user} />
                        </Grid>
                        <Grid item lg={9} md={8} xs={12}>
                            <ProfileDetails user={user} />
                        </Grid>
                    </Grid>
                </div>
            </div>
            <div className='category'>
                <div className='category__item'>
                    <Typography
                        variant='h4'
                        component='h1'
                        color='primary'
                        gutterBottom
                        align='left'
                    >
                        Lịch sử tra cứu
                    </Typography>
                    <Divider
                        variant='fullWidth'
                        sx={{
                            marginBottom: '1.67rem',
                        }}
                    />
                    <SearchTable />
                </div>
            </div>
            <div className='category'>
                <div className='category__item'>
                    <Typography
                        variant='h4'
                        component='h1'
                        color='primary'
                        gutterBottom
                        align='left'
                    >
                        Đơn hàng
                    </Typography>
                    <Divider
                        variant='fullWidth'
                        sx={{
                            marginBottom: '1.67rem',
                        }}
                    />
                    <AccountOrder orders={listOrders} />
                </div>
            </div>
        </MainContainer>
    )
}
