import axios from '../axios'

export const paymentMomoAPI = (orderId, query) =>
    axios.post('/payment/momo/pay-' + orderId, null, {
        params: {
            ...query,
        },
    })

export const solvingOrderMomoAPI = (
    orderId,
    price,
    statusCode,
    signature1st,
    signature2nd,
    timeResponse,
    typePaid,
    saleId
) =>
    axios.post('/payment/momo/solving', {
        orderId,
        price,
        statusCode,
        signature1st,
        signature2nd,
        timeResponse,
        typePaid,
        saleId,
    })

export const getMyOrders = () => axios.get('/payment/orders')
