import alertConstants from '../../config/constant/alert.constant'

const initialState = {}

function alertReducer(state = initialState, action) {
    switch (action.type) {
        case alertConstants.ALERT_SUCCESS:
            return {
                severity: 'success',
                message: action.payload,
            }
        case alertConstants.ALERT_ERROR:
            return {
                title: 'Lỗi',
                severity: 'error',
                message: action.payload,
            }
        case alertConstants.ALERT_INFO:
            return {
                severity: 'info',
                message: action.payload,
            }
        case alertConstants.ALERT_WARNING:
            return {
                title: 'Cảnh báo',
                severity: 'warning',
                message: action.payload,
            }
        case alertConstants.ALERT_CLEAR:
            return {}
        default:
            return state
    }
}

export default alertReducer
