import { Box, Button, Grid } from '@mui/material'

import Avatar from '@mui/material/Avatar'
import { MainContainer } from '../UI'
import NhanSoHocBanner from '../../assets/images/nhan-so-app.webp'
import React from 'react'

const Header = ({ scrollFormAction }) => {
    return (
        <header className='header-area'>
            <Box
                id='home'
                className='header-hero bg_cover'
                display='flex'
                alignItems='center'
            >
                <div className='shape shape-1'></div>
                <div className='shape shape-2'></div>
                <div className='shape shape-3'></div>
                <div className='shape shape-4'></div>
                <div className='shape shape-5'></div>
                <div className='shape shape-6'></div>

                <MainContainer>
                    <Grid container alignItems='center' spacing={5}>
                        <Grid item lg={6} md={10}>
                            <div className='header-hero-content'>
                                <h3
                                    className='header-title wow animated fadeInLeftBig'
                                    data-wow-duration='1.3s'
                                    data-wow-delay='0.2s'
                                >
                                    <span>Nhân Số Đại Việt</span> công cụ tra
                                    cứu nhân số học
                                </h3>
                                <p
                                    className='text wow animated fadeInLeftBig'
                                    data-wow-duration='1.3s'
                                    data-wow-delay='0.6s'
                                >
                                    Mục tiêu số 1 của Nhân Số Đại Việt là hỗ trợ
                                    bạn trong hành trình nhận thức sâu sắc về
                                    bản ngã và trở nên tự tin hơn. Bạn sẽ không
                                    chỉ tìm thấy các biểu đồ chỉ số bí mật cho
                                    riêng bạn, mà còn vô số các công cụ mạnh mẽ
                                    khác để phát triển bản thân.
                                </p>
                                <Box display='flex' mt={3}>
                                    <Button
                                        variant='contained'
                                        size='large'
                                        color='primary'
                                        className='main-btn wow animated fadeInLeftBig'
                                        data-wow-duration='1.3s'
                                        data-wow-delay='0.8s'
                                        onClick={scrollFormAction}
                                    >
                                        Tra cứu ngay
                                    </Button>
                                </Box>
                            </div>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6}>
                            {/* <div className='animated fadeInRightBig'>
                            <img aspect-ratio="auto 1/1" width='100%' height='auto' alt='banner' src={NhanSoHocBanner}/>
							</div> */}
                            <div
                                className='animated fadeInRightBig'
                                style={{ textAlign: 'center' }}
                            >
                                <img
                                    aspect-ratio='auto 1/1'
                                    width='100%'
                                    height='auto'
                                    alt='banner'
                                    loading='lazy'
                                    src={NhanSoHocBanner}
                                    style={{
                                        borderRadius: '5%',
                                        width: '400px',
                                    }}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </MainContainer>
            </Box>
        </header>
    )
}

export default Header
