import './style.css'

import React, { useEffect } from 'react'
import { ThemeProvider, Typography } from '@mui/material'

import AKBLogo from '../../assets/images/nhan-so-hoc-logo-cong-ty.png'
import BusinessIcon from '@mui/icons-material/Business'
import CssBaseline from '@mui/material/CssBaseline'
import { DarkTheme } from '../../components/UI/Theme'
import FaxIcon from '@mui/icons-material/Fax'
import MailIcon from '@mui/icons-material/Mail'
import PhoneIcon from '@mui/icons-material/Phone'

export default function App() {
    useEffect(() => {
        document.title = 'Về Chúng Tôi | NSĐV'
        let description = document.querySelector('meta[name="description"]')
        if (description) {
            description.setAttribute(
                'content',
                'Cung cấp giải pháp số toàn diện theo đúng mục đích, quy trình hoạt động của doanh nghiệp, cơ quan tổ chức từ nhỏ tới lớn. info@daviso.vn. 0979.33.88.11. Số 23 ngõ 412 đường Ngọc Thụy, quận Long Biên, Hà Nội'
            )
        }
    }, [])

    return (
        <div className='parent'>
            <div className='text-box'>
                <img src={AKBLogo} alt='logo' />
                <div className='slogan' style={{ textTransform: 'uppercase' }}>
                    <div style={{ display: 'flex' }}>
                        <b style={{ color: 'white' }}>Your</b>&nbsp;
                        <b style={{ color: 'white' }}>Business,</b>&nbsp;
                    </div>
                    <div style={{ display: 'flex' }}>
                        <b style={{ color: 'white' }}>Our</b>&nbsp;
                        <b style={{ color: 'white' }}>Solution</b>
                    </div>
                </div>
                <div className='custom-info'>
                    <ThemeProvider theme={DarkTheme}>
                        <CssBaseline />
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                margin: '10px',
                            }}
                        >
                            <MailIcon fontSize='large' />
                            &nbsp;&nbsp;
                            <Typography
                                sx={{
                                    fontSize: 20,
                                }}
                            >
                                info@daviso.vn
                            </Typography>
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                margin: '10px',
                            }}
                        >
                            <BusinessIcon fontSize='large' />
                            &nbsp;&nbsp;
                            <Typography
                                sx={{
                                    fontSize: 20,
                                }}
                            >
                                Số 23 ngõ 412 đường Ngọc Thụy, quận Long Biên,
                                Hà Nội
                            </Typography>
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                margin: '10px',
                            }}
                        >
                            <PhoneIcon fontSize='large' />
                            &nbsp;&nbsp;
                            <Typography
                                sx={{
                                    fontSize: 20,
                                }}
                            >
                                (+84) 979.33.88.11
                            </Typography>
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                margin: '10px',
                            }}
                        >
                            <FaxIcon fontSize='large' />
                            &nbsp;&nbsp;
                            <Typography
                                sx={{
                                    fontSize: 20,
                                }}
                            >
                                (+84) 979.33.88.11
                            </Typography>
                        </div>
                    </ThemeProvider>
                </div>
            </div>
        </div>
    )
}
