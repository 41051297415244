import {
    Button,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    Skeleton,
    Typography,
} from '@mui/material'

import NoImage from '../../../assets/images/no-image.png'
import { PATH_PAGE } from '../../../config/constant'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const REACT_APP_BK_ENDPOINT = process.env.REACT_APP_BK_ENDPOINT

const Catalogue = ({ isLoading, ...catalogue }) => {
    const navigate = useNavigate()
    const { img, isPost, title, slug, shortDescription } = catalogue
    const url = isPost
        ? `${PATH_PAGE.post.replace(':slug', slug)}`
        : `${PATH_PAGE.human_arithmetic_theory}/${slug}`

    const errorImage = (target) => {
        target.onError = null
        target.src = NoImage
    }

    return (
        <>
            <Card name='card' className='primary-card' variant='outlined'>
                <CardActionArea
                    component='a'
                    onClick={(e) => {
                        e.preventDefault()
                        navigate(url)
                    }}
                >
                    {isLoading ? (
                        <Skeleton
                            animation='wave'
                            variant='rectangular'
                            height={220}
                        />
                    ) : (
                        <CardMedia
                            loading='lazy'
                            component='img'
                            height={220}
                            image={REACT_APP_BK_ENDPOINT + img}
                            onError={({ target }) => errorImage(target)}
                            title={title}
                            alt={title}
                            sx={{
                                border: 'none',
                            }}
                        />
                    )}
                    <CardContent>
                        {isLoading ? (
                            <Skeleton
                                animation='wave'
                                height={30}
                                width='40%'
                            />
                        ) : (
                            <Typography
                                variant='h5'
                                color='primary'
                                gutterBottom
                            >
                                {title}
                            </Typography>
                        )}
                        {isLoading ? (
                            <Skeleton
                                animation='wave'
                                height={10}
                                width='100%'
                            />
                        ) : (
                            <Typography
                                mt={3}
                                variant='body1'
                                color='white'
                                className='limit-text'
                            >
                                {shortDescription}
                            </Typography>
                        )}
                    </CardContent>
                    <CardActions>
                        <Button
                            size='small'
                            mb={2}
                            onClick={() => navigate(url)}
                        >
                            Đọc thêm
                        </Button>
                    </CardActions>
                </CardActionArea>
            </Card>
        </>
    )
}

export default Catalogue
