//react import
import { useState } from 'react'
// import mui components
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Divider,
    Typography,
} from '@mui/material'

//redux import
import authActions from '../../../redux/actions/auth.action'
import userActions from '../../../redux/actions/user.action'
import { useDispatch } from 'react-redux'

import { useNavigate } from 'react-router-dom'

import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import { styled } from '@mui/material/styles'
//import icon
import BuildIcon from '@mui/icons-material/Build'
import LocalPlayIcon from '@mui/icons-material/LocalPlay'

export default function Profile({ user }) {
    const dispatch = useDispatch()
    const [avatar, setAvatar] = useState(user.avatarUrl ? user.avatarUrl : '')
    const [file, setFile] = useState()
    const [change, setChange] = useState(false)
    // change avatar
    const handleFile = (e) => {
        const file = e.target.files[0]
        setFile(file)
        file.preview = URL.createObjectURL(file)
        if (file.preview != user.avatarUrl) {
            setChange(true)
        }
        setAvatar(file.preview)
    }
    // save change
    const handleSave = () => {
        dispatch(userActions.updateProfile({ avatarUrl: file }))
        setChange(false)
    }
    if (!user) {
        return <></>
    }

    return (
        <Card
            sx={{
                background: 'rgba(0,0,0,0.5)',
            }}
        >
            <CardContent>
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Avatar
                        src={avatar}
                        sx={{
                            height: 64,
                            mb: 2,
                            width: 64,
                        }}
                    />
                    <Typography color='textPrimary' gutterBottom variant='h5'>
                        {user.fullname ? user.fullname : user.username}
                    </Typography>
                </Box>
            </CardContent>
            <Divider />
            <CardActions>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: {
                            lg: 'space-evenly',
                            md: 'space-evenly',
                            sm: 'center',
                            xs: 'center',
                        },
                    }}
                >
                    <label htmlFor='contained-button-file'>
                        <Input
                            accept='image/*'
                            id='contained-button-file'
                            multiple
                            type='file'
                            onChange={(e) => {
                                handleFile(e)
                            }}
                        />
                        <Button
                            color='primary'
                            component='span'
                            variant='outlined'
                        >
                            Thay đổi ảnh đại diện
                        </Button>
                    </label>
                    {!change ? (
                        <></>
                    ) : (
                        <Button onClick={handleSave} variant='contained'>
                            Lưu thay đổi
                        </Button>
                    )}
                </Box>
            </CardActions>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: {
                        md: 'center',
                        sm: 'flex-start',
                        xs: 'flex-start',
                    },
                    padding: '1vh 1vw',
                }}
            >
                <ListItem>
                    <ListItemAvatar>
                        <Avatar>
                            <BuildIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary='Trạng Thái '
                        secondary={
                            user.isBlocked ? (
                                <span style={{ color: '#F44336' }}>
                                    Bị khóa
                                </span>
                            ) : user.isVerified ? (
                                <span style={{ color: '#66BB6A' }}>
                                    Đã xác minh
                                </span>
                            ) : (
                                'Chưa xác minh'
                            )
                        }
                    />
                </ListItem>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar>
                            <LocalPlayIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary='Các gói đã đăng ký'
                        secondary={
                            !user.hasPaid ? (
                                <span>Bạn chưa đăng kí gói nào</span>
                            ) : (
                                'Có'
                            )
                        }
                    />
                </ListItem>
            </Box>
        </Card>
    )
}

const Input = styled('input')({
    display: 'none',
})
