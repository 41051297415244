import { Box, Grid, Typography } from '@mui/material'

import BoltOutlinedIcon from '@mui/icons-material/BoltOutlined'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import Container from '@mui/material/Container'
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined'
import React from 'react'
import WifiCallingOutlinedIcon from '@mui/icons-material/WifiCallingOutlined'

const Service = ({ title, icon, des, serviceClass }) => (
    <Box
        mt={4}
        textAlign='center'
        className={`single-services ${serviceClass} wow animated fadeInUpBig`}
        data-wow-duration='1.3s'
        data-wow-delay='0.2s'
        style={{
            borderRadius: '5%',
            color: 'white',
            // backgroundColor: '#676767CC',
        }}
    >
        <Box
            display='flex'
            alignItems='center'
            justifyContent='center'
            className='services-icon '
        >
            {icon}
        </Box>
        <div className='services-content'>
            <h4 className='services-title'>
                <a href='#'>{title}</a>
            </h4>
            <p className='text'>{des}</p>
        </div>
    </Box>
)
const Why = () => {
    return (
        <section
            id='why'
            className='services-area'
            style={{ marginTop: '8rem', marginBottom: '9rem' }}
        >
            <Container>
                <Grid container justifyContent='center'>
                    <Grid item lg={7}>
                        <Box
                            className='section-title'
                            pb={3}
                            textAlign='center'
                        >
                            <Typography className='title'>
                                Bạn nên sử dụng Website của chúng tôi vì
                            </Typography>
                            <p className='text'>
                                DAVISO là công ty tiền thân có nền tảng xây dựng
                                phần mềm lâu đời tại Việt Nam. Chúng tôi hiện
                                đang có các nhân viên, kỹ sư chuyên nghiệp về xử
                                lý hệ thống phần mềm và dữ liệu. Trong đó có dữ
                                liệu về Nhân Số Học.
                            </p>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={5}>
                    <Grid item lg={3} sm={6}>
                        <Service
                            title='Chính xác'
                            icon={
                                <CheckOutlinedIcon
                                    sx={{
                                        fontSize: '46px',
                                        lineHeight: '79px',
                                    }}
                                />
                            }
                            serviceClass='services-color-1'
                            des='Dựa theo tài liệu chuẩn nhất về Thần số học'
                        />
                    </Grid>
                    <Grid item lg={3} sm={6}>
                        <Service
                            title='Thân thiện'
                            icon={
                                <LayersOutlinedIcon
                                    sx={{
                                        fontSize: '46px',
                                        lineHeight: '79px',
                                    }}
                                />
                            }
                            serviceClass='services-color-2'
                            des='Bố cụ rõ ràng, gam màu phù hợp với mọi người'
                        />
                    </Grid>
                    <Grid item lg={3} sm={6}>
                        <Service
                            title='Tốc độ'
                            icon={
                                <BoltOutlinedIcon
                                    sx={{
                                        fontSize: '46px',
                                        lineHeight: '79px',
                                    }}
                                />
                            }
                            serviceClass='services-color-3'
                            des='Hàng triệu phép tính được thực hiện chỉ trong vài giây'
                        />
                    </Grid>
                    <Grid item lg={3} sm={6}>
                        <Service
                            title='Hỗ trợ'
                            icon={
                                <WifiCallingOutlinedIcon
                                    sx={{
                                        fontSize: '46px',
                                        lineHeight: '79px',
                                    }}
                                />
                            }
                            serviceClass='services-color-4'
                            des='Đội ngũ nhân viên luôn hỗ trợ bạn 24/7.'
                        />
                    </Grid>
                </Grid>
            </Container>
        </section>
    )
}

export default Why
