// react import
import { useContext, useEffect, useState } from 'react';


// mui import
import Grid from '@mui/material/Grid';
import { Box, TextField, MenuItem, useStepContext } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment';

//mui icon import
import SearchIcon from '@mui/icons-material/Search';

// Component import
import Context from '../store/Context';

export default function Search() {

  const { search, type } = useContext(Context)
  const [searchText, setSearchText] = search
  console.log(searchText)

  return (
    <Grid container p={5} spacing={2} >
      <Grid item md={4} sm={6} xs={12}>
        <TextField
          label="Tìm kiếm"
           id="outlined-start-adornment"
          sx={{ width: '100%' }}
          InputProps={{
            startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
          }}
        />
      </Grid>
      <Grid md={2} sm={4} xs={12} item>
        <TextField
          sx={{ width: '100%' }}
          label='Tìm kiếm theo'
          select
        >
          <MenuItem value='userName'>Tên đăng nhập</MenuItem>
          <MenuItem value='fullName'>Tên đầy đủ</MenuItem>
        </TextField>
      </Grid>
    </Grid>
  )
}