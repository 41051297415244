import PropTypes from 'prop-types'
import React from 'react'
import Slide from '@mui/material/Slide'
import useScrollTrigger from '@mui/material/useScrollTrigger'

const HideOnScroll = ({ children, window }) => {
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    })
    return (
        <Slide appear={false} direction='down' in={!trigger}>
            {children}
        </Slide>
    )
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
}

export default HideOnScroll
