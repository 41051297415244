import {
    Button,
    CssBaseline,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    ThemeProvider,
    Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { createSearchParams, useNavigate } from 'react-router-dom'

import { Box } from '@mui/system'
import FormValidator from './validator'
import { LightTheme } from '../UI/Theme'
import { PATH_PAGE } from '../../config/constant'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import { authSelector } from '../../redux/selector/auth.selector'
import { getCodeSearchHistory } from '../../api/search_history'
import moment from 'moment'
import { useSelector } from 'react-redux'

const makeNArray = (n) => Array.from({ length: n }, (_, i) => i + 1)
const makeNYearArrayFromNow = () => {
    const currentYear = new Date().getFullYear()
    const lastestYear = 1900
    const n = currentYear - lastestYear + 1
    return Array.from({ length: n }, (_, i) => currentYear - i)
}
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

const FormTraCuu = ({ theme = LightTheme }) => {
    const [isValid, setValidForm] = useState(false)
    const { user } = useSelector(authSelector)
    const navigate = useNavigate()
    const [errors, setErrors] = useState({})

    const [stateForm, setForm] = useState({
        fullname: '',
        shortname: '',
        gender: 1,
        date: '',
        month: '',
        year: '',
    })

    useEffect(() => {
        if (user && Object.keys(user).length !== 0) {
            const dob = user.birth ? moment(user.birth) : null
            setForm({
                fullname: user.fullname,
                shortname: '',
                gender: 1,
                date: dob?.date || '',
                month: dob?.month || '',
                year: dob?.year || '',
            })
        }
    }, [user])

    const handleInput = (e) => {
        setForm({ ...stateForm, [e.target.name]: e.target.value })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setErrors(FormValidator.validate(stateForm))
        setValidForm(FormValidator.isValid)
    }
    if (isValid) {
        stateForm[
            'dob'
        ] = `${stateForm.year}-${stateForm.month}-${stateForm.date}`
        delete stateForm.year
        delete stateForm.month
        delete stateForm.date
        getCodeSearchHistory(stateForm).then(({ data }) => {
            if (data) {
                return navigate(
                    PATH_PAGE.human_arithmetic_result +
                        '?' +
                        createSearchParams({ code: data })
                )
            }
        })
    }
    return (
        <>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Box
                    component='form'
                    method='post'
                    onSubmit={handleSubmit}
                    sx={{
                        mt: 4,
                        flexWrap: 'wrap',
                        display: 'flex',
                        '& .form-group': {
                            marginBottom: 3,
                            width: '100%',
                        },
                    }}
                >
                    <FormControl className='form-group'>
                        <TextField
                            required
                            error={!!errors.fullname}
                            helperText={errors?.fullname}
                            value={stateForm.fullname}
                            onInput={handleInput}
                            name='fullname'
                            id='input-fullname'
                            InputLabelProps={{
                                shrink: true,
                            }}
                            type='text'
                            label='Họ và tên đầy đủ'
                            placeholder='VD: Nguyễn Văn A'
                            fullWidth
                        />
                    </FormControl>
                    <FormControl className='form-group'>
                        <TextField
                            error={!!errors.shortname}
                            helperText={errors?.shortname}
                            value={stateForm.shortname}
                            onInput={handleInput}
                            name='shortname'
                            id='input-shortname'
                            InputLabelProps={{
                                shrink: true,
                            }}
                            type='text'
                            label='Tên thường gọi'
                            placeholder='VD: Văn A'
                            fullWidth
                        />
                    </FormControl>
                    {/* <FormControl
                        className='form-group'
                        style={{ marginLeft: '2px' }}
                    >
                        <RadioGroup
                            row
                            aria-labelledby='gender'
                            name='gender'
                            onChange={handleInput}
                            helperText={errors?.gender}
                            defaultValue={stateForm.gender}
                        >
                            <FormControlLabel
                                value={1}
                                control={<Radio />}
                                label='Nam'
                            />
                            <FormControlLabel
                                value={2}
                                control={<Radio />}
                                label='Nữ'
                            />
                            <FormControlLabel
                                value={3}
                                control={<Radio />}
                                label='Khác'
                            />
                        </RadioGroup>
                    </FormControl> */}
                    <Grid
                        container
                        spacing={3}
                        className='form-group'
                        style={{
                            width: '120%',
                        }}
                    >
                        <Grid item md={4} sm={4} lg={4} xs={12}>
                            <FormControl error={!!errors.date} fullWidth>
                                <InputLabel htmlFor='input-date'>
                                    Ngày sinh
                                </InputLabel>
                                <Select
                                    required
                                    id='input-date'
                                    name='date'
                                    labelId='lb-input-date'
                                    error={!!errors.date}
                                    onChange={handleInput}
                                    input={<OutlinedInput label='Ngày sinh' />}
                                    value={stateForm.date}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem value=''>
                                        <em>Ngày</em>
                                    </MenuItem>
                                    {makeNArray(31).map((date) => (
                                        <MenuItem value={date}>{date}</MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>{errors?.date}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item md={4} sm={4} lg={4} xs={12}>
                            <FormControl error={!!errors.month} fullWidth>
                                <InputLabel id='lb-input-month'>
                                    Tháng sinh
                                </InputLabel>
                                <Select
                                    required
                                    id='input-month'
                                    labelId='lb-input-month'
                                    name='month'
                                    error={!!errors.month}
                                    onChange={handleInput}
                                    input={<OutlinedInput label='Tháng sinh' />}
                                    value={stateForm.month}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem value=''>
                                        <em>Tháng</em>
                                    </MenuItem>
                                    {makeNArray(12).map((month) => (
                                        <MenuItem value={month}>
                                            {month}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>{errors?.month}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item md={4} sm={4} lg={4} xs={12}>
                            <FormControl error={!!errors.year} fullWidth>
                                <InputLabel id='lb-input-year'>
                                    Năm sinh
                                </InputLabel>
                                <Select
                                    required
                                    id='input-year'
                                    labelId='lb-input-year'
                                    error={!!errors.year}
                                    name='year'
                                    onChange={handleInput}
                                    label='Năm sinh'
                                    input={<OutlinedInput label='Năm sinh' />}
                                    value={stateForm.year}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem value=''>
                                        <em>Năm</em>
                                    </MenuItem>
                                    {makeNYearArrayFromNow().map((year) => (
                                        <MenuItem value={year}>{year}</MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>{errors?.year}</FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <div className='form-group'>
                        <Typography
                            sx={{ pl: 1 }}
                            variant='caption'
                            color='text.secondary'
                            display='block'
                            gutterBottom
                        >
                            Nhập ngày tháng năm sinh dương lịch
                        </Typography>
                    </div>
                    <Button
                        mt={0}
                        type='submit'
                        variant='contained'
                        startIcon={<SearchOutlinedIcon />}
                        sx={{ mt: 2 }}
                    >
                        Tra cứu
                    </Button>
                </Box>
            </ThemeProvider>
        </>
    )
}

export default FormTraCuu
