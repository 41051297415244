import axios from '../axios'

export const signIn = (username, password) =>
    axios.post('/auth/sign-in', { username, password })

export const signUp = (username, password) =>
    axios.post('/auth/sign-up', { username, password })

export const signOut = () => axios.delete('/auth/sign-out')

export const facebookSignIn = () => {
    window.location.replace('https://localhost:8080/api/v1/auth/facebook')
}
