//react import
import { useEffect, useState } from 'react'

//icons mui import

//redux import
import { useSelector } from 'react-redux'
import authActions from '../../redux/actions/auth.action'
import userActions from '../../redux/actions/user.action'
import { useDispatch } from 'react-redux'

// mui import
import { Box, Container, Grid, Typography } from '@mui/material'

// component import
import Profile from './components/AccountProfile'
import ProfileDetails from './components/AccountProfileDetail'
import SearchTable from './components/SearchHistory'
import { useNavigate } from 'react-router-dom'
import tokenService from '../../services/token.service'
import storageConstant from '../../config/constant/storage.constant'

export default function AccountProfile() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [user, setUser] = useState({})
    const [loading, setLoading] = useState(true)
    let data = useSelector((state) => state.auth)

    const rtoken = tokenService.getRefreshToken()
    useEffect(() => {
        if (data.user && JSON.stringify(data.user) != '{}') {
            setUser(data.user)
            setLoading(false)
        } else {
            alert('Bạn cần đăng nhập để truy cập trang này!')
            navigate('/')
        }
    }, [])
    if (loading) {
        return <></>
    }
    return (
        <>
            <Box
                component='main'
                sx={{
                    flexGrow: 1,
                    py: 8,
                }}
            >
                <Container
                    sx={{
                        width: {
                            lg: '60vw',
                            md: '95vw',
                            sm: '95vw',
                            xs: '95vw',
                        },
                    }}
                >
                    <Typography mb={3} align='center' variant='h3'>
                        Tài khoản của tôi
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid item lg={4} md={4} xs={12}>
                            <Profile user={user} />
                        </Grid>
                        <Grid item lg={8} md={8} xs={12}>
                            <ProfileDetails user={user} />
                        </Grid>
                    </Grid>
                    <Typography mt={7} align='center' variant='h3'>
                        Lịch sử tra cứu
                    </Typography>
                </Container>
            </Box>
            <SearchTable />
        </>
    )
}
