import { PATH_PAGE } from '../../config/constant'
import { lazy } from 'react'

const route = {
    key: 'about',
    path: PATH_PAGE.about,
    exact: true,
    component: lazy(() => import('.')),
    otherRoutes: [],
}

export default route
