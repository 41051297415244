import alertActions from './alert.action'
import tokenService from '../../services/token.service'
import userConstants from '../../config/constant/user.constant'
import userService from '../../services/user.service'

const userActions = {
    getMyProfile: function () {
        const getMyProfileSuccess = (user) => ({
            type: userConstants.GET_USER_PROFILE,
            payload: user,
        })
        return async (dispatch) => {
            return userService
                .handleGetMyProfile()
                .then((user) => {
                    dispatch(getMyProfileSuccess(user))
                })
                .catch(() => {
                    console.log('bug')
                })
        }
    },
    changePassword: function (currentPassword, newPassword, confirmPassword) {
        const changePasswordSuccess = () => ({
            type: userConstants.CHANGE_PASSWORD_SUCCESS,
        })
        return async (dispatch) => {
            return userService
                .handleChangePassword(
                    currentPassword,
                    newPassword,
                    confirmPassword
                )
                .then(() => {
                    console.log('thanh cong')
                    dispatch(changePasswordSuccess())
                    localStorage.clear()
                    tokenService.clearToken()
                    alert('Đổi mật khẩu thành công vui lòng đăng nhập lại!')
                    return Promise.resolve()
                })
                .catch((error) => {
                    console.log('loi')
                    dispatch(alertActions.error(error.errorMessage))
                    return Promise.reject(error)
                })
        }
    },
    updateProfile: function ({
        avatarUrl,
        email,
        fullname,
        phoneCode,
        phoneNumber,
        birth,
    }) {
        const updateProfileSuccess = (user) => ({
            type: userConstants.UPDATE_PROFILE_SUCCESS,
            payload: user,
        })
        return async (dispatch) => {
            return userService
                .handleUpdateProfile({
                    email,
                    avatarUrl,
                    fullname,
                    phoneCode,
                    phoneNumber,
                    birth,
                })
                .then((user) => {
                    dispatch(updateProfileSuccess(user))
                    dispatch(
                        alertActions.success('Cập nhập thông tin thành công!')
                    )
                    return Promise.resolve()
                })
                .catch((error) => {
                    dispatch(alertActions.error(error.errorMessage))
                    return Promise.reject(error)
                })
        }
    },
}
export default userActions
