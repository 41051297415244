import MarkDown from '../../../components/MarkDown'
import React from 'react'
import { Typography } from '@mui/material'

const mdProps = [
    'md_description',
    'md_feature',
    'md_devDirection',
    'md_quote',
    'md_strengths',
    'md_weaknesses',
    'md_overcome',
    'md_job',
    'md_advice',
    'md_summary',
]

const MdAttr = ({ luangiai }) => {
    if (!luangiai || (Array.isArray(luangiai) && luangiai.length === 0)) {
        return null
    }
    return (
        <>
            {luangiai.map((lg, i) => {
                return (
                    <>
                        <div className='markdown-body reading-time'>
                            <Typography
                                variant='h5'
                                component='h3'
                                className='effect-title'
                            >
                                Bạn có {lg.vnName}
                            </Typography>
                        </div>
                        {mdProps.map((f, i) => {
                            const value = lg[f]
                            return (
                                value && (
                                    <MarkDown
                                        key={'md-' + i}
                                        name={f}
                                        children={value}
                                    />
                                )
                            )
                        })}
                    </>
                )
            })}
        </>
    )
}

export default MdAttr
