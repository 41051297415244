import { Box, Divider, Grid, Link, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'

import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import LoadingButton from '@mui/lab/LoadingButton'
import Validator from '../../../utils/Validator'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import authActions from '../../../redux/actions/auth.action'
import { useDispatch } from 'react-redux'

const validator = new Validator([
    {
        field: 'username',
        method: (value) => /^[a-zA-Z0-9]{6,20}$/.test(value),
        message: 'Tên đăng nhập không hợp lệ!',
        validWhen: true,
        args: [],
    },
])

const SignIn = ({ handleChange, onClose }) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)

    const [errors, setErrors] = useState({})
    const handleMouseDownPassword = (event) => {
        event.preventDefault()
    }

    const [stateForm, setForm] = useState({
        username: '',
        password: '',
    })

    const [validated, setValidated] = useState({
        username: false,
        password: false,
    })

    const [errorMessage, setErrorMessage] = useState('')
    const [showPassword, setShowPassword] = useState(false)

    const handleInput = (e) => {
        const temp = { ...stateForm, [e.target.name]: e.target.value }
        const error = validator.validate(temp)
        setForm(temp)
        setErrors(error)
        if (!error[e.target.name]) {
            setValidated({
                ...validated,
                [e.target.name]: true,
            })
        } else {
            setValidated({
                ...validated,
                [e.target.name]: false,
            })
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setErrorMessage('')
        const { username, password } = stateForm
        setLoading(true)
        dispatch(authActions.login(username, password))
            .then(() => {
                onClose()
            })
            .catch((error) => {})
            .finally(() => setLoading(false))
    }

    return (
        <>
            <Box
                id='login-form'
                component='form'
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1 }}
            >
                <TextField
                    size='small'
                    margin='normal'
                    required
                    fullWidth
                    id='username'
                    label='Tên đăng nhập'
                    error={!!errors.username}
                    helperText={errors?.username}
                    name='username'
                    variant='outlined'
                    value={stateForm.username}
                    onChange={handleInput}
                    autoComplete='new-password'
                />
                <TextField
                    size='small'
                    margin='normal'
                    autoComplete='new-password'
                    required
                    fullWidth
                    name='password'
                    label='Mật khẩu'
                    variant='outlined'
                    type={showPassword ? 'text' : 'password'}
                    value={stateForm.password}
                    onInput={handleInput}
                    InputProps={{
                        autoComplete: 'off',
                        endAdornment: (
                            <InputAdornment position='end'>
                                <IconButton
                                    aria-label='toggle password visibility'
                                    onClick={() =>
                                        setShowPassword(!showPassword)
                                    }
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {showPassword ? (
                                        <VisibilityOff />
                                    ) : (
                                        <Visibility />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <Typography
                    my={1}
                    color='error'
                    variant='p'
                    component='p'
                    sx={{ fontSize: '14px' }}
                >
                    {errorMessage}
                </Typography>
                {validated.username && validated.password ? (
                    <LoadingButton
                        type='submit'
                        fullWidth
                        loading={loading}
                        loadingPosition='start'
                        variant='contained'
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Đăng nhập
                    </LoadingButton>
                ) : (
                    <LoadingButton
                        type='submit'
                        fullWidth
                        loading={loading}
                        loadingPosition='start'
                        variant='contained'
                        sx={{ mt: 3, mb: 2 }}
                        disabled
                    >
                        Đăng nhập
                    </LoadingButton>
                )}
                <Grid container>
                    <Grid item xs></Grid>
                    <Grid item>
                        <Link
                            href='#'
                            variant='body2'
                            onClick={() => handleChange(1)}
                            align='right'
                        >
                            Đăng ký
                        </Link>
                    </Grid>
                </Grid>
                <Divider
                    sx={{
                        marginY: 2,
                    }}
                >
                    <Typography>hoặc</Typography>
                </Divider>
            </Box>
        </>
    )
}

export default SignIn
