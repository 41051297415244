import { getLuangiaiCache, getLuangiaiChitiet } from '../api/luangiai'

const luangiaiService = {
    getLuanGiaiTongQuat: async (code) => {
        const tracuu = await getLuangiaiCache(code)
        return tracuu.data
    },
    getLuangiaiChiTiet: async (payload, type) => {
        return getLuangiaiChitiet(payload, type)
    },
}

export default luangiaiService
