//react import

import * as Yup from 'yup'

import { Box, Grid, Modal, TextField, Typography } from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import LoadingButton from '@mui/lab/LoadingButton'
import Validator from '../../../utils/Validator'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import alertActions from '../../../redux/actions/alert.action'
import { useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import userActions from '../../../redux/actions/user.action'

//mui import



















export default function ChangePasswordModal({ open, handleCloseModal }) {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    //loading button
    const [loading, setLoading] = useState(false)

    const [showPassword, setShowPassword] = useState(false)

    const handleChangePassword = async (
        old_password,
        new_password,
        re_password
    ) => {
        if (old_password == new_password) {
            dispatch(
                alertActions.error(
                    'Mật khẩu mới không được trùng với mật khẩu cũ'
                )
            )
            return
        }

        setLoading(true)
        dispatch(
            userActions.changePassword(old_password, new_password, re_password)
        )
            .then(() => {
                console.log('thanh cong')
                setLoading(false)
                navigate('/')
            })
            .catch((error) => {
                console.log('loi')
                setLoading(false)
            })
    }

    const [checkErrors, setCheckErrors] = useState({
        old_password: false,
        new_password: false,
        re_password: false,
    })

    const formik = useFormik({
        initialValues: {
            old_password: '',
            new_password: '',
            re_password: '',
        },
        validationSchema: Yup.object({
            old_password: Yup.string().required(
                'Mật khẩu cũ không được bỏ trống'
            ),
            new_password: Yup.string()
                .required('Mật khẩu mới không được bỏ trống')
                .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,12}$/,
                    'Có 8 - 12 kí tự có số, chữ cái in hoa, chữ cái in thường và không có ký tự đặc biệt'
                ),
            re_password: Yup.string()
                .required('Mật khẩu xác nhận không được bỏ trống')
                .oneOf(
                    [Yup.ref('new_password'), null],
                    'Mật khẩu xác nhận phải giống mật khẩu!'
                ),
        }),
        onSubmit: ({ old_password, new_password, re_password }) => {
            handleChangePassword(old_password, new_password, re_password)
        },
    })

    const onClose = () => {
        Object.keys(formik.values).forEach((v) => (formik.values[v] = ''))
        Object.keys(checkErrors).forEach((v) => (checkErrors[v] = false))
        handleCloseModal()
    }
    return (
        <Modal
            open={open}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
        >
            <Box sx={style}>
                <Grid container>
                    <Grid item lg={6} md={6} sm={8} xs={10}>
                        <h3 id='parent-modal-title'>Đổi mật khẩu</h3>
                    </Grid>
                    <Grid
                        item
                        lg={6}
                        md={6}
                        sm={4}
                        xs={2}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <CloseIcon
                            sx={{ color: 'white', cursor: 'pointer' }}
                            onClick={onClose}
                        ></CloseIcon>
                    </Grid>
                </Grid>
                <Box sx={{ mt: 1, borderColor: 'divider' }}>
                    <TextField
                        size='small'
                        margin='normal'
                        autoComplete=''
                        fullWidth
                        name='old_password'
                        id='old_password'
                        label='Mật khẩu cũ'
                        variant='outlined'
                        type={showPassword ? 'text' : 'password'}
                        value={formik.values.old_password}
                        onChange={formik.handleChange}
                        onBlur={(e) => {
                            setCheckErrors({
                                ...checkErrors,
                                [e.target.name]: true,
                            })
                        }}
                        helperText={formik.errors.old_password}
                        error={
                            checkErrors.old_password &&
                            formik.errors.old_password
                        }
                        InputProps={{
                            autoComplete: 'off',
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <IconButton
                                        aria-label='toggle password visibility'
                                        onClick={() =>
                                            setShowPassword(!showPassword)
                                        }
                                    >
                                        {showPassword ? (
                                            <VisibilityOff />
                                        ) : (
                                            <Visibility />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        margin='normal'
                        fullWidth
                        size='small'
                        name='new_password'
                        label='Mật khẩu mới'
                        type={showPassword ? 'text' : 'password'}
                        id='new_password'
                        value={formik.values.new_password}
                        error={
                            checkErrors.new_password &&
                            formik.errors.new_password
                        }
                        helperText={
                            formik.errors?.new_password
                                ? formik.errors.new_password
                                : ''
                        }
                        onChange={formik.handleChange}
                        onBlur={(e) => {
                            setCheckErrors({
                                ...checkErrors,
                                [e.target.name]: true,
                            })
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <IconButton
                                        aria-label='toggle password visibility'
                                        onClick={() =>
                                            setShowPassword(!showPassword)
                                        }
                                    >
                                        {showPassword ? (
                                            <VisibilityOff />
                                        ) : (
                                            <Visibility />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        margin='normal'
                        fullWidth
                        size='small'
                        name='re_password'
                        label='Nhập lại mật khẩu mới'
                        type={showPassword ? 'text' : 'password'}
                        id='re_password'
                        value={formik.values.re_password}
                        error={
                            checkErrors.re_password && formik.errors.re_password
                        }
                        // helperText={formik.errors?.re_password ? formik.errors.re_password : ''}
                        onChange={formik.handleChange}
                        onBlur={(e) => {
                            setCheckErrors({
                                ...checkErrors,
                                [e.target.name]: true,
                            })
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <IconButton
                                        aria-label='toggle password visibility'
                                        onClick={() =>
                                            setShowPassword(!showPassword)
                                        }
                                    >
                                        {showPassword ? (
                                            <VisibilityOff />
                                        ) : (
                                            <Visibility />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <LoadingButton
                        type='submit'
                        fullWidth
                        loading={loading}
                        loadingPosition='start'
                        variant='contained'
                        sx={{ mt: 3, mb: 2 }}
                        onClick={(e) => {
                            e.preventDefault()
                            Object.keys(checkErrors).forEach(
                                (v) => (checkErrors[v] = true)
                            )
                            formik.handleSubmit()
                        }}
                        disabled={
                            JSON.stringify(formik.errors) === '{}'
                                ? false
                                : true
                        }
                    >
                        Đổi mật khẩu
                    </LoadingButton>
                </Box>
            </Box>
        </Modal>
    )
}
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: {
        lg: '30vw',
        md: '35vw',
        sm: '70vw',
        xs: '90vw',
    },
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 3,
    px: 4,
    pb: 4,
}
