import { Box, Typography } from '@mui/material'
import React from 'react'

const deg = [45, 90, 135, 180, 225, 270, 315, 360]
const percent = [50, 35, 87.5, 65, 12.5]
const linearGradientStyle = `linear-gradient({x1}deg, transparent calc({x2}% - 1.5px), var(--red-base) {x2}%, transparent calc({x2}% + 1.5px))`

const TableArrowComponent = ({ matrixArrow, caption }) => {
    return (
        <>
            <Box
                display='flex'
                justifyContent='center'
                flexDirection='column'
                flex={1}
                minWidth='8rem'
            >
                <table className='collapse'>
                    <tbody>
                        {matrixArrow
                            ? matrixArrow.map((m, i) => {
                                  return (
                                      <tr key={i}>
                                          {m.map((n, j) => {
                                              let className =
                                                  'border-b border-r bd red-base gray-base'
                                              if (i === 2) {
                                                  className = className.replace(
                                                      'border-b',
                                                      ''
                                                  )
                                              }
                                              if (j === 2) {
                                                  className = className.replace(
                                                      'border-r',
                                                      ''
                                                  )
                                              }
                                              if (!n.empty) {
                                                  className = className.replace(
                                                      'red-base',
                                                      ''
                                                  )
                                              }
                                              if (n.value) {
                                                  className = className.replace(
                                                      'gray-base',
                                                      ''
                                                  )
                                              }
                                              const temp = [
                                                  ...n.sign,
                                                  ...n.special,
                                              ]
                                              const styleLine = temp
                                                  .map((i) => {
                                                      let iDeg = 0
                                                      let iPercent = 0
                                                      if (i < 0) {
                                                          iDeg = deg[2]
                                                          iPercent = percent[-i]
                                                      } else {
                                                          iDeg = deg[i - 1]
                                                          iPercent = percent[0]
                                                      }
                                                      const obj = {
                                                          '{x1}': iDeg,
                                                          '{x2}': iPercent,
                                                      }
                                                      let retStr =
                                                          linearGradientStyle
                                                      for (let x in obj) {
                                                          retStr =
                                                              retStr.replace(
                                                                  new RegExp(
                                                                      x,
                                                                      'g'
                                                                  ),
                                                                  obj[x]
                                                              )
                                                      }
                                                      return retStr
                                                  })
                                                  .toString()
                                              return (
                                                  <td
                                                      key={j}
                                                      className={className}
                                                      style={{
                                                          background: styleLine,
                                                      }}
                                                  >
                                                      {n.value
                                                          ? n.value
                                                          : 3 - i + 3 * j}
                                                  </td>
                                              )
                                          })}
                                      </tr>
                                  )
                              })
                            : 'ĐÃ XẢY RA LỖI'}
                    </tbody>
                </table>
                <Typography
                    variant='caption'
                    mt={2}
                    sx={{ textAlign: 'center', fontStyle: 'italic' }}
                >
                    {caption}
                </Typography>
            </Box>
        </>
    )
}

export default TableArrowComponent
