import { CssBaseline, ThemeProvider, Toolbar } from '@mui/material'
import React, { useRef } from 'react'

import AlertCustom from '../../AlertCustom'
import AppModal from '../../Modal/AppModal'
import AutoScrollTop from '../../AutoScrollTop'
import BackgroundCould from '../../BackgroundCould'
import { Box } from '@mui/system'
import Footer from '../../Footer'
import Navbar from '../../Navbar'
import ScrollToTop from '../../ScrollToTop'

const LayoutDefault = ({ theme, children }) => {
    const topRef = useRef(null)
    return (
        <>
            <AlertCustom />
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <AutoScrollTop />
                <Box id='image-background'>
                    <Navbar />
                    <Toolbar id='back-to-top-anchor' ref={topRef} />
                    <section id='section'>
                        <BackgroundCould>
                            <div className='home-wrapper'>{children}</div>
                        </BackgroundCould>
                    </section>
                    <ScrollToTop topRef={topRef} />
                </Box>
                <AppModal />
                <Footer />
            </ThemeProvider>
        </>
    )
}

export default LayoutDefault
