import { Box, Button, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ContentPost from './components/ContentPost'
import FeaturedPost from './components/FeaturedPost'
import { MainContainer } from '../../components/UI'
import { PATH_PAGE } from '../../config/constant'
import { getCatalogues } from '../../api/catalogues'
import { getContentPost } from '../../api/post'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'

const Post = () => {
    const navigate = useNavigate()
    const { slug } = useParams()
    const [post, setPost] = useState(null)
    const [featuredPosts, setFeaturedPosts] = useState(new Array(3).fill({}))
    const [contentPost, setContentPost] = useState('Đang tải...')

    useEffect(() => {
        if (post !== null) {
            let description = document.querySelector('meta[name="description"]')
            if (description) {
                description.setAttribute('content', post.shortDescription)
            }
            document.title = post.title
        }
    }, [post])

    useEffect(() => {
        getCatalogues({}, slug)
            .then(({ data }) => {
                setPost(data.data)
                return data.data
            })
            .then(
                (catalogue) =>
                    getCatalogues({
                        fatherSlug: catalogue.fatherSlug,
                        isPost: 1,
                    }) //get recommend post
            )
            .then(({ data }) => setFeaturedPosts(data.data))
        getContentPost(slug)
            .then(({ data }) => setContentPost(data))
            .catch((error) => console.error(error))
    }, [slug])

    return (
        <MainContainer>
            <Box spacing={3} mb={4}>
                <ContentPost post={post} contentPost={contentPost} />
            </Box>
            <Box
                display='flex'
                flexDirection='row'
                justifyContent='space-between'
                mb={4}
            >
                <Typography gutterBottom variant='h4'>
                    Có thể bạn quan tâm
                </Typography>
                <Button
                    variant='text'
                    color='primary'
                    size='small'
                    startIcon={<ArrowBackIcon />}
                    onClick={() => navigate(PATH_PAGE.human_arithmetic_theory)}
                >
                    Quay lại trang lý thuyết
                </Button>
            </Box>
            <Box>
                <Grid container spacing={4} display='flex' alignItems='stretch'>
                    {featuredPosts.slice(0, 6).map((featuredPost, i) => (
                        <FeaturedPost key={i} post={featuredPost} />
                    ))}
                </Grid>
            </Box>
        </MainContainer>
    )
}

export default Post
