import { Box, Button, Container, Grid } from '@mui/material'
import React, { useContext } from 'react'

import AppContext from '../../context/AppContext'
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined'
import DownloadIcon from '@mui/icons-material/Download'
import FacebookIcon from '@mui/icons-material/Facebook'
import { FacebookShareButton } from 'react-share'
import InstallPWA from '../InstallPWA'
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined'
import { Link } from 'react-router-dom'
import LogoCongTy from '../../assets/images/nhan-so-hoc-logo-cong-ty.webp'
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined'
import NhanSoApp from '../../assets/images/nhan-so-app.jpg'
import { PATH_PAGE } from '../../config/constant'
import PhoneInTalkOutlinedIcon from '@mui/icons-material/PhoneInTalkOutlined'
import QRCode from '../../assets/images/qrcode_nhansodaiviet.com.png'

const Footer = () => {
    const { footer } = useContext(AppContext)
    const [isShowFooter] = footer
    return (
        isShowFooter && (
            <footer id='footer' className='footer-area'>
                <div className='footer-shape shape-1'></div>
                <div className='footer-shape shape-2'></div>
                <div className='footer-shape shape-3'></div>
                <div className='footer-shape shape-4'></div>
                <div className='footer-shape shape-5'></div>
                <div className='footer-shape shape-6'></div>
                <div className='footer-shape shape-7'></div>
                <div className='footer-shape shape-8'></div>

                <Box
                    className='footer-widget'
                    style={{
                        paddingBottom: '120px',
                        paddingTop: '120px',
                    }}
                >
                    <Container>
                        <Grid container spacing={5}>
                            {/* <Grid item lg={3} md={4} sm={6}>
                                <div
                                    className='footer-about mt-50 wow fadeIn'
                                    data-wow-duration='1.3s'
                                    data-wow-delay='0.2s'
                                >
                                    <a className='logo' href='#'>
                                        <img src={NhanSoApp} alt='Logo' />
                                    </a>
                                    <p className='text'>
                                        Nhân Số Đại Việt là công cụ tra cứu nhân
                                        số học tốt nhất tại Việt Nam tại thời
                                        điểm hiện tại
                                    </p>
                                    <ul className='social'>
                                        <li>
                                            <a href='#'>
                                                <i className='lni lni-facebook'></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href='#'>
                                                <i className='lni lni-twitter'></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href='#'>
                                                <i className='lni lni-instagram'></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href='#'>
                                                <i className='lni lni-linkedin'></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </Grid> */}
                            <Grid item lg={3} md={4} sm={6}>
                                <div
                                    className='footer-contact wow fadeIn'
                                    data-wow-duration='1.3s'
                                    data-wow-delay='0.8s'
                                >
                                    <div className='footer-title'>
                                        <h4 className='title'>Công ty</h4>
                                    </div>
                                    <ul className='contact-list'>
                                        <li>
                                            <div className='contact-info '>
                                                <div className='info-content media-body'>
                                                    <p className='text'>
                                                        CÔNG TY CỔ PHẦN PHÁT
                                                        TRIỂN GIẢI PHÁP SỐ ĐẠI
                                                        VIỆT
                                                    </p>
                                                </div>
                                            </div>
                                        </li>

                                        <li>
                                            <div className='contact-info '>
                                                <div className='info-content media-body'>
                                                    <p className='text'>
                                                        Giám đốc: Trần Thu Thuỷ
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className='contact-info d-flex'>
                                                <div className='info-content media-body'>
                                                    <p className='text'>
                                                        Mã số thuế: 0109885713
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <img
                                                style={{ width: '80%' }}
                                                src={LogoCongTy}
                                                alt='logo-cong-ty'
                                            />
                                        </li>
                                    </ul>
                                </div>
                            </Grid>
                            <Grid item lg={3} md={4} sm={6}>
                                <div
                                    className='footer-contact wow fadeIn'
                                    data-wow-duration='1.3s'
                                    data-wow-delay='0.8s'
                                >
                                    <div className='footer-title'>
                                        <h4 className='title'>Liên hệ</h4>
                                    </div>
                                    <ul className='contact-list'>
                                        <li>
                                            <div className='contact-info '>
                                                <div className='info-content media-body'>
                                                    <p className='text'>
                                                        <PhoneInTalkOutlinedIcon />{' '}
                                                        (+84) 979.33.88.11
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className='contact-info d-flex'>
                                                <div className='info-content media-body'>
                                                    <p className='text'>
                                                        <MailOutlineOutlinedIcon />{' '}
                                                        info@daviso.vn
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className='contact-info d-flex'>
                                                <div className='info-content media-body'>
                                                    <p className='text'>
                                                        <a
                                                            href='https://daviso.vn/'
                                                            target='_blank'
                                                        >
                                                            www.daviso.vn
                                                        </a>
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className='contact-info d-flex'>
                                                <div className='info-content media-body'>
                                                    <p className='text'>
                                                        <BusinessOutlinedIcon />{' '}
                                                        Số 23, ngõ 412 đường
                                                        Ngọc Thụy, quận Long
                                                        Biên, Hà Nội
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </Grid>
                            <Grid item lg={3} md={4} sm={6}>
                                <div
                                    className='footer-contact wow fadeIn'
                                    data-wow-duration='1.3s'
                                    data-wow-delay='0.8s'
                                >
                                    <div className='footer-title'>
                                        <h4 className='title'>Trang</h4>
                                    </div>
                                    <ul className='contact-list'>
                                        <li>
                                            <div className='contact-info d-flex'>
                                                <div className='info-content media-body'>
                                                    <p className='text'>
                                                        <Link
                                                            to={PATH_PAGE.home}
                                                        >
                                                            Trang chủ
                                                        </Link>
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className='contact-info d-flex'>
                                                <div className='info-content media-body'>
                                                    <p className='text'>
                                                        <Link
                                                            to={
                                                                PATH_PAGE.human_arithmetic
                                                            }
                                                        >
                                                            Công cụ tra cứu
                                                        </Link>
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className='contact-info d-flex'>
                                                <div className='info-content media-body'>
                                                    <p className='text'>
                                                        <Link
                                                            to={
                                                                PATH_PAGE.human_arithmetic_theory
                                                            }
                                                        >
                                                            Lý thuyết thần số
                                                        </Link>
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className='contact-info d-flex'>
                                                <div className='info-content media-body'>
                                                    <p className='text'>
                                                        <Link
                                                            to={PATH_PAGE.about}
                                                        >
                                                            Về chúng tôi
                                                        </Link>
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className='contact-info'>
                                                <div className='info-content media-body'>
                                                    <p className='text'>
                                                        <Link
                                                            to={
                                                                PATH_PAGE.policy
                                                            }
                                                        >
                                                            Điều khoản
                                                        </Link>
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </Grid>
                            <Grid item lg={3} md={4} sm={6}>
                                <div
                                    className='footer-contact wow fadeIn'
                                    data-wow-duration='1.3s'
                                    data-wow-delay='0.8s'
                                >
                                    <div className='footer-title'>
                                        <h4 className='title'>Tải ứng dụng</h4>
                                    </div>
                                    <ul className='contact-list'>
                                        <li>
                                            <div className='contact-info '>
                                                <div className='info-content media-body'>
                                                    <p className='text'>
                                                        Sử dụng QR-code hoặc
                                                        Facebook để chia sẻ
                                                        chúng tôi với bạn bè của
                                                        bạn.
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className='contact-info '>
                                                <div className='info-content media-body'>
                                                    <p
                                                        className='text'
                                                        style={{
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                            columnGap: '25px',
                                                        }}
                                                    >
                                                        <img
                                                            style={{
                                                                width: '120px',
                                                            }}
                                                            alt='qrcode'
                                                            src={QRCode}
                                                        />
                                                        <p
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection:
                                                                    'column',
                                                                rowGap: '25px',
                                                            }}
                                                        >
                                                            <InstallPWA />
                                                            <FacebookShareButton
                                                                url={
                                                                    'https://nhansodaiviet.com/'
                                                                }
                                                            >
                                                                <Button
                                                                    variant='contained'
                                                                    style={{
                                                                        color: 'var(--white-base)',
                                                                        backgroundColor:
                                                                            'var(--facebook-bg)',
                                                                    }}
                                                                    startIcon={
                                                                        <FacebookIcon />
                                                                    }
                                                                >
                                                                    Facebook
                                                                </Button>
                                                            </FacebookShareButton>
                                                        </p>
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
                {/* <div className='footer-copyright'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='copyright d-sm-flex justify-content-between'>
                                <div className='copyright-text text-center'>
                                    <p className='text'>
                                        Template Crafted by{' '}
                                        <a
                                            rel='nofollow'
                                            href='https://uideck.com'
                                        >
                                            UIdeck
                                        </a>
                                    </p>
                                </div>

                                <div className='copyright-privacy text-center'>
                                    <a href='#'>Report Issues</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
                {/* <div className='background-overlay'></div> */}
            </footer>
        )
    )
}

export default Footer
