import {
    getMyOrders,
    paymentMomoAPI,
    solvingOrderMomoAPI,
} from '../api/payment.api'

const paymentService = {
    payMomo: async (orderId, redirectUrl, ipnUrl = '') => {
        try {
            const response = await paymentMomoAPI(orderId, {
                redirect_url: redirectUrl,
            })
            return response.data
        } catch (error) {
            return Promise.reject(error)
        }
    },

    solvingOrderMomo: async (bodyData) => {
        const {
            orderId,
            price,
            statusCode,
            signature1st,
            signature2nd,
            timeResponse,
            typePaid,
            saleId,
        } = bodyData
        try {
            return await solvingOrderMomoAPI(
                orderId,
                price,
                statusCode,
                signature1st,
                signature2nd,
                timeResponse,
                typePaid,
                saleId
            )
        } catch (error) {
            return Promise.reject(error)
        }
    },

    getMyOrders: async () => {
        try {
            const { data } = await getMyOrders()
            return data.orders
        } catch (error) {
            return Promise.reject(error)
        }
    },
}

export default paymentService
