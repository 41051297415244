import moment from 'moment'
import luangiaiConstants from '../../config/constant/luangiai.constant'
import luangiaiService from '../../services/luangiai.service'
import alertActions from './alert.action'

const luangiaiActions = {
    tongquat: (code) => {
        return async (dispatch) => {
            const key = window.location.href
            const dataInStorage = localStorage.getItem(key)
            if (dataInStorage) {
                const parseDataInStoreage = JSON.parse(dataInStorage)
                const { exp } = parseDataInStoreage
                const now = new Date()
                if (now.getTime() <= exp) {
                    return dispatch({
                        type: luangiaiConstants.TONG_QUAT,
                        payload: parseDataInStoreage,
                    })
                } else {
                    localStorage.removeItem(key)
                }
            }
            try {
                const { data: luangiai } =
                    await luangiaiService.getLuanGiaiTongQuat(code)
                // Life-time in LocalStorage is 30 mins
                const exp = moment().add(30, 'm').toDate().getTime()
                const dataStore = {
                    ...luangiai,
                    exp,
                }
                localStorage.setItem(key, JSON.stringify(dataStore))
                dispatch({
                    type: luangiaiConstants.TONG_QUAT,
                    payload: luangiai,
                })
            } catch ({ error }) {
                const { response } = error
                if (response.status === 404) {
                    return Promise.reject(error)
                }
                dispatch(alertActions.error(error.errorMessage))
            }
        }
    },
    chitietSoChuDao: (payload) => {
        return async (dispatch) => {
            try {
                const { data } = await luangiaiService.getLuangiaiChiTiet(
                    payload,
                    'so-chu-dao'
                )
                const { data: luangiai } = data
                dispatch({
                    type: luangiaiConstants.SO_CHU_DAO,
                    payload: luangiai,
                })
            } catch (error) {
                dispatch(alertActions.error(error.errorMessage))
            }
        }
    },
    chitietNamCaNhan: (payload) => {
        return async (dispatch) => {
            try {
                const { data } = await luangiaiService.getLuangiaiChiTiet(
                    payload,
                    'so-nam-ca-nhan'
                )
                const { data: luangiai } = data
                dispatch({
                    type: luangiaiConstants.SO_NAM_CA_NHAN,
                    payload: luangiai,
                })
            } catch (error) {
                dispatch(alertActions.error(error.errorMessage))
            }
        }
    },
    chitietBDNgaySinh: (payload) => {
        return async (dispatch) => {
            try {
                const { data } = await luangiaiService.getLuangiaiChiTiet(
                    payload,
                    'bieu-do-ngay-sinh'
                )
                const { data: luangiai } = data
                dispatch({
                    type: luangiaiConstants.BIEU_DO_NGAY_SINH,
                    payload: luangiai,
                })
            } catch (error) {
                dispatch(alertActions.error(error.errorMessage))
            }
        }
    },
    chitietBDTenRieng: (payload) => {
        return async (dispatch) => {
            try {
                const { data } = await luangiaiService.getLuangiaiChiTiet(
                    payload,
                    'bieu-do-ten-rieng'
                )
                const { data: luangiai } = data
                dispatch({
                    type: luangiaiConstants.BIEU_DO_TEN_RIENG,
                    payload: luangiai,
                })
            } catch (error) {
                dispatch(alertActions.error(error.errorMessage))
            }
        }
    },
    chitietBDTongHop: (payload) => {
        return async (dispatch) => {
            try {
                const { data } = await luangiaiService.getLuangiaiChiTiet(
                    payload,
                    'bieu-do-tong-hop'
                )
                const { data: luangiai } = data
                dispatch({
                    type: luangiaiConstants.BIEU_DO_TONG_HOP,
                    payload: luangiai,
                })
            } catch (error) {
                dispatch(alertActions.error(error.errorMessage))
            }
        }
    },
    chitietSMTenRieng: (payload) => {
        return async (dispatch) => {
            try {
                const { data } = await luangiaiService.getLuangiaiChiTiet(
                    payload,
                    'suc-manh-ten-rieng'
                )
                const { data: luangiai } = data
                dispatch({
                    type: luangiaiConstants.SUC_MANH_TEN_RIENG,
                    payload: luangiai,
                })
            } catch (error) {
                dispatch(alertActions.error(error.errorMessage))
            }
        }
    },
    chitietDinhCaoDoiNguoi: (payload) => {
        return async (dispatch) => {
            try {
                const { data } = await luangiaiService.getLuangiaiChiTiet(
                    payload,
                    'kim-tu-thap'
                )
                const { data: luangiai } = data
                dispatch({
                    type: luangiaiConstants.KIM_TU_THAP,
                    payload: luangiai,
                })
            } catch (error) {
                dispatch(alertActions.error(error.errorMessage))
            }
        }
    },
}
export default luangiaiActions
