import React from 'react'
import { Box } from '@mui/material'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import 'react-medium-image-zoom/dist/styles.css'
import BaseContent from './Base'
import { calSoNamCaNhan } from '../../../helpers/calculateHumanArithmetic'
import { useSelector } from 'react-redux'
import luangiaiActions from '../../../redux/actions/luangiai.action'
import MdAttr from './MdAttr'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
)

const dataChart = {
    _80: 9,
    _88: 1,
    _54: 2,
    _19: 3,
    _2: 4,
    _16: 5,
    _40: 6,
    _20: 7,
    _62: 8,
}

const getLabelYear = (personalYear) => {
    const indexYear = new Array(9).fill(0)
    const year = new Date().getFullYear()
    for (let index = 0; index < indexYear.length; index++) {
        indexYear[index] =
            year - (personalYear === 9 ? 0 : personalYear) + index
    }
    const labelYear = []
    for (const key in dataChart) {
        if (Object.hasOwnProperty.call(dataChart, key)) {
            const item = dataChart[key]
            if (item === '') {
                labelYear.push('')
            } else if (item === 9) {
                labelYear.push(indexYear[0])
            } else {
                labelYear.push(indexYear[item])
            }
        }
    }
    return labelYear
}

const getData = (personalYear) => {
    return {
        labels: getLabelYear(personalYear),
        datasets: [
            {
                label: 'Chu kỳ 9 năm',
                data: Object.keys(dataChart).map((s) => parseInt(s.slice(1))),
                fill: true,
                borderColor: '#1976d2',
                backgroundColor: '#1976d2',
                pointRadius: 0,
            },
        ],
    }
}

const optionsChart = {
    responsive: true,
    backgroundColor: 'rgba(255, 255, 255)',
    borderColor: 'rgba(255, 255, 255)',
    tension: 0.5,
    showLine: true,
    scales: {
        yAxes: {
            ticks: {
                color: 'transparent',
            },
        },
        xAxes: {
            ticks: {
                color: '#fff',
            },
        },
    },
    events: [],
    pointBorderWidth: '3',
}

const ChuKyVanNam = () => {
    // const date = new Date(state.dob.replace(/-/g, '/'))
    const { lg_sonamcanhan, query: state } = useSelector((s) => s.luangiai)
    const date = new Date(state.dob)
    const personalYear = calSoNamCaNhan(date)
    return (
        <BaseContent
            title={'Chu kỳ vận năm'}
            des='Thần số học là một lĩnh vực hoạt động dựa trên mô hình phát triển theo chu kỳ 9 năm. Sau đó, chúng lại bắt đầu lại sau mỗi chu kỳ vận số của mình. Mỗi một năm trong chu kỳ đều có những nhu cầu và cá tính riêng. Vì vậy, mỗi người cần phải nắm bắt được những ý nghĩa, khoảnh khắc trong mỗi chu kỳ để biết được mình đang ở đâu và có thể mở ra những bí ẩn nào trong đường của bạn.'
            helperText='Đăng nhập để xem đầy đủ chu kỳ vận năm của bản thân'
            hashLink='chu-ky-van-nam'
            id='chu-ky-van-nam'
            condition={lg_sonamcanhan?.length > 0}
            actions={luangiaiActions.chitietNamCaNhan}
            state={state}
        >
            <Box id='chart-canvas'>
                <Line
                    options={{
                        ...optionsChart,
                        plugins: {
                            datalabels: {
                                font: {
                                    size: 15,
                                },
                                align: 'left',
                                formatter: function (value, context) {
                                    const globalYear = dataChart['_' + value]
                                    if (globalYear) {
                                        if (globalYear === personalYear) {
                                            return (
                                                'Năm thứ ' +
                                                dataChart['_' + value]
                                            )
                                        }
                                        return dataChart['_' + value]
                                    }
                                    return null
                                },
                            },
                        },
                    }}
                    data={getData(personalYear)}
                />
            </Box>
            <Box name='luangiai' mt={4}>
                <MdAttr luangiai={lg_sonamcanhan} />
            </Box>
        </BaseContent>
    )
}

export default ChuKyVanNam
