import Container from '@mui/material/Container'
import MuiAppBar from '@mui/material/AppBar'
import { SwipeableDrawer } from '@mui/material'
import { styled } from '@mui/material/styles'

const drawerWidth = 240

export const Main = styled('main')(({ theme }) => ({
    flexGrow: 1,
    height: '100%',
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
}))

export const MainContainer = styled(Container)(({ theme }) => ({
    ...theme,
    marginTop: '3rem',
    marginBottom: '3rem',
    '@media (min-width: 1024px)': {
        margin: '0 auto',
        maxWidth: '1530px',
    },
}))

export const MainAdsContainer = styled(Container)(({ theme }) => ({
    ...theme,
    marginTop: '3rem',
    marginBottom: '3rem',
    boxSizing: 'border-box',
    maxWidth: '1140px',
    padding: '0 15px',
    '@media (min-width: 768px)': {
        maxWidth: '80.498938em',
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: '0 2.617924em',
    },
}))

export const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: 'none',
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}))

export const DrawerCustome = styled(SwipeableDrawer)(({ theme }) => {
    return {
        ...theme,
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
        },
    }
})

export const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}))
