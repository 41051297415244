import { Box } from '@mui/material'
import React from 'react'

const NumberBox = () => {
    return (
        <Box
            sx={{
                boxShadow: '0px 0px 10px 0px rgb(154 154 154 / 16%)',
                padding: '30px 25px 35px',
            }}
        >
            <Box display='flex'></Box>
            <div className='content'>
                <h5>Số chủ đạo</h5>
                <p className='text'>Số chủ đạo là số</p>
            </div>
        </Box>
    )
}

export default NumberBox
