import axios from '../axios'

export const changePassword = (currentPassword, newPassword, confirmPassword) =>
    axios.put('/auth/change-password', {
        currentPassword,
        newPassword,
        confirmPassword,
    })

// export const updateProfile = ({
//     email,
//     fullname,
//     phoneCode,
//     phoneNumber,
//     birth,
//     avatarUrl,
// }) => {
//     const phoneNum = [phoneNumber]
//     phoneNumber = phoneNum
//     return axios.put('/user/profile', {
//         email,
//         avatarUrl,
//         fullname,
//         phoneCode,
//         phoneNumber,
//         birth,
//     })
// }

export const updateProfile = (payload) => {
    const bodyFormData = new FormData()
    for (const key in payload) {
        let value = payload[key]
        if (value) {
            if (key === 'phoneNumber') {
                bodyFormData.append(key + '[]', value)
            } else {
                bodyFormData.append(key, value)
            }
        }
    }
    return axios({
        url: '/user/profile',
        method: 'PUT',
        data: bodyFormData,
    })
}

export const getHistorySearch = (search, page, limit) => {
    return axios.get('/history/view-online', {
        params: {
            ...(search !== '' ? { search } : {}),
            page,
            limit,
        },
    })
}

export const getMyProfile = () => axios.get('/user/profile')

export const getUserList = () => axios.get('user/info/')
