// react import

import * as Yup from 'yup'

import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Typography,
} from '@mui/material'
import React, { useState } from 'react'

import ChangePasswordModal from './ChangePasswordModal'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import alertActions from '../../../redux/actions/alert.action'
import authActions from '../../../redux/actions/auth.action'
import { isVietnamese } from '../../../helpers/characterTransform'
import moment from 'moment'
import phoneCode from '../../../config/data/phoneCode'
import { useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'
import userActions from '../../../redux/actions/user.action'

export default function ProfileDetails({ user }) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    //open modal change password
    const [openModal, setOpenModal] = useState(false)

    const [loading, setLoading] = useState(false)

    const getBirth = user && user.birth ? user.birth.substring(0, 10) : ''
    const getYear = getBirth !== '' ? Number(getBirth.substring(0, 4)) : 2000
    const getMonth = getBirth !== '' ? Number(getBirth.substring(5, 7)) : 1
    const getDay = getBirth !== '' ? Number(getBirth.substring(8, 10)) : 1

    const [checkErrors, setCheckErrors] = useState({
        fullname: false,
        email: false,
        phoneCode: false,
        phoneNumber: false,
    })

    //alert update
    const [openUpdateAlert, setOpenUpdateAlert] = useState(false)
    const handleOpenUpdateAlert = () => {
        setOpenUpdateAlert(true)
    }
    const handleCloseUpdateAlert = () => {
        setOpenUpdateAlert(false)
    }
    //update user
    const handleUpdateProfile = async ({
        fullname,
        email,
        phoneCode,
        phoneNumber,
        date,
        month,
        year,
    }) => {
        const birthDay =
            date.toString().length === 2 ? date.toString() : `0${date}`
        const birthMonth =
            month.toString().length === 2 ? month.toString() : `0${month}`
        const birth = `${year}-${birthMonth}-${birthDay}`
        const phoneNum = [phoneNumber]
        phoneNumber = phoneNum
        moment(birth).isValid()
            ? dispatch(
                  userActions.updateProfile({
                      fullname,
                      email,
                      phoneCode,
                      phoneNumber,
                      birth,
                  })
              )
            : dispatch(alertActions.error('Ngày sinh không hợp lệ!'))
    }

    //alert log out
    const [openLogOutAlert, setOpenLogOutAlert] = useState(false)
    const handleOpenLogOutAlert = () => {
        setOpenLogOutAlert(true)
    }
    const handleCloseLogOutAlert = () => {
        setOpenLogOutAlert(false)
    }

    //log out
    const handleSignOut = async () => {
        await dispatch(authActions.logout())
        navigate('/')
    }
    const formik = useFormik({
        initialValues: {
            fullname:
                user?.fullname && user.fullname !== '' ? user.fullname : '',
            email: user?.email && user.email !== '' ? user.email : '',
            phoneCode:
                user?.phoneCode && user.phoneCode !== ''
                    ? user.phoneCode
                    : '+84',
            phoneNumber:
                user?.phoneNumber && user.phoneNumber.length !== 0
                    ? user.phoneNumber[0]
                    : '',
            date: getDay.toString(),
            month: getMonth.toString(),
            year: getYear.toString(),
        },
        validationSchema: Yup.object({
            fullname: Yup.string()
                .required('Tên đầy đủ không được đê trống!')
                // .matches(
                //     /^[A-ZÀÁẠẢÃÂẦẤẬẨẪĂẰẮẶẲẴÈÉẸẺẼÊỀẾỆỂỄÌÍỊỈĨÒÓỌỎÕÔỒỐỘỔỖƠỜỚỢỞỠÙÚỤỦŨƯỪỨỰỬỮỲÝỴỶỸĐa-zàáạảãâầấậẩẫăằắặẳẵèéẹẻẽêềếệểễìíịỉĩòóọỏõôồốộổỗơờớợởỡùúụủũưừứựửữỳýỵỷỹđ][a-zàáạảãâầấậẩẫăằắặẳẵèéẹẻẽêềếệểễìíịỉĩòóọỏõôồốộổỗơờớợởỡùúụủũưừứựửữỳýỵỷỹđ]*(?:[ ][A-ZÀÁẠẢÃÂẦẤẬẨẪĂẰẮẶẲẴÈÉẸẺẼÊỀẾỆỂỄÌÍỊỈĨÒÓỌỎÕÔỒỐỘỔỖƠỜỚỢỞỠÙÚỤỦŨƯỪỨỰỬỮỲÝỴỶỸĐ][a-zàáạảãâầấậẩẫăằắặẳẵèéẹẻẽêềếệểễìíịỉĩòóọỏõôồốộổỗơờớợởỡùúụủũưừứựửữỳýỵỷỹđ]*)*$/,
                //     'Tên đầy đủ không hợp lệ!'
                // ),
                .test({
                    message: 'Tên đầy đủ không hợp lệ!',
                    test: (value) => isVietnamese(value),
                }),
            email: Yup.string()
                .required('Email không được đê trống!')
                .matches(
                    /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                    'Email không hợp lệ!'
                ),
            phoneCode: Yup.string().required('Mã vùng không được đê trống!'),
            phoneNumber: Yup.string()
                .required('Số điện thoại không được đê trống!')
                .matches(/^[0-9]{10,11}$/, 'Số điện thoại không hợp lệ!'),
        }),
        onSubmit: ({
            fullname,
            email,
            phoneCode,
            phoneNumber,
            date,
            month,
            year,
        }) => {
            handleUpdateProfile({
                fullname,
                email,
                phoneCode,
                phoneNumber,
                date,
                month,
                year,
            })
        },
    })
    if (!user) {
        return <></>
    }
    return (
        <>
            <ChangePasswordModal
                open={openModal}
                handleCloseModal={() => setOpenModal(false)}
            />
            <form
                autoComplete='off'
                noValidate
                onSubmit={(e) => {
                    e.preventDefault()
                    handleOpenUpdateAlert()
                }}
            >
                {/* <Card> */}
                {/* <CardHeader subheader='Hồ sơ có thể chỉnh sửa' title='Hồ sơ' /> */}
                <Typography
                    variant='h5'
                    component='div'
                    title='Hồ sơ'
                    marginBottom='20px'
                >
                    Thông tin người dùng
                </Typography>
                {/* <CardContent> */}
                <Grid
                    container
                    rowSpacing={{ xs: 3, sm: 5, md: 6 }}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                    <Grid item md={6} xs={12}>
                        <TextField
                            fullWidth
                            label='Họ tên'
                            name='fullname'
                            id='fullname'
                            value={formik.values.fullname}
                            onChange={formik.handleChange}
                            onBlur={(e) => {
                                setCheckErrors({
                                    ...checkErrors,
                                    [e.target.name]: true,
                                })
                            }}
                            error={
                                checkErrors.fullname && formik.errors.fullname
                            }
                            helperText={formik.errors.fullname}
                            variant='standard'
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextField
                            fullWidth
                            label='Email'
                            name='email'
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={(e) => {
                                setCheckErrors({
                                    ...checkErrors,
                                    [e.target.name]: true,
                                })
                            }}
                            error={checkErrors.email && formik.errors.email}
                            helperText={formik.errors.email}
                            variant='standard'
                        />
                    </Grid>
                    <Grid item md={6} xs={12} container spacing={1}>
                        <Grid item lg={6} md={6} sm={3} xs={4}>
                            <FormControl fullWidth variant='standard'>
                                <InputLabel id='lb-input-phoneCode'>
                                    Mã vùng
                                </InputLabel>
                                <Select
                                    required
                                    id='input-phoneCode'
                                    name='phoneCode'
                                    labelId='lb-input-phoneCode'
                                    // input={<OutlinedInput label='Mã vùng' />}
                                    value={formik.values.phoneCode}
                                    onChange={formik.handleChange}
                                    error={formik.errors.phoneCode}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem value='+84'>
                                        <em>Mã vùng</em>
                                    </MenuItem>
                                    {Object.keys(phoneCode).map(
                                        (key, index) => (
                                            <MenuItem
                                                value={phoneCode[key].code}
                                            >
                                                {phoneCode[key].code} (
                                                {phoneCode[key].name})
                                            </MenuItem>
                                        )
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item lg={6} md={6} sm={9} xs={8}>
                            <TextField
                                fullWidth
                                label='Số điện thoại'
                                name='phoneNumber'
                                value={formik.values.phoneNumber}
                                onChange={(e) => {
                                    const re = /^[0-9\b]+$/
                                    if (
                                        e.target.value === '' ||
                                        re.test(e.target.value)
                                    ) {
                                        formik.handleChange(e)
                                    }
                                }}
                                onBlur={(e) => {
                                    setCheckErrors({
                                        ...checkErrors,
                                        [e.target.name]: true,
                                    })
                                }}
                                error={
                                    checkErrors.phoneNumber &&
                                    formik.errors.phoneNumber
                                }
                                helperText={formik.errors.phoneNumber}
                                variant='standard'
                            />
                        </Grid>
                    </Grid>
                    <Grid item md={6} xs={12} container spacing={1}>
                        <Grid item md={4} sm={4} lg={4} xs={4}>
                            <FormControl fullWidth variant='standard'>
                                <InputLabel htmlFor='input-date'>
                                    Ngày sinh
                                </InputLabel>
                                <Select
                                    required
                                    id='input-date'
                                    name='date'
                                    labelId='lb-input-date'
                                    // input={<OutlinedInput label='Ngày sinh' />}
                                    value={formik.values.date}
                                    onChange={formik.handleChange}
                                    error={formik.errors.date}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem value=''>
                                        <em>Ngày</em>
                                    </MenuItem>
                                    {makeNArray(31).map((date) => (
                                        <MenuItem value={date}>{date}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={4} sm={4} lg={4} xs={4}>
                            <FormControl fullWidth variant='standard'>
                                <InputLabel id='lb-input-month'>
                                    Tháng sinh
                                </InputLabel>
                                <Select
                                    required
                                    id='input-month'
                                    labelId='lb-input-month'
                                    name='month'
                                    // input={<OutlinedInput label='Tháng sinh' />}
                                    value={formik.values.month}
                                    onChange={formik.handleChange}
                                    error={formik.errors.month}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem value=''>
                                        <em>Tháng</em>
                                    </MenuItem>
                                    {makeNArray(12).map((month) => (
                                        <MenuItem value={month}>
                                            {month}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={4} sm={4} lg={4} xs={4}>
                            <FormControl
                                error={formik.errors?.year}
                                fullWidth
                                variant='standard'
                            >
                                <InputLabel id='lb-input-year'>
                                    Năm sinh
                                </InputLabel>
                                <Select
                                    required
                                    id='input-year'
                                    labelId='lb-input-year'
                                    name='year'
                                    label='Năm sinh'
                                    // input={<OutlinedInput label='Năm sinh' />}
                                    value={formik.values.year}
                                    onChange={formik.handleChange}
                                    error={formik.errors.year}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem value=''>
                                        <em>Năm</em>
                                    </MenuItem>
                                    {makeNYearArrayFromNow().map((year) => (
                                        <MenuItem value={year}>{year}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                {/* </CardContent> */}
                <Grid container sx={{ marginTop: '2rem' }}>
                    <Grid
                        item
                        lg={6}
                        md={6}
                        sm={4}
                        xs={12}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: {
                                xs: '1vh',
                                lg: '0',
                                md: '0',
                                sm: '0',
                            },
                            justifyContent: {
                                xs: 'center',
                                lg: 'flex-start',
                                md: 'flex-start',
                                sm: 'flex-start',
                            },
                        }}
                    >
                        <p
                            style={{
                                marginLeft: '15px',
                                textDecoration: 'underline',
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                setOpenModal(true)
                            }}
                        >
                            Đổi mật khẩu
                        </p>
                    </Grid>
                    <Grid item lg={6} md={6} sm={8} xs={12}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: {
                                    lg: 'flex-end',
                                    md: 'flex-end',
                                    sm: 'flex-end',
                                    xs: 'center',
                                },
                                p: 2,
                            }}
                        >
                            <Button
                                color='primary'
                                variant='outlined'
                                onClick={handleOpenLogOutAlert}
                                sx={{
                                    marginRight: 1,
                                }}
                            >
                                Đăng xuất
                            </Button>
                            {JSON.stringify(formik.errors) === '{}' ? (
                                <Button
                                    color='success'
                                    variant='contained'
                                    type='submit'
                                >
                                    Lưu thay đổi
                                </Button>
                            ) : (
                                <Button
                                    color='success'
                                    variant='contained'
                                    type='submit'
                                    disabled
                                >
                                    Lưu thay đổi
                                </Button>
                            )}
                        </Box>
                    </Grid>
                </Grid>
                {/* </Card> */}
            </form>
            <Dialog
                open={openLogOutAlert}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby='alert-dialog-slide-description'
            >
                <DialogTitle>{'Bạn có chắc muốn đăng xuất ?'}</DialogTitle>
                <DialogActions>
                    <Button onClick={handleCloseLogOutAlert}>Quay lại</Button>
                    <Button
                        onClick={() => {
                            handleCloseLogOutAlert()
                            handleSignOut()
                        }}
                    >
                        Chắc chắn
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openUpdateAlert}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby='alert-dialog-slide-description'
            >
                <DialogTitle>
                    {'Bạn có chắc muốn cập nhập thông tin?'}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={handleCloseUpdateAlert}>Hủy</Button>
                    <Button
                        onClick={() => {
                            handleCloseUpdateAlert()
                            Object.keys(checkErrors).forEach(
                                (v) => (checkErrors[v] = true)
                            )
                            formik.handleSubmit()
                        }}
                    >
                        Chắc chắn
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />
})

const ITEM_PADDING_TOP = 8
const ITEM_HEIGHT = 48

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

const makeNArray = (n) => Array.from({ length: n }, (_, i) => i + 1)

const makeNYearArrayFromNow = () => {
    const currentYear = new Date().getFullYear()
    const lastestYear = 1900
    const n = currentYear - lastestYear + 1
    return Array.from({ length: n }, (_, i) => currentYear - i)
}
