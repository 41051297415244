import { Route, Routes } from 'react-router-dom'

import { DarkTheme } from './components/UI/Theme.js'
import Error404 from './pages/Error404/index.jsx'
import LayoutDefault from './components/Layouts/LayoutDefault/LayoutDefault.jsx'
import PrivateRoute from './components/PrivateRoute.jsx'
import PublicRoute from './components/PublicRoute.jsx'
import React from 'react'
import routes from './pages/routes'

const makeComponent = (page, layout, isPrivate = false, theme) => {
    const Page = page
    const Layout = layout
    return (
        <React.Suspense fallback={<>Loading...</>}>
            {isPrivate ? (
                <PrivateRoute>
                    <Layout theme={theme}>
                        <Page />
                    </Layout>
                </PrivateRoute>
            ) : (
                <PublicRoute>
                    <Layout theme={theme}>
                        <Page />
                    </Layout>
                </PublicRoute>
            )}
        </React.Suspense>
    )
}

const recursiveRoutes = ({ routes }) => {
    if (!Array.isArray(routes)) {
        const layout = routes.layout || LayoutDefault
        const theme = routes.theme || DarkTheme
        return (
            <Route
                key={routes.key}
                path={routes.path}
                element={makeComponent(
                    routes.component,
                    layout,
                    routes.isPrivate,
                    theme
                )}
            />
        )
    }
    return routes.map(
        ({
            key,
            component: Component,
            path,
            layout,
            otherRoutes,
            isPrivate,
            theme,
        }) => {
            const layoutPage = layout || LayoutDefault
            const themePage = theme || DarkTheme
            return (
                <Route
                    key={key}
                    path={path}
                    element={makeComponent(
                        Component,
                        layoutPage,
                        isPrivate,
                        themePage
                    )}
                >
                    {recursiveRoutes({ routes: otherRoutes })}
                </Route>
            )
        }
    )
}

const AppRoutes = () => {
    return (
        <Routes>
            {recursiveRoutes({ routes })}
            <Route path='*' element={<Error404 />} />
        </Routes>
    )
}

export default AppRoutes
