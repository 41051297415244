// react import
import { useContext, useEffect, useState } from 'react'



// mui import
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TablePagination from '@mui/material/TablePagination'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Modal from '@mui/material/Modal'

// mui icon import
import IconButton from '@mui/material/IconButton'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

// import Component
import UserCard from './UserCard'
import Context from '../store/Context'
import Loading from '../../../components/Loading'



//api import 

import { getUserList } from '../../../api/user.api'

const COLUMNS_TABLE = [
    { key: '1', value: 'Tên đăng nhập', alias: 'username' },
    { key: '2', value: 'Tên đầy đủ', alias: 'fullname' },
    { key: '3', value: 'Email', alias: 'email' },
    { key: '4', value: 'Sđt', alias: 'phoneNumber' },
]

export default function UserList() {

    const [loading, setLoading] = useState(true)

    const [rows, setRows] = useState([])

    useEffect(() => { 
        getUserList().then((response)=>{
            const data = response.data
            setRows(data)
            setLoading(false)
        })
        return () => {
            console.log('This will be logged on unmount');
        };

    }, [])
    
    


    

    if (loading) {
        return <Loading />
    }
    return (
        <Grid container pl={5} pr={5} spacing={3} pt={10}>
            <Grid item xs={12}>
                <TableContainer sx={{ maxHeight: '60vh', overflowY: 'auto',background: 'rgba(0,0,0,0.5)', }}>
                    <Table aria-label='collapsible table' stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell align='center'>STT</TableCell>
                                {COLUMNS_TABLE.map((row) => (
                                    <TableCell align='center' key={row.key}>
                                        {row.value}
                                    </TableCell>
                                ))}
                                <TableCell align='center'>Trạng thái</TableCell>
                                <TableCell align='center'></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row, index) => (
                                <UserCard
                                    key={row.index}
                                    row={row}
                                    index={index}
                                    cols={COLUMNS_TABLE.map((col) => col.alias)}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    )
}
