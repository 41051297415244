import BaseCard from './Base'
import {
    Box,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material'
import React, { useState, useEffect } from 'react'
import CalculateFullname from '../../../helpers/calculateFullname'
import { transposeMatrix } from '../../../helpers/matrixSupport'
import MdAttr from './MdAttr'
import { useSelector } from 'react-redux'
import luangiaiActions from '../../../redux/actions/luangiai.action'

const TableFullname = (datasetCharacter) => {
    const matrixFullname = []
    datasetCharacter.forEach((dataset) => {
        const { character, isUp, number } = dataset
        if (isUp) {
            matrixFullname.push([number, character, ''])
        } else {
            matrixFullname.push(['', character, number])
        }
    })
    const matrix = transposeMatrix(matrixFullname)
    const classNames = ['bd-sm text-sm bottom', 'bd-sm', 'bd-sm text-sm top']
    return (
        <>
            <table className='collapse'>
                <tbody>
                    {matrix?.map((m, i) => {
                        return (
                            <tr>
                                {m.map((n, j) => {
                                    const className = classNames[i]
                                    return (
                                        <td className={className}>
                                            {n.toString().toUpperCase()}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </>
    )
}

const SucManhTenRieng = () => {
    const [matranKytu, setMaTranKytu] = useState([])
    const {
        lg_sobieudat,
        lg_sotenrieng,
        lg_solinhhon,
        query: state,
    } = useSelector((s) => s.luangiai)

    useEffect(() => {
        const calculateFullname = new CalculateFullname(state.fullname)
        setMaTranKytu(calculateFullname.dataSetFullname)
    }, [state])
    const rows = [
        ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'],
        ['J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R'],
        ['S', 'T', 'V', 'U', 'W', 'X', 'Y', 'Z', ''],
    ]
    return (
        <BaseCard
            title='Sức mạnh của tên riêng'
            des='Tên riêng là âm thanh quan trọng nhất đối với mỗi người, cho dù là tên chính thức, tên ở nhà hay biệt hiệu hoặc bất kỳ danh xưng nào khác mà chúng ta thường sử dụng. Tên riêng là 1 phần bổ sung quan trọng của tính cách và cách biểu đạt của bản thân.'
            helperText='Đăng nhập để xem đầy đủ sức mạnh tên của bạn'
            hashLink='suc-manh-ten-rieng'
            id='suc-manh-ten-rieng'
            condition={
                lg_sobieudat?.length > 0 ||
                lg_sotenrieng?.length > 0 ||
                lg_solinhhon?.length > 0
            }
            actions={luangiaiActions.chitietSMTenRieng}
            state={state}
        >
            <Stack spacing={4} name='hinhve' flexWrap='wrap'>
                {TableFullname(matranKytu)}
                <TableContainer component={Paper}>
                    <Table
                        size='small'
                        className='primary-table'
                        aria-label='simple table'
                    >
                        <TableHead>
                            <TableRow>
                                {Array.from({ length: 9 }, (_, i) => i + 1).map(
                                    (i) => (
                                        <TableCell align='center'>
                                            <Typography
                                                color='red'
                                                sx={{
                                                    fontWeight: 600,
                                                }}
                                            >
                                                {i}
                                            </Typography>
                                        </TableCell>
                                    )
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow
                                    key={row.name}
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    {row.map((column) => (
                                        <TableCell align='center'>
                                            {state.fullname
                                                .toUpperCase()
                                                .includes(column) ? (
                                                <Typography
                                                    color='primary'
                                                    fontWeight='bold'
                                                >
                                                    {column}
                                                </Typography>
                                            ) : (
                                                <Typography>
                                                    {column}
                                                </Typography>
                                            )}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Stack>
            <Box name='luangiai' mt={4}>
                <MdAttr luangiai={lg_sobieudat} />
                <MdAttr luangiai={lg_solinhhon} />
                <MdAttr luangiai={lg_sotenrieng} />
            </Box>
        </BaseCard>
    )
}

export default SucManhTenRieng
