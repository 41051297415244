import './animate.css'
import './index.css'

import { Box, CssBaseline, ThemeProvider, Toolbar } from '@mui/material'
import React, { useEffect, useState } from 'react'

import AlertCustom from './components/AlertCustom'
import AppModal from './components/Modal/AppModal'
import AppProvider from './AppProvider'
import AppRoutes from './AppRoutes'
import AutoScrollTop from './components/AutoScrollTop'
import { DarkTheme } from './components/UI/Theme'
import Footer from './components/Footer'
import Navbar from './components/Navbar'
import ScrollToTop from './components/ScrollToTop'
import alertActions from './redux/actions/alert.action'
import { useDispatch } from 'react-redux'
import { useRef } from 'react'

function App() {
    const dispatch = useDispatch()
    const topRef = useRef(null)
    const [loading, setLoading] = useState(true)
    const [isConnectInternet, setIsConnectInternet] = useState(true)

    useEffect(() => {
        setLoading(false)
    }, [])

    useEffect(() => {
        window.ononline = () => setIsConnectInternet(true)
        window.onoffline = () => setIsConnectInternet(false)
    }, [])

    if (!isConnectInternet) {
        dispatch(
            alertActions.error(
                'Đã xảy ra lỗi kêt nối, vui lòng kiểm tra lại đường truyền.'
            )
        )
    }

    return (
        <>
            <AppProvider>
                {/* <AlertCustom />
                <ThemeProvider theme={DarkTheme}>
                    <CssBaseline />
                    <AutoScrollTop />
                    <Box id='image-background'>
                        <Navbar />
                        <Toolbar id='back-to-top-anchor' ref={topRef} />
                        <section id='section'>
                            <div className='home-wrapper'>
                                {!loading && <AppRoutes />}
                            </div>
                        </section>
                        <ScrollToTop topRef={topRef} />
                    </Box>
                    <AppModal />
                    <Footer />
                </ThemeProvider> */}
                <AppRoutes />
            </AppProvider>
        </>
    )
}

export default App
