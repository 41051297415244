import { PATH_PAGE } from '../../config/constant'
import { lazy } from 'react'

const route = {
    key: 'human-arithmetic-result',
    path: PATH_PAGE.human_arithmetic_result,
    exact: true,
    component: lazy(() => import('.')),
    otherRoutes: [],
}

export default route
