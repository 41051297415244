import DinhCaoDoiNguoiImg from '../../../assets/images/dinh-cao-doi-nguoi.png'
import React, { useEffect, useState } from 'react'
import { calPyramid } from '../../../helpers/calculateHumanArithmetic'
import BaseCard from './Base'
import MdAttr from './MdAttr'
import { Grid } from '@mui/material'
import { useSelector } from 'react-redux'
import luangiaiActions from '../../../redux/actions/luangiai.action'

const DinhCaoDoiNguoi = () => {
    const [arrLuangiaiKTT, setArrLuangiaiKTT] = useState([])
    const {
        lg_sodinhcao,
        num_sochudao,
        query: state,
    } = useSelector((s) => s.luangiai)
    const [arrNumVertex, setArrNumVertex] = useState([])
    useEffect(() => {
        // const date = new Date(state.dob.replace(/-/g, '/'))
        const date = new Date(state.dob)
        const cacdinhs = calPyramid(date)
        const data = []
        for (let i = 0; i < 4; i++) {
            const age =
                36 -
                (num_sochudao === 11
                    ? 2
                    : num_sochudao === 22
                    ? 4
                    : num_sochudao) +
                i * 9
            const year = date.getFullYear() + age
            data.push({
                age,
                year,
                number: cacdinhs[i + 3],
                data: lg_sodinhcao[i] ?? {},
            })
        }
        setArrLuangiaiKTT(data)
        setArrNumVertex(cacdinhs)
    }, [lg_sodinhcao, num_sochudao, state])

    return (
        <BaseCard
            title='Bốn đỉnh cao đời người'
            des='Theo <strong>Pythagoras</strong>, 4 đỉnh kim tự tháp Thần số học là sự đại diện cho cuộc sống của một người trưởng thành. Mỗi đời người gồm có 3 giai đoạn chính (mỗi giai đoạn kéo dài 3 năm và tổng cộng là 27 năm): Giai đoạn thiếu niên – Trưởng thành – Viên mãn.'
            helperText='Đăng nhập để xem đầy đủ các đỉnh cảo của bạn'
            hashLink='dinh-cao-doi-nguoi'
            id='dinh-cao-doi-nguoi'
            condition={lg_sodinhcao?.length > 0}
            actions={luangiaiActions.chitietDinhCaoDoiNguoi}
            state={state}
        >
            <Grid
                container
                name='hinhve'
                sx={{
                    position: 'relative',
                    maxWidth: 300,
                    margin: '16px auto',
                }}
            >
                <img
                    alt='dinh-cao-doi-nguoi'
                    src={DinhCaoDoiNguoiImg}
                    loading='lazy'
                    width={300}
                    height={180}
                    style={{
                        display: 'block',
                        textAlign: 'center',
                    }}
                ></img>
                {arrNumVertex.map((number, i) => {
                    const no = i + 1
                    return (
                        <div
                            key={'so-dinh-cao-' + no}
                            className={'so-dinh-cao' + (no > 3 ? ' pulse' : '')}
                            id={`no-${no}`}
                        >
                            {number}
                        </div>
                    )
                })}
            </Grid>
            <Grid item name='luangiai'>
                {arrLuangiaiKTT.map(({ age, year, number }, i) => {
                    const sodinhcao = lg_sodinhcao.find(
                        (e) => +e.number === +number
                    )
                    const vnName = `Đỉnh ${i + 1} - ${
                        sodinhcao.vnName
                    } (${age} tuổi / năm ${year})`
                    const data = { ...sodinhcao, vnName }
                    return <MdAttr luangiai={[data]} />
                })}
                {/* <MdAttr luangiai={lg_sodinhcao} /> */}
                {/* {arrLuangiaiKTT.map(({ age, year, number, data }, i) => {
                    const mdProps = Object.keys(data).filter((f) =>
                        f.startsWith('md_')
                    )
                    return (
                        <>
                            <Typography
                                gutterBottom
                                variant='h5'
                                component='h3'
                                color='primary'
                                sx={{
                                    position: 'relative',
                                    marginTop: '1.5em',
                                    marginBottom: '16px',
                                    fontWeight: '700',
                                    lineHeight: '1.4',
                                }}
                            >
                                Đỉnh {i + 1} - Số {number} ({age} tuổi / năm{' '}
                                {year})
                            </Typography>
                            {mdProps?.map((f) => {
                                const value = data[f]
                                return (
                                    value && (
                                        <MarkDownComponent
                                            name={f}
                                            children={value}
                                        />
                                    )
                                )
                            })}
                        </>
                    )
                })} */}
            </Grid>
        </BaseCard>
    )
}

export default DinhCaoDoiNguoi
