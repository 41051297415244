import { Typography } from '@mui/material'
import React from 'react'
import { MainContainer } from '../../components/UI'

/**
 * @description Trang Lịch vạn niên
 * @returns
 */
const CalendarPage = () => {
    return (
        <MainContainer>
            {/* <DrawerHeader /> */}
            <Typography paragraph>This is CalendarPage</Typography>
        </MainContainer>
    )
}

export default CalendarPage
