import Context from "./Context"
import { useState } from "react"

export default function Provider({ children }) {
    const [search, setSearch] = useState('')
    const [type, setType] = useState('')
    const value = {
        search: [search, setSearch],
        type: [type, setType]
    }
    return (
        <Context.Provider value={value}>
            {children}
        </Context.Provider>
    )
}