//react import
import { useState, useEffect } from 'react';


//mui material import
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Divider from '@mui/material/Divider';


//icons material import
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import EmailIcon from '@mui/icons-material/Email';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import PhoneIcon from '@mui/icons-material/Phone';
import DateRangeIcon from '@mui/icons-material/DateRange';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import BuildIcon from '@mui/icons-material/Build';
import LocalPlayIcon from '@mui/icons-material/LocalPlay';

//Component import
import Context from '../store/Context';
import UserModal from './UserModal';






export default function UserCard(props) {
    const { row, index, cols } = props;
    const [open, setOpen] = useState(false);


    const [openModal, setOpenModal] = useState(false)


    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);
    return (
        <>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} >
                <TableCell align="center">{index + 1}</TableCell>
                {cols.map((col, index) => (
                    <TableCell align="center" >
                        {row[col]}
                    </TableCell>
                ))}
                <TableCell align="center">
                    {row.isBlocked ? <span style={{ color: '#F44336' }}>Bị khóa</span> : (row.isVerified ? <span style={{ color: '#66BB6A' }}>Đã xác minh</span> : 'Chưa xác minh')}
                </TableCell>
                <TableCell align="center">
                    {/* <Button variant="outlined"
                        onClick={(e) => {
                            e.preventDefault()
                            setOpen(!open)
                        }
                        }
                    >
                        <IconButton
                            aria-label="expand row"
                            size="small"

                            sx={{ padding: '0' }}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </Button> */}

                    <Button variant="outlined" color="success" onClick={handleOpenModal}>
                        Chi tiết
                    </Button>
                    <Button variant="outlined" color="error">
                        Xóa
                    </Button>
                </TableCell>
            </TableRow>
            {/* <TableRow>
                <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                Chi tiết
                            </Typography>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">GoogleID</TableCell>
                                        <TableCell align="center">FacebookID</TableCell>
                                        <TableCell align="center">TwitterID</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="center">{row.googleId != '' ? row.googleId : 'Chưa liên kết'}</TableCell>
                                        <TableCell align="center">{row.facebookId != '' ? row.facebookId : 'Chưa liên kết'}</TableCell>
                                        <TableCell align="center">{row.twitterId != '' ? row.twitterId : 'Chưa liên kết'}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow> */}
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                gridItem
            >
                <UserModal row={row} />
            </Modal>
        </>
    );
}