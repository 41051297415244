import { Box, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import HashLinkCustom from '../../../../components/HashLink'
import { Document, Page, pdfjs } from 'react-pdf'
import './style.css'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

const PdfPreview = () => {
    const location = useLocation()
    const [numPages, setNumPages] = useState(null)
    const { pathname, search } = location
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages)
    }
    return (
        <>
            <Box px={2} py={1} display='flex' rowGap={1} flexDirection='column'>
                <div>
                    <HashLinkCustom
                        id='bao-cao-than-so'
                        to={`${pathname}${search}#bao-cao-than-so`}
                        title='Bản báo cáo thần số'
                        gutterBottom
                        variant='h3'
                        color='primary'
                        component='h2'
                        className='title-divider'
                    />
                    <Box mt={2}>
                        <Typography
                            variant='body1'
                            sx={{ fontStyle: 'italic' }}
                        >
                            Bản báo cáo thần số này là 1 quyển sách được biên
                            soạn cho cuộc đời của bạn, tổng cộng hơn 50 trang và
                            hàng chục biểu đồ. Những bí ẩn về con người, số mệnh
                            và tính cách của bạn sẽ được giải đáp và làm rõ. Để
                            có thể đặt mua báo cáo này, bạn đọc xin vui lòng
                            đăng nhập.
                        </Typography>
                        <Box mt={2} name='pdf-preview'>
                            <div className='pdf-toolbar'>
                                <Typography variant='h6'>
                                    BẢN XEM TRƯỚC BÁO CÁO THẦN SỐ HỌC CỦA BẠN
                                </Typography>
                            </div>
                            <div className='pdf-container'>
                                <Document
                                    file={`${process.env.REACT_APP_API_URL}/file/pdf/pdf-preview`}
                                    onLoadSuccess={onDocumentLoadSuccess}
                                >
                                    {numPages &&
                                        Array.apply(null, {
                                            length: numPages,
                                        }).map((_, index) => (
                                            <Page
                                                key={'pdf-' + index}
                                                pageNumber={index + 1}
                                                renderAnnotationLayer={false}
                                                renderTextLayer={false}
                                                renderMode='canvas'
                                                loading='Loading...'
                                                className='pdf-viewport'
                                            />
                                        ))}
                                </Document>
                                <p>Page {numPages}</p>
                            </div>
                        </Box>
                    </Box>
                </div>
            </Box>
        </>
    )
}

export default PdfPreview
