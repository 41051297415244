const data = {
    0: 'Bạn có “Con số vua” chỉ chiếm khoảng 1 - 2% dân số thế giới. Tiềm năng gần như vô hạn và thường đạt hầu hết những mục đích cỏ vẻ bất khả thi. Thích học hỏi và tốc độ tiếp nhận kiến thức của bạn nhanh đáng ngạc nhiên, như thể bạn đã lĩnh hội gần như mọi điều từ kiếp sống trước, và giờ đây chỉ đang làm quen lại với cách biểu đạt của kiếp sống hiện tại. Bạn cần thận trọng để không trở nên tàn nhẫn trên hành trình hoàn thành sứ mệnh của mình.',
    2: 'Là người nhạy cảm, có trực giác tốt, có tinh thần hỗ trợ, đáng tin cậy, có khả năng hòa giải, giàu lòng trắc ẩn và có tính nghệ sĩ. Chính do có yếu tố nghệ sĩ, nên đôi khi bạn không thể chịu được áp lực khi làm việc cường độ cao và nhiều sức ép. Bạn sẽ phát huy tốt khả năng của mình với các lĩnh vực như hội họa, viết lách nhưng phải đặt dưới sự dẫn dắt của trực giác chuẩn xác mà bạn có.',
    3: 'Có đầu óc nhanh nhạy, giỏi đánh giá, suy nghĩ và lên kế hoạch. Bạn cũng có đầu óc nhanh nhạy, tính hài hước và tư duy linh hoạt, điều này khiến bạn dễ dàng thành công trong công việc. Bạn là cây hài trong mỗi buổi tiệc hoặc là người sáng giá trong công ty. Tuy nhiên những ưu điểm này không phải lúc nào cũng được thể hiện khi bạn ở nhà. Bạn có thể thoải mái thu hút các tương tác xã hội, nhưng với những người có sự gắn bó lâu dàu thì bạn lại thường phê bình gay gắt, nói lời khó nghe. Điều này có thể khiến những người thân của bạn cảm thấy mệt mỏi, đặc biệt là người bạn đời.',
    4: 'Bạn có thiên hướng về sự "thực tế" tức là bạn thích bắt tay vào việc hơn là ngồi bàn luận về các giá trị mà việc đó mang lại. Chính sự "thực tế" này giúp bạn luôn luôn tiến về phía trước. Bạn thuộc nhóm người có nguyên tắc rõ ràng và rất đáng tin cậy. Điều này thể hiện rõ nhất qua việc bạn rất tỉ mỉ, kiên nhẫn và chính xác với công việc của mình. Tuy nhiên khi gặp các vấn đề liên quan tới yếu tố tâm linh, tinh thần thì bạn lại rất thiếu kiên nhẫn. Ngoài ra do việc hết mình với công việc, bạn rất dễ bị các vấn đề về tâm lý như stress, dễ mất cân bằng cuộc sống, đặc biệt là cuộc sống gia đình.',
    5: 'Thuộc nhóm “cảm nhận” khi cảm xúc của bạn có thể lên xuống thất thường khi có sự tác động nhỏ từ bên ngoài. Yêu tự do, thích phiêu lưu, giàu lòng yêu thương nhưng lại không chịu đựng được khi đặt trong khuôn khổ. Đặc trưng của bạn là: yêu tự do, thích diễn xuất và có khiếu nghệ thuật.',
    6: 'Có đầu óc sáng tạo, thích quan tâm, công bằng, không ích kỷ, bao dung và yêu gia đình, nhưng hay có khuynh hướng bị lo lắng và bất an thái quá.',
    7: 'Bạn có một khả năng học tập gần như vô tận, tuy nhiên bạn lại học hỏi theo cách riêng của mình, đặc biệt là sự khao khát được học hỏi bằng cách tự trải nghiệm. Chính vì điều này, bạn thường phải hy sinh ít nhất một trong 3 khía cạnh của cuộc sống: sức khỏe, tình yêu và tiền tài. Những khó khăn, tổn thất và thử thách mà bạn phải đối mặt sẽ liên tục lặp lại với mức độ ngày trầm trọng hơn nếu bạn không nhận ra và khắc phục được nó. Tuy nhiên khi đã hiểu và khắc phục được, bạn sẽ dần trở thành người chai sạn, có vốn sống và trải nghiệm sâu sắc để truyền tải lại cho người khác và giúp ích cho đời. Bạn rất thích hợp các hoạt động liên quan đến ngành luật hoặc giảng dạy.',
    8: 'Độc lập là điểm nổi bật nhất của bạn, và bạn cực kỳ đáng tin cậy nhưng ít chịu bày tỏ cảm xúc của mình. Tuy nhiên về nội tâm vẫn rất quan tâm đến những người kém may mắn hoặc khó khăn trong cuộc sống.',
    9: 'Có tinh thần trách nhiệm có lý tưởng và luôn hướng về yếu tố nhân văn. Trong cuộc sống bạn rất nghiêm túc và không giỏi nhìn người cho lắm. Bạn cũng không giỏi quản lý tiền bạc, và cũng khó tiết kiệm được tiền.',
    10: 'Có thể hoạt động trong nhiều lĩnh vực khác nhau. Bạn tự tin, hoàn nhã và thông minh nên dễ đạt được thành công. Nhưng khi cái tôi quá lớn, quá tự tin thì bạn lại đi quá xa dẫn đến nhiều phiền phức trong cuộc sống.',
    11: 'Bạn nhạy cảm, đầy cảm xúc và quan tâm người khác, trung thực và giàu lòng trắc ẩn, yêu vẻ đẹp gắn liền với các giá trị văn hóa. Bạn không có thế mạnh trong kinh doanh cũng như không quản lý tiền bạc giỏi.',
    22: 'Bạn có con số vua, bạn là nhất.',
}

export default data
