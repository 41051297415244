import authActions from '../redux/actions/auth.action'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'

const PublicRoute = ({ children }) => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(authActions.whoAmI())
    }, [dispatch])
    return children
}

export default PublicRoute
