import { useDispatch, useSelector } from 'react-redux'

import React from 'react'
import TabModalComponent from '../SignIn&SignUp'
import modalActions from '../../redux/actions/modal.action'
import { modalTabSelector } from '../../redux/selector/modal.selector'

const AppModal = () => {
    const dispatch = useDispatch()
    const tabModal = useSelector(modalTabSelector)
    return (
        <>
            <TabModalComponent
                open={tabModal}
                handleClose={() => dispatch(modalActions.closeTabModal())}
            />
        </>
    )
}

export default AppModal
