import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

function AutoScrollTop() {
    const pathName = useLocation()
    useEffect(() => {
        window.scrollTo({ top: 0 })
    }, [pathName])
}
export default AutoScrollTop
