import React from 'react'
import Container from '@mui/material/Container'
import { Box, Grid } from '@mui/material'
import NhanSoApp from '../../../assets/images/nhan-so-app.jpg'
import FormTraCuu from '../../../components/FormTraCuu'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const Form = ({ formRef }) => {
    return (
        <section
            id='form'
            ref={formRef}
            className='form-area'
            style={{
                marginTop: '6rem',
                marginBottom: '7rem',
            }}
        >
            <Container>
                <Grid container>
                    <Grid item lg={6}>
                        <Box className='form-image wow fadeInLeftBig' mt={5}>
                            <div className='form-shape'></div>
                            <LazyLoadImage
                                alt='app'
                                src={NhanSoApp}
                                className='app'
                            />
                        </Box>
                    </Grid>
                    <Grid item lg={6}>
                        <Box
                            className='form-content wow fadeInRightBig'
                            data-wow-duration='1.3s'
                            data-wow-delay='0.5s'
                            mt={5}
                            display='flex'
                            flexDirection='column'
                            alignItems='center'
                        >
                            <div className='section-title'>
                                <h3 className='title' style={{ color: '#000' }}>
                                    Công cụ tra cứu thần số học
                                </h3>
                                <p className='text' style={{ color: '#000' }}>
                                    Công cụ được phát triển bởi hệ thống dữ liệu
                                    phong phú về Nhân số học hàng đầu tại Việt
                                    Nam, kèm với đó là đội ngũ kỹ sư giàu kinh
                                    nghiệm. Hệ thống có thể xử lý hàng triệu kết
                                    quả chỉ trong vài giây với tỷ lệ chính xác
                                    lên tới 80%.
                                </p>
                            </div>
                            <div style={{ padding: '30px 0' }}>
                                <FormTraCuu />
                            </div>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </section>
    )
}

export default Form
