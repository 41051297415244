import { Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import DownloadIcon from '@mui/icons-material/Download'

const InstallPWA = () => {
    const [supportPWA, setSupportPWA] = useState(false)
    const [promptInstall, setPromptInstall] = useState(null)

    useEffect(() => {
        const handler = (e) => {
            e.preventDefault()
            setSupportPWA(true)
            setPromptInstall(e)
        }
        window.addEventListener('beforeinstallprompt', handler)
        return () => window.removeEventListener('transitionend', handler)
    }, [])

    const onClick = (e) => {
        e.preventDefault()
        if (!promptInstall) {
            return
        }
        promptInstall.prompt()
    }
    if (!supportPWA) {
        return null
    }

    return (
        <Button
            variant='contained'
            color='success'
            size='small'
            sx={{
                color: '#fff',
            }}
            id='setup_button'
            aria-label='Install app'
            title='Install app'
            onClick={onClick}
            startIcon={<DownloadIcon />}
        >
            Tải xuống
        </Button>
    )
}

export default InstallPWA
