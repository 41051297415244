import './style.css'
import { Box, IconButton, Tooltip } from '@mui/material'
import React from 'react'
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded'
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'
import { FacebookShareButton } from 'react-share'

const Action = ({ copy }) => {
    return (
        <>
            <Box className='votes'></Box>
            <Box className='subcrible' mb={2}>
                <div onClick={copy}>
                    <Tooltip title='Sao chép đường dẫn' placement='left-start'>
                        <IconButton size='large' type='button' color='default'>
                            <BookmarkBorderIcon sx={{ fontSize: '35px' }} />
                        </IconButton>
                    </Tooltip>
                </div>
            </Box>
            <Box className='social-sharing'>
                <FacebookShareButton url={window.location.href}>
                    <Tooltip
                        title='Chia sẻ kết quả lên Facebook'
                        placement='left-start'
                    >
                        <FacebookRoundedIcon
                            sx={{ fontSize: '25px', color: '#fff' }}
                        />
                    </Tooltip>
                </FacebookShareButton>
                {/* <Link to='/'>
                    <Tooltip
                        title='Chia sẻ kết quả lên Twitter'
                        placement='left-start'
                    >
                        <TwitterIcon sx={{ fontSize: '25px', color: '#fff' }} />
                    </Tooltip>
                </Link> */}
                {/* <Link to='/'>
                    <Tooltip
                        title='Số lượng người bình luận'
                        placement='left-start'
                    >
                        <Badge badgeContent={4} color='primary'>
                            <ChatBubbleOutlineOutlinedIcon
                                color='action'
                                sx={{ fontSize: '25px', color: '#fff' }}
                            />
                        </Badge>
                    </Tooltip>
                </Link> */}
            </Box>
        </>
    )
}

export default Action
