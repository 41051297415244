import { createTheme } from '@mui/material/styles'

const DarkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#B4975D',
        },
        error: {
            main: '#c73d05',
        },
        success: {
            main: '#2e7d32',
        },
        background: {
            default: '#101010',
            paper: '#0d1117',
        },
        text: {
            primary: '#c9d1d9',
            secondary: '#8b949e',
        },
    },
    typography: {
        fontSize: 13,
        lineHeight: 1.7,
        fontFamily: [
            '-apple-system',
            'Poppins',
            '"Segoe UI"',
            'Open Sans',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        h1: {
            fontSize: '48px',
        },
        h2: {
            fontSize: '36px',
        },
        h3: {
            fontSize: '28px',
        },
        h4: {
            fontSize: '22px',
        },
        h5: {
            fontSize: '18px',
        },
        h6: {
            fontSize: '16px',
        },
    },
})

const LightTheme = createTheme({
    palette: {
        mode: 'light',
    },
    typography: {
        fontSize: 13,
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Open Sans',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
})

export { DarkTheme, LightTheme }
