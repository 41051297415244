import React, { useContext, useEffect } from 'react'

import AppContext from '../../context/AppContext'
import { MainContainer } from '../../components/UI'

function Error404Page() {
    const { footer } = useContext(AppContext)
    const [, setShowFooter] = footer

    useEffect(() => {
        document.title = 'Nhân Số Đại Việt | 404'
        const description = document.querySelector('meta[name="description"]')
        if (description) {
            description.setAttribute(
                'content',
                'Rất tiếc, Chúng tôi không tìm thấy trang web mà bạn muốn truy cập. Có lẽ bạn đã truy cập một đường dẫn không chính xác? Hãy kiểm tra lại đường dẫn truy cập của bạn.'
            )
        }
        setShowFooter(false)
    }, [setShowFooter])

    return (
        <MainContainer>
            <Error404Page />
        </MainContainer>
    )
}

export default Error404Page
