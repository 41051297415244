import { Grid, Grow, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import Catalogue from './Catalogue'

const Catalogues = ({ isLoading, ...catalogues }) => {
    let { title, subCatalogues } = catalogues
    if (!subCatalogues || subCatalogues.length === 0) {
        subCatalogues = new Array(8).fill({
            title: '',
            slug: '',
            img: '',
            shortDescription: '',
            description: '',
        })
    }

    return (
        <>
            <Box mt={5}>
                <Box
                    display='flex'
                    justifyContent='space-between'
                    alignItems='center'
                >
                    <Typography
                        variant='h5'
                        sx={{ fontWeight: 'bold' }}
                        gutterBottom
                    >
                        {title}
                    </Typography>
                    {/* <Button size='small' color='primary' variant='outlined'>
                        Đọc hết
                    </Button> */}
                </Box>
                <Grow in>
                    <Grid
                        container
                        spacing={3}
                        display='flex'
                        alignItems='stretch'
                    >
                        {subCatalogues?.map((item, i) => (
                            <Grid key={i} item md={3} xs={12} sm={6}>
                                <Catalogue isLoading={isLoading} {...item} />
                            </Grid>
                        ))}
                    </Grid>
                </Grow>
            </Box>
        </>
    )
}

export default Catalogues
