import './style.css'

import {
    Box,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'

import { APP_NAME } from '../../config/key'
import BackgroundCould from '../../components/BackgroundCould'
import CircularProgress from '@mui/material/CircularProgress'
import Divider from '@mui/material/Divider'
import { MainContainer } from '../../components/UI'
import Package from '../../components/Package/Package'
import { getAllProducts } from '../../api/product.api'
import { useSearchParams } from 'react-router-dom'

const Shopping = () => {
    const [products, setProducts] = useState(null)
    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {
        const getProducts = async () => {
            const { data } = await getAllProducts()
            setProducts(data)
        }
        getProducts()
    }, [])

    useEffect(() => {
        document.title = 'Mua sắm | ' + APP_NAME
    }, [])

    const onSearchPackages = (e) => {
        e.preventDefault()
        setSearchParams({
            s: e.target.value.trim().replace(/\s/g, ' '),
        })
    }

    return (
        <BackgroundCould>
            <MainContainer>
                <div className='nav-ribbon-wrapper'>
                    <nav
                        className='nav nav-ribbon'
                        aria-label='navigation ribbon'
                    >
                        <ul className='nav__items list-unstyled' role='menubar'>
                            <li className='nav__li'>Nhân Số</li>
                            <li className='nav__li'>Tra cứu</li>
                            <li className='nav__li'>Lý thuyết</li>
                            <li className='nav__li'>Blog</li>
                            <li className='nav__li'>Dịch vụ</li>
                            <li className='nav__li'>Mua sắm</li>
                        </ul>
                    </nav>
                </div>
                <div
                    className='ads'
                    style={{
                        height: '250px',
                    }}
                ></div>
                <div style={{ paddingTop: '2em' }}>
                    <header className='shopping-header'>
                        <Typography
                            variant='h3'
                            component='h1'
                            color='primary'
                            gutterBottom
                            marginBottom='2rem'
                        >
                            Dịch vụ Nhân Số học
                        </Typography>
                        <div className='shopping-header__description'>
                            <Typography
                                variant='body1'
                                component='p'
                                color='text.primary'
                            >
                                Chào mừng bạn đến với NhanSoDaiViet.vn Shop! Hãy
                                hoàn thành khám phá những bí mật sâu bên trong
                                con người bạn bằng các biểu đồ, lời khuyên, định
                                hướng, và nhiều hơn thế. Hãy để chúng tôi mở
                                khóa định mệnh của bạn.
                            </Typography>
                        </div>
                    </header>
                    <div className='shopping-searching'>
                        <div className='shopping-searching__wrapper'>
                            <div className='shopping-searching__wrapper__search'>
                                <Box
                                    component='form'
                                    justifyContent='space-between'
                                    gap={15}
                                    display='flex'
                                    alignItems='end'
                                >
                                    <FormControl
                                        sx={{
                                            width: '100%',
                                            maxWidth: 420,
                                        }}
                                    >
                                        <TextField
                                            name='s'
                                            label='Tìm sản phẩm'
                                            variant='standard'
                                            onChange={onSearchPackages}
                                        />
                                    </FormControl>
                                    <FormControl
                                        variant='standard'
                                        sx={{ minWidth: 120 }}
                                    >
                                        <InputLabel id='demo-simple-select-standard-label'>
                                            Sắp xếp
                                        </InputLabel>
                                        <Select
                                            labelId='demo-simple-select-standard-label'
                                            id='demo-simple-select-standard'
                                            value={''}
                                            onChange={() => {}}
                                            label='Sắp xếp'
                                        >
                                            <MenuItem value=''>
                                                <em>Sắp xếp</em>
                                            </MenuItem>
                                            <MenuItem value={10}>
                                                Rẻ nhất
                                            </MenuItem>
                                            <MenuItem value={20}>
                                                Mới nhất
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </div>
                            <div className='shopping-searching__wrapper__category'></div>
                            <div className='shopping-searching__wrapper__sort'></div>
                        </div>
                    </div>
                    <Box className='shopping-products'>
                        {!products ? (
                            <CircularProgress />
                        ) : (
                            products.map(({ category, packages }) => {
                                return (
                                    <div style={{ marginTop: '1em' }}>
                                        <Divider
                                            textAlign='left'
                                            marginBottom='1.2em'
                                        >
                                            <Typography
                                                gutterBottom
                                                variant='h6'
                                                component='div'
                                            >
                                                {category
                                                    ? category.name
                                                    : 'Khác'}
                                            </Typography>
                                        </Divider>
                                        <Grid
                                            container
                                            spacing={8}
                                            direction='row'
                                            justifyContent='flex-start'
                                            alignItems='center'
                                        >
                                            {packages.map((_pack, index) => (
                                                <Grid item>
                                                    <Package
                                                        index={
                                                            packages.length %
                                                            (packages.length -
                                                                index)
                                                        }
                                                        idPackage={_pack._id}
                                                        name={_pack.name}
                                                        price={_pack.totalPrice}
                                                        desc={_pack.desc}
                                                    />
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </div>
                                )
                            })
                        )}
                    </Box>
                    <div className='shopping-paginate'></div>
                </div>
            </MainContainer>
        </BackgroundCould>
    )
}

export default Shopping
