import Cookies from 'js-cookie'
import moment from 'moment'
import storageConstant from '../config/constant/storage.constant'

const tokenService = {
    getAccessToken: () => {
        return Cookies.get(storageConstant.ACCESS_TOKEN)
    },
    getRefreshToken: () => {
        return encodeURIComponent(Cookies.get(storageConstant.REFRESH_TOKEN))
    },
    setAccessToken: (token) => {
        let now = moment()
        let expiresAccessToken = moment(now).add(15, 'seconds')
        Cookies.set(storageConstant.ACCESS_TOKEN, token, {
            expires: expiresAccessToken._d,
        })
    },
    setRefreshToken: (token) => {
        Cookies.set(storageConstant.REFRESH_TOKEN, token, { expires: 7 })
    },
    clearToken: () => {
        Object.keys(Cookies.get()).forEach(function (cookieName) {
            Cookies.remove(cookieName)
        })
    },
}

export default tokenService
