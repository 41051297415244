import React, { useState } from 'react'
import AppContext from './context/AppContext'

const AppProvider = (props) => {
    const [showFooter, setShowFooter] = useState(true)
    const value = {
        footer: [showFooter, setShowFooter],
    }
    return <AppContext.Provider value={value} {...props} />
}

export default AppProvider
