import alertConstants from '../../config/constant/alert.constant'

const alertActions = {
    success: (message) => ({
        type: alertConstants.ALERT_SUCCESS,
        payload: message,
    }),
    error: (message) => ({
        type: alertConstants.ALERT_ERROR,
        payload: message || 'Đã xảy ra lỗi ngoài ý muốn. Vui lòng thử lại sau.',
    }),
    info: (message) => ({
        type: alertConstants.ALERT_INFO,
        payload: message,
    }),
    warning: (message) => ({
        type: alertConstants.ALERT_WARNING,
        payload: message,
    }),
    clear: () => ({
        type: alertConstants.ALERT_CLEAR,
    }),
}

export default alertActions
