import luangiaiConstants from '../../config/constant/luangiai.constant'

const luangiai = JSON.parse(localStorage.getItem(window.location.href))

const initialState = luangiai || {
    num_sochudao: 0,
    exp: '',
    query: {
        fullname: '',
        gender: 1,
        dob: '',
        finallname: '',
    },
    lg_tonghop: [],
    lg_tenrieng: [],
    lg_sotenrieng: [],
    lg_sonamcanhan: [],
    lg_solinhhon: [],
    lg_sochudao: [],
    lg_sobieudat: [],
    lg_ngaysinh: [],
    lg_cacsonbdns: [],
    lg_sodinhcao: [],
}

function luangiaiReducer(state = initialState, action) {
    switch (action.type) {
        case luangiaiConstants.TONG_QUAT:
            return {
                ...state,
                ...action.payload,
            }
        case luangiaiConstants.SO_CHU_DAO:
            return {
                ...state,
                lg_sochudao: action.payload,
            }
        case luangiaiConstants.SO_NAM_CA_NHAN:
            return {
                ...state,
                lg_sonamcanhan: action.payload,
            }
        case luangiaiConstants.BIEU_DO_NGAY_SINH:
            return {
                ...state,
                lg_ngaysinh: action.payload,
            }
        case luangiaiConstants.BIEU_DO_TEN_RIENG:
            return {
                ...state,
                lg_ngaysinh: action.payload,
            }
        case luangiaiConstants.BIEU_DO_TONG_HOP:
            return {
                ...state,
                ...action.payload,
            }
        case luangiaiConstants.SUC_MANH_TEN_RIENG:
            return {
                ...state,
                ...action.payload,
            }
        case luangiaiConstants.KIM_TU_THAP:
            return {
                ...state,
                ...action.payload,
            }
        default:
            return state
    }
}

export default luangiaiReducer
