const luangiaiConstants = {
    TONG_QUAT: 'tong-quat',
    SO_CHU_DAO: 'so-chu-dao',
    SO_NAM_CA_NHAN: 'so-nam-ca-nhan',
    BIEU_DO_NGAY_SINH: 'bieu-do-ngay-sinh',
    BIEU_DO_TEN_RIENG: 'bieu-do-ten-rieng',
    BIEU_DO_TONG_HOP: 'bieu-do-tong-hop',
    SUC_MANH_TEN_RIENG: 'suc-manh-ten-rieng',
    KIM_TU_THAP: 'kim-tu-thap',
}

export default luangiaiConstants
