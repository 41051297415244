import { Grid, Paper, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import BaseContent from './Base'
import { Box } from '@mui/system'
import { MatranMuiTenNgaySinh } from '../../../helpers/muiten'
import MatrixArrowTableComponent from './TableArrowComponent'
import MdAttr from './MdAttr'
import alertActions from '../../../redux/actions/alert.action'
import luangiaiActions from '../../../redux/actions/luangiai.action'
import moment from 'moment'

const BieuDoNgaySinh = () => {
    const dispatch = useDispatch()
    const [matranNgaysinh, setMatranNgaysinh] = useState(undefined)
    const { lg_ngaysinh, query: state } = useSelector((s) => s.luangiai)

    useEffect(() => {
        try {
            // const date = new Date(state.dob.replace(/-/g, '/'))
            if (state.dob) {
                const date = new Date(state.dob)
                const matran = new MatranMuiTenNgaySinh(date)
                setMatranNgaysinh(matran.matran)
            }
        } catch (error) {
            dispatch(alertActions.error())
        }
    }, [state, dispatch])

    return (
        <BaseContent
            title='Biểu đồ sức mạnh'
            des=' Đây là biểu đồ sức mạnh, hay có tên gọi khác là biểu đồ
    ngày sinh, biểu đồ này sẽ biểu hiện sức mạnh nguyên thủy
    của bạn. Nó sẽ cho bạn thấy được một cách tổng quát về
    những ưu nhược điểm của bạn thân, sức mạnh của bạn (thể
    chất, tinh thần và trí tuệ). Tóm lại biểu đồ này giống
    như 1 bản đồ mà vũ trụ đã tạo ra cho bạn, bạn có thể tim
    hiểu, đi theo hoặc tìm đường đi khác. Nhưng nếu quyết
    định đi theo, cuộc sống của bạn sẽ cân bằng và tốt đẹp
    hơn rất nhiều'
            hashLink='bieu-do-suc-manh'
            id='bieu-do-suc-manh'
            helperText='Đăng nhập tài khoản để xem đầy đủ sức mạnh của bản thân'
            condition={lg_ngaysinh?.length > 0}
            actions={luangiaiActions.chitietBDNgaySinh}
            state={state}
        >
            <Grid container spacing={2} mt={2}>
                <Grid item sm={12} md={4} xs={12}>
                    <MatrixArrowTableComponent
                        matrixArrow={matranNgaysinh}
                        caption={
                            'Biểu đồ sức mạnh ' +
                            moment(state.dob).format('DD/MM/YYYY')
                        }
                    />
                </Grid>
                <Grid item sm={12} md={8} xs={12}>
                    <Paper
                        className='primary-paper'
                        sx={{ padding: 3, height: '100%' }}
                    >
                        <Typography
                            paragraph
                            sx={{
                                fontWeight: 'bold',
                            }}
                            component='p'
                        >
                            "Hãy nhớ rằng những gì bạn có được thông qua sự có
                            mặt hoặc vắng mặt của những con số trên Biểu đồ ngày
                            sinh chỉ là phần sức mạnh ban đầu, và nó không quan
                            trọng bằng những gì chúng ta có thể làm để điền vào
                            những ô trống."
                        </Typography>
                        <Typography variant='body1' textAlign='right'>
                            David A. Phillips
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>
            <Box name='luangiai' mt={4}>
                <MdAttr luangiai={lg_ngaysinh} />
            </Box>
        </BaseContent>
    )
}

export default BieuDoNgaySinh
