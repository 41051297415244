import { Typography } from '@mui/material'
import React from 'react'
import { MainContainer } from '../../components/UI'

/**
 * @description Trang Tra cứu ngày sinh
 * @returns
 */
const SearchBirthday = () => {
    return (
        <MainContainer>
            {/* <DrawerHeader /> */}
            <Typography paragraph>This is SearchBirthday</Typography>
        </MainContainer>
    )
}

export default SearchBirthday
