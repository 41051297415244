import alertActions from './alert.action'
import authService from '../../services/auth.service'
import axios from '../../axios'
import tokenService from '../../services/token.service'
import userConstants from '../../config/constant/user.constant'

const authActions = {
    whoAmI: () => async (dispatch) => {
        const whoAmISuccess = (user) => ({
            type: userConstants.WHO_AM_I,
            payload: user,
        })
        try {
            const response = await axios({
                url: '/auth/who-am-i',
                withCredentials: true,
                method: 'GET',
            })
            if (response.hasOwnProperty('errors')) {
                localStorage.removeItem('user')
            } else {
                localStorage.setItem('user', JSON.stringify(response.data))
                dispatch(whoAmISuccess(response.data))
            }
        } catch (error) {
            localStorage.removeItem('user')
            tokenService.clearToken()
            return Promise.reject(error)
        }
    },

    login: (username, password) => {
        const loginRequest = (user) => ({
            type: userConstants.LOGIN_REQUEST,
            payload: user,
        })

        const loginSuccess = (user) => ({
            type: userConstants.LOGIN_SUCCESS,
            payload: user,
        })

        const loginFailure = (error) => ({
            type: userConstants.LOGIN_FAILURE,
            payload: error,
        })

        return async (dispatch) => {
            dispatch(loginRequest({ username }))
            return authService
                .handleSignIn(username, password)
                .then((user) => {
                    dispatch(loginSuccess(user))
                    dispatch(alertActions.success('Xin chào ' + user.username))
                    return Promise.resolve()
                })
                .catch((error) => {
                    dispatch(loginFailure(error.errorMessage))
                    dispatch(alertActions.error(error.errorMessage))
                    return Promise.reject(error)
                })
        }
    },
    register: (username, password) => {
        const registerRequest = (user) => ({
            type: userConstants.REGISTER_REQUEST,
            payload: user,
        })

        const registerSuccess = (user) => ({
            type: userConstants.REGISTER_SUCCESS,
            payload: user,
        })

        const registerFailure = (error) => ({
            type: userConstants.REGISTER_FAILURE,
            payload: error,
        })
        return async (dispatch) => {
            dispatch(registerRequest({ username }))
            return authService
                .handleSignUp(username, password)
                .then((user) => {
                    dispatch(registerSuccess(user))
                    dispatch(
                        alertActions.success(
                            'Đăng kí thành công vui lòng đăng nhập để tiếp tục!'
                        )
                    )
                    return Promise.resolve()
                })
                .catch((error) => {
                    dispatch(registerFailure(error.errorMessage))
                    dispatch(alertActions.error(error.errorMessage))
                    return Promise.reject(error)
                })
        }
    },
    logout: function () {
        const logoutRequest = () => ({
            type: userConstants.LOGOUT,
        })
        return async (dispatch) => {
            dispatch(logoutRequest())
            authService.handleSignOut()
            dispatch(alertActions.success('Đã đăng xuất'))
        }
    },
    facebookLogIn: () => {
        const facebookLogInSuccess = (user) => ({
            type: userConstants.REGISTER_SUCCESS,
            payload: user,
        })

        const facebookLogInFailure = (error) => ({
            type: userConstants.REGISTER_FAILURE,
            payload: error,
        })
        return (dispatch) => {
            return authService.handleFacebookSignIn()
        }
    },
}

export default authActions
