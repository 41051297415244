import alertReducer from './alert.reducer'
import authReducer from './auth.reducer'
import { combineReducers } from 'redux'
import luangiaiReducer from './luangiai.reducer'
import modalReducer from './modal.reducer'

const rootReducer = combineReducers({
    auth: authReducer,
    alert: alertReducer,
    luangiai: luangiaiReducer,
    modal: modalReducer,
})

export default rootReducer
