import {
    Alert,
    Box,
    Button,
    Divider,
    FormControl,
    TextField,
    Typography,
} from '@mui/material'

import { PATH_PAGE } from '../../../config/constant'
import React from 'react'
import { formatterMoney } from '../../../helpers/money'
import { useNavigate } from 'react-router-dom'

const Order = ({ order }) => {
    const navigate = useNavigate()
    return (
        <Box className='order'>
            <div className='order__summary'>
                <div className='order__summary__header'>
                    <div className='order__sumary__header__code'>
                        Đơn hàng:
                        <Typography
                            variant='h5'
                            component='div'
                            color='primary'
                            display='inline'
                            ml={4}
                        >
                            {order.orderId}
                        </Typography>
                    </div>
                    <div className='order__sumary__header__status'>
                        {order.isPaid ? (
                            <Alert severity='success'>Đã mua</Alert>
                        ) : (
                            <Alert severity='error'>Chưa mua</Alert>
                        )}
                    </div>
                </div>
                <Divider />
                <div className='order__summary__info'>
                    {order?.packages.map((_pack) => (
                        <div className='order__summary__info__package'>
                            <div className='order__sumary__info__package--left'>
                                <div
                                    className='package-thumnail'
                                    style={{
                                        backgroundImage: `url('${_pack.package.thumbnail}')`,
                                    }}
                                ></div>
                                <div className='package-name'>
                                    <Typography
                                        gutterBottom
                                        variant='h5'
                                        component='div'
                                        color='primary'
                                        textTransform='uppercase'
                                    >
                                        {_pack.package.name}
                                    </Typography>
                                    <Typography
                                        color='text.secondary'
                                        variant='body2'
                                    >
                                        {_pack.package.desc}
                                    </Typography>
                                </div>
                            </div>
                            <div className='order__sumary__info__package--right'>
                                <Typography variant='body1' component='div'>
                                    {formatterMoney.format(_pack.totalPrice)}
                                </Typography>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className='order__price'>
                <Typography component='div' display='inline'>
                    Tổng số tiền:&nbsp;&nbsp;
                </Typography>
                <Typography
                    className='order__total-price'
                    component='div'
                    color='primary'
                    gutterBottom
                    display='inline'
                >
                    {formatterMoney.format(order.totalPrice)}
                </Typography>
            </div>
            <div className='order__actions'>
                <Button color='error' variant='contained' className='btn-big'>
                    Mua lại
                </Button>
                <Button
                    color='inherit'
                    variant='text'
                    className='btn-big'
                    onClick={() =>
                        navigate({
                            pathname: PATH_PAGE.payment_order,
                            search: '?orderId=' + order.orderId,
                        })
                    }
                >
                    Chi tiết đơn hàng
                </Button>
            </div>
        </Box>
    )
}

const AccountOrder = ({ orders }) => {
    return (
        <div>
            <Box
                component='form'
                justifyContent='flex-start'
                gap={15}
                display='flex'
                alignItems='end'
            >
                <FormControl
                    sx={{
                        width: '100%',
                        marginBottom: '30px',
                        maxWidth: 420,
                    }}
                >
                    <TextField
                        name='search'
                        variant='standard'
                        type='search'
                        label='Tìm kiếm'
                        placeholder='Tìm kiếm theo Tên gói hoặc Mã đơn hàng'
                    />
                </FormControl>
            </Box>
            {orders.length === 0 ? (
                <Typography>Không có đơn hàng</Typography>
            ) : (
                orders.map((order) => <Order order={order} />)
            )}
        </div>
    )
}

export default AccountOrder
