export const sumAllDigit = (num) => {
    let sum = 0
    while (num) {
        sum += num % 10
        num = Math.floor(num / 10)
    }
    return sum
}

export const sumCondition = (num, fnCond) => {
    let sum = sumAllDigit(num)
    while (fnCond(sum)) {
        sum = sumAllDigit(sum)
    }
    return sum
}

export const condition1122 = (num) => {
    while (num > 9) {
        if (num === 11 || num === 22 || num === 33) {
            return num
        }
        num = sumAllDigit(num)
    }
    return num
}
