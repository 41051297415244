import React, { useEffect } from 'react'
import { Box, ThemeProvider, Typography } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import AKBLogo from '../../assets/images/nhan-so-hoc-logo-cong-ty.png'
import { DarkTheme } from '../../components/UI/Theme'
import './style.css'

export default function App() {
    useEffect(() => {
        document.title = 'Chính sách | NSĐV'
        let description = document.querySelector('meta[name="description"]')
        if (description) {
            description.setAttribute(
                'content',
                'Cung cấp giải pháp số toàn diện theo đúng mục đích, quy trình hoạt động của doanh nghiệp, cơ quan tổ chức từ nhỏ tới lớn. info@daviso.vn. 0979.33.88.11. Số 23 ngõ 412 đường Ngọc Thụy, quận Long Biên, Hà Nội'
            )
        }
    }, [])

    return (
        <div className='parent'>
            <div className='text-box'>
                <img src={AKBLogo} alt='logo' />
                <div className='custom-info'>
                    <Box className='slogan' sx={{ textTransform: 'uppercase' }}>
                        <div style={{ display: 'flex' }}>
                            <b style={{ color: 'white' }}>Chính sách bảo mật</b>
                        </div>
                        <div style={{ display: 'flex' }}>
                            &nbsp;<b style={{ color: 'white' }}> và </b>&nbsp;
                        </div>
                        <div style={{ display: 'flex' }}>
                            <b style={{ color: 'white' }}>Điều khoản sử dụng</b>
                        </div>
                    </Box>
                    <ThemeProvider theme={DarkTheme}>
                        <CssBaseline />
                        <Box display='flex' mt={2} alignItems='left'>
                            <Typography paragraph variant='body1'>
                                Khi sử dụng dịch vụ của Nhân Số Đại Việt, bạn
                                tin tưởng cung cấp thông tin của bạn cho Nhân Số
                                Đại Việt. Nhân Số Đại Việt hiểu rằng đây là một
                                trách nhiệm lớn và Nhân Số Đại Việt nỗ lực bảo
                                vệ thông tin của bạn cũng như để bạn nắm quyền
                                kiểm soát. Nhân Số Đại Việt chỉ sử dụng thông
                                tin thu thập để: Cung cấp Dịch vụ mà bạn yêu
                                cầu; Hiểu cách bạn sử dụng Dịch vụ để Nhân Số
                                Đại Việt có thể cải thiện trải nghiệm của bạn;
                                Cung cấp nội dung và quảng cáo theo đối tượng
                                khách hàng.
                            </Typography>
                        </Box>
                        <Box display='flex' mt={2} alignItems='left'>
                            <Typography paragraph variant='body1'>
                                TỪ CHỐI TRÁCH NHIỆM: nội dung trang này được sưu
                                tầm từ Internet. Người viết không giữ bản quyền
                                bất kỳ nội dung nào. Nhân số học chỉ mang tính
                                tham khảo, không nên tin tưởng tuyệt đối. Nhân
                                Số Đại Việt chỉ cung cấp dịch vụ cho mục đích
                                giải trí. Thông tin có trên trang web hoặc trong
                                bất kỳ email hoặc tài liệu nhận được từ trang
                                web đều không được đảm bảo bằng bất cứ cách nào.
                            </Typography>
                        </Box>
                        <Box display='flex' mt={2} alignItems='left'>
                            <Typography paragraph variant='body1'>
                                TỪ CHỐI BẢO HÀNH: việc bạn sử dụng các hình ảnh,
                                thông tin, các tài liệu của trang web và các
                                dịch vụ được cung cấp trên hoặc thông qua trang
                                web là rủi ro của riêng bạn và không có được bất
                                kỳ đảm bảo nào. Nhân Số Đại Việt không tuyên bố
                                hoặc đảm bảo rằng các trang web, tài liệu của
                                trang web hoặc các dịch vụ được cung cấp thông
                                qua trang web là chính xác, hoàn chỉnh, tin cậy
                                hoặc không có tác hại nào khác.
                            </Typography>
                        </Box>
                        <Box display='flex' mt={2} alignItems='left'>
                            <Typography paragraph variant='body1'>
                                TRANG WEB VÀ NỘI DUNG CỦA BÊN THỨ BA: Nhân Số
                                Đại Việt có thể chứa các liên kết đến các trang
                                web khác (“Trang web của bên thứ ba”) dưới dạng
                                văn bản, đồ họa, bài báo biểu tượng, ảnh, hình
                                ảnh, thiết kế, âm nhạc, âm thanh, video, thông
                                tin, phần mềm và các nội dung khác thuộc về hoặc
                                có nguồn gốc từ thứ ba các bữa tiệc. Thông tin
                                và nội dung từ Trang web của Bên thứ ba không
                                được Nhân Số Đại Việt xác minh hoặc xem xét
                                trước khi đưa vào Trang web. Nhân Số Đại Việt
                                không chịu trách nhiệm về tính chính xác, đầy đủ
                                hoặc đúng đắn của bất kỳ nội dung nào từ các
                                trang web của Bên thứ ba. Bạn chấp nhận rằng
                                việc sử dụng Trang web của bên thứ ba là quyết
                                định của riêng bạn và do bạn tự chịu rủi ro.
                            </Typography>
                        </Box>
                    </ThemeProvider>
                </div>
            </div>
        </div>
    )
}
