import { Fab, Zoom } from '@mui/material'
import React, { useEffect, useState } from 'react'

import Box from '@mui/material/Box'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

const ScrollToTop = ({ topRef }) => {
    const [showScrollTop, setShowScrollTop] = useState(false)

    useEffect(() => {
        const scrollEvent = () => {
            setShowScrollTop(window.scrollY > 400)
        }
        window.addEventListener('scroll', scrollEvent)
        return () => window.removeEventListener('scroll', scrollEvent)
    }, [])

    const handleClick = () => {
        window.scrollTo({
            top: topRef.current.offsetTop,
            left: 0,
            behavior: 'smooth',
        })
    }

    return (
        <Zoom in={showScrollTop}>
            <Box
                onClick={handleClick}
                role='presentation'
                sx={{
                    position: `fixed`,
                    bottom: `75px`,
                    right: `1rem`,
                    zIndex: `99`,
                }}
            >
                <Fab color='error' aria-label='scroll back to top'>
                    <KeyboardArrowUpIcon />
                </Fab>
            </Box>
        </Zoom>
    )
}

export default ScrollToTop
