import { useDispatch, useSelector } from 'react-redux'

import { Navigate } from 'react-router-dom'
import authActions from '../redux/actions/auth.action'
import { authSelector } from '../redux/selector/auth.selector'
import { useEffect } from 'react'

const PrivateRoute = ({ children, redirectPath = '/' }) => {
    const dispatch = useDispatch()
    const { loggedIn, user } = useSelector(authSelector)
    useEffect(() => {
        dispatch(authActions.whoAmI())
    }, [dispatch])

    if (!loggedIn || !user) {
        return <Navigate to={redirectPath} replace />
    }

    return children
}

export default PrivateRoute
