export const PATH_PAGE = {
    // human_arithmetic: '/nhan-so-hoc',
    human_arithmetic: '/tra-cuu/nhan-so',
    home: '/',
    calendar: '/lich-van-nien',
    human_arithmetic_result: '/ket-qua',
    human_arithmetic_theory: '/ly-thuyet',
    post: '/bai-viet/:slug',
    human_arithmetic_category: '/tra-cuu',
    search_birthday: '/tra-cuu/ngay-sinh',
    account_profile: '/tai-khoan-cua-toi',
    manager: '/quan-li-nguoi-dung',

    about: '/ve-chung-toi',
    policy: '/chinh-sach',

    shopping: '/mua-sam',
    payment_order: '/thanh-toan',

    error404: '/trang-khong-ton-tai-404',
}
