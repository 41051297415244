import axios from 'axios'

const URL = process.env.REACT_APP_API_URL

export const getLuangiai = (data) => {
    return axios.get(`${URL}/luan-giai`, {
        params: data,
    })
}

export const getLuangiaiChitiet = (data, type) => {
    return axios.get(`${URL}/luan-giai/${type}`, {
        params: data,
    })
}

export const getLuangiaiCache = (code) => {
    return axios.get(`${URL}/luan-giai-cache`, {
        params: { code },
    })
}
